import React, { ReactNode } from 'react'
import cx from 'classnames'

import { D6Logo } from '../Logo/D6Logo'

interface GenericErrorMessageProps
  extends React.HTMLAttributes<HTMLDivElement> {
  message?: ReactNode
}

export const ErrorMessageLogo = ({
  id = 'genericErrorMessage',
  message = 'Sorry, something went wrong',
  className,
  ...rest
}: GenericErrorMessageProps) => {
  const styles = cx(
    'flex flex-col items-center justify-center h-full m-4',
    className
  )

  return (
    <div id={id} data-testid={id} className={styles} {...rest}>
      <D6Logo className="h-6 mx-auto mb-5" />
      {message}
    </div>
  )
}
