import { container, inject, injectable } from 'tsyringe'
import path from 'path-browserify'
import { HttpClient, HttpOptions, JsonHttpClient } from '@deep6ai/common'

import { FetchHttpClient } from '../http/fetch-http-client'
import { RequestContextService } from '../../services/request-context-service/RequestContextService'

@injectable()
export class CentralApiHttpClient implements HttpClient, JsonHttpClient {
  static readonly injectionToken = 'CentralApiClient'

  private baseUrl = '/api'

  constructor(
    @inject(FetchHttpClient.injectionToken) private httpClient: HttpClient,
    private requestContextService: RequestContextService
  ) {}

  get(url: string, options?: HttpOptions): Promise<Response> {
    return this.httpClient.get(
      this.formatUrl(url),
      this.formatRequestOptions(options)
    )
  }

  async getJson<T = unknown>(url: string, options?: HttpOptions): Promise<T> {
    const res = await this.get(url, options)
    return res.json()
  }

  post(url: string, options?: HttpOptions): Promise<Response> {
    return this.httpClient.post(
      this.formatUrl(url),
      this.formatRequestOptions(options)
    )
  }

  async postJson<T = unknown>(url: string, options?: HttpOptions): Promise<T> {
    const res = await this.post(url, options)
    return res.json()
  }

  put(url: string, options?: HttpOptions): Promise<Response> {
    return this.httpClient.put(
      this.formatUrl(url),
      this.formatRequestOptions(options)
    )
  }

  async putJson<T = unknown>(url: string, options?: HttpOptions): Promise<T> {
    const res = await this.put(url, options)
    return res.json()
  }

  patch(url: string, options?: HttpOptions): Promise<Response> {
    return this.httpClient.patch(
      this.formatUrl(url),
      this.formatRequestOptions(options)
    )
  }

  async patchJson<T = unknown>(url: string, options?: HttpOptions): Promise<T> {
    const res = await this.patch(url, options)
    return res.json()
  }

  delete(url: string, options?: HttpOptions): Promise<Response> {
    return this.httpClient.delete(
      this.formatUrl(url),
      this.formatRequestOptions(options)
    )
  }

  head(url: string, options?: HttpOptions): Promise<Response> {
    return this.httpClient.head(
      this.formatUrl(url),
      this.formatRequestOptions(options)
    )
  }

  private formatRequestOptions(options: HttpOptions = {}): HttpOptions {
    const requestContext = this.requestContextService.getContext()
    const defaultHeaders: HeadersInit = {
      'Content-Type': 'application/json',
      ...requestContext
    }

    return {
      headers: defaultHeaders,
      ...options
    }
  }

  private formatUrl(url: string): string {
    return path.join(this.baseUrl, url)
  }

  static Build() {
    container.register(CentralApiHttpClient.injectionToken, {
      useClass: CentralApiHttpClient
    })
    return container.resolve<CentralApiHttpClient>(CentralApiHttpClient)
  }
}

export const centralApiClient = CentralApiHttpClient.Build()
