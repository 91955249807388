import { container, inject, injectable } from 'tsyringe'
import { JsonHttpClient, HttpClient, UrlBuilder } from '@deep6ai/common'

import { PaginatedResponse } from '../../lib/http/paginated-response'
import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import { Pageable } from '../../lib/http/pageable'
import { Sortable } from '../../lib/http/sortable'
import {
  MatchType,
  MembershipStatus,
  PartialMatchFlag,
  StudyMembershipFilters
} from './models/StudyMembershipFilters'
import {
  GetPatientResult,
  Patient,
  PatientSummary,
  StudyMembershipsMetadata
} from './models/Patient'
import { ReviewStatus } from '../study/study.service'
import * as SpokeHttpClientIoc from '../../lib/spoke-http-client/SpokeHttpClient.ioc'

export enum PatientIdentifierType {
  PatientUuid = 'patient-uuid',
  PatientMrn = 'patient-mrn'
}

export enum PatientStatus {
  LIVING = 'LIVING',
  DECEASED = 'DECEASED',
  UNSPECIFIED = 'UNSPECIFIED'
}

export type PatientIdentifier = {
  type: PatientIdentifierType.PatientUuid | PatientIdentifierType.PatientMrn
  value: string
}

export interface GetStudyMembershipParams extends Pageable, Sortable {
  filters: StudyMembershipFilters
  runUuid: string
  healthSystemUuid: string
  studyUuid: string
}

@injectable()
export class PatientService {
  private basePath = '/patient'

  constructor(
    @inject(SpokeHttpClientIoc.injectionToken)
    private spokeHttpClient: JsonHttpClient,
    @inject(CentralApiHttpClient.injectionToken)
    private centralApi: JsonHttpClient & HttpClient,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder
  ) {}

  getPatientByUuid = (patientUuid: string): Promise<Patient> => {
    return this.getPatientByIdentifier({
      type: PatientIdentifierType.PatientUuid,
      value: patientUuid
    })
  }

  getPatientByMrn = (patientMrn: string): Promise<Patient> => {
    return this.getPatientByIdentifier({
      type: PatientIdentifierType.PatientMrn,
      value: patientMrn
    })
  }

  getPatientByIdentifier = (
    identifier: PatientIdentifier
  ): Promise<Patient> => {
    return this.spokeHttpClient.postJson(
      this.urlBuilder.build(this.formatPath('identifier')),
      {
        body: JSON.stringify(identifier)
      }
    )
  }

  getPatientsByUuids = (
    patientUuids: string[]
  ): Promise<GetPatientResult[]> => {
    const identifiers = patientUuids.map((patientUuid) => ({
      type: PatientIdentifierType.PatientUuid,
      value: patientUuid
    }))
    return this.getPatientsByIdentifiers(identifiers)
  }

  getPatientsByMrns = (patientMrns: string[]): Promise<GetPatientResult[]> => {
    const identifiers = patientMrns.map((patientMrn) => ({
      type: PatientIdentifierType.PatientMrn,
      value: patientMrn
    }))
    return this.getPatientsByIdentifiers(identifiers)
  }

  getPatientsByIdentifiers = (
    identifiers: PatientIdentifier[]
  ): Promise<GetPatientResult[]> => {
    return this.spokeHttpClient.postJson(
      this.urlBuilder.build(this.formatPath('identifiers')),
      {
        body: JSON.stringify(identifiers)
      }
    )
  }

  /**
   * @deprecated - use getPatientMembership instead
   */

  getStudyMemberships = async (
    params: GetStudyMembershipParams
  ): Promise<PaginatedResponse<PatientSummary[], StudyMembershipsMetadata>> => {
    const { studyUuid, healthSystemUuid, runUuid, page, sort, size, filters } =
      params

    const url = this.urlBuilder.build(
      this.formatPath(
        `study-membership/${studyUuid}/run/${runUuid}/health-system/${healthSystemUuid}`
      ),
      {
        size,
        page,
        sort
      }
    )

    // Why a POST instead of a GET? The amount of filter params we need to pass
    // can get very large. Larger than a url can hold in some cases. We also
    // need to define complex data hierarchies that are difficult to express
    // in a url param.
    return this.spokeHttpClient.postJson(url, {
      body: JSON.stringify(filters)
    })
  }

  static buildStudyMembershipFilters = ({
    matchType,
    membershipStatus,
    partialMatchFlags = [],
    nameOrMrnFilters = [],
    recordContentsFilter,
    siteIdsFilter = [],
    patientStatusesFilter = []
  }: {
    matchType: MatchType
    membershipStatus: MembershipStatus
    partialMatchFlags?: PartialMatchFlag[]
    nameOrMrnFilters?: string[]
    recordContentsFilter?: string
    siteIdsFilter?: string[]
    patientStatusesFilter?: PatientStatus[]
  }): StudyMembershipFilters => {
    const studyMembershipFilter = isMembershipStatusUnreviewed(membershipStatus)
      ? {
          status: membershipStatus,
          criteria: {
            matchType,
            flags: partialMatchFlags
          }
        }
      : {
          status: membershipStatus
        }

    return {
      memberships: [studyMembershipFilter],
      patientRecord: {
        nameOrMrns: nameOrMrnFilters,
        contents: recordContentsFilter,
        siteIds: siteIdsFilter,
        patientStatuses: patientStatusesFilter
      }
    }
  }

  /**
   * Calls the central-api updateStudyMembershipStatus endpoint
   *
   * We're passing in ReviewStatus and converting to MembershipStatus
   * since MembershipStatus is deprecated.
   */
  updateStudyReviewStatus = (
    patientUuid: string,
    studyUuid: string,
    reviewStatus: ReviewStatus,
    comment?: string
  ): Promise<Response> => {
    const membershipStatus = reviewStatusToMembershipStatusMap[reviewStatus]

    const url = this.urlBuilder.build(
      this.formatPath(`${patientUuid}/study-membership/${studyUuid}`)
    )

    return this.spokeHttpClient.postJson(url, {
      body: JSON.stringify(membershipStatus)
    })
  }

  private formatPath(p = ''): string {
    return this.urlBuilder.joinPath(this.basePath, p)
  }

  static build() {
    return container.resolve(PatientService)
  }
}

export const reviewStatusToMembershipStatusMap = {
  [ReviewStatus.ACCEPTED]: MembershipStatus.YES,
  [ReviewStatus.REJECTED]: MembershipStatus.NO,
  [ReviewStatus.UNREVIEWED]: MembershipStatus.UNREVIEWED
}

export const membershipStatusToReviewStatusMap = {
  [MembershipStatus.YES]: ReviewStatus.ACCEPTED,
  [MembershipStatus.NO]: ReviewStatus.REJECTED,
  [MembershipStatus.UNREVIEWED]: ReviewStatus.UNREVIEWED,
  [MembershipStatus.WATCHLISTED]: ReviewStatus.WATCHLISTED
}

const isMembershipStatusUnreviewed = (
  status: MembershipStatus
): status is MembershipStatus.UNREVIEWED =>
  status === MembershipStatus.UNREVIEWED

export const patientService = PatientService.build()
