import React from 'react'
import { LoggerFactory } from '@deep6ai/common'
import { If } from '@deep6ai/component-library'

import { ErrorMessageLogo } from './ErrorMessageLogo'

const logger = LoggerFactory.build()

export class DefaultErrorBoundary extends React.Component<any, any> {
  state = {
    error: false
  }

  static getDerivedStateFromError(error) {
    return { error: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    logger.error('============ERROR MESSAGE BEGIN============')
    logger.error(Error)
    logger.error(errorInfo)
    logger.error(error.message)
    logger.error('============ERROR MESSAGE END============')
  }

  render() {
    return (
      <If condition={!this.state.error} otherwise={<ErrorMessageLogo />}>
        {this.props.children}
      </If>
    )
  }
}
