import { container, injectable } from 'tsyringe'

import { StudyContextService } from '../study/StudyContextService'
import { TrialRecommenderContextService } from '../trial-recommender/TrialRecommenderContextService'

export enum RequestContext {
  STUDY = 'STUDY',
  TRIAL_RECOMMENDER = 'TRIAL_RECOMMENDER'
}

type Ctx =
  | {
      RequestContext: RequestContext.TRIAL_RECOMMENDER
    }
  | {
      RequestContext: RequestContext.STUDY
      StudyUuid: string
    }

@injectable()
export class RequestContextService {
  constructor(
    private studyContextService: StudyContextService,
    private trialRecommenderContextService: TrialRecommenderContextService
  ) {}

  getContext(): Ctx | undefined {
    if (this.isTrialRecommenderContext()) {
      return {
        RequestContext: RequestContext.TRIAL_RECOMMENDER
      }
    }

    if (this.isStudyContext()) {
      return {
        RequestContext: RequestContext.STUDY,
        StudyUuid: this.studyContextService.getContext()!
      }
    }
  }

  private isStudyContext = (): boolean => {
    return !!this.studyContextService.getContext()
  }

  private isTrialRecommenderContext = (): boolean => {
    return this.trialRecommenderContextService.getContext()
  }

  static build() {
    return container.resolve(RequestContextService)
  }
}

export const requestContextService = RequestContextService.build()
