import { injectable } from 'tsyringe'

import {
  BucketMapper,
  ReducerCB,
  MissingMetadataException
} from './bucket-mapper'
import {
  AllowedIneligibleRecruitmentStatuses,
  IneligibleMetadata,
  Recruitment,
  RecruitmentBucketKey
} from '../Recruitment'
import { IncomingRecruitmentBucketItemMetadata } from './recruitment-bucket-mapper'

@injectable()
export class IneligibleMapper implements BucketMapper<IneligibleMetadata> {
  buildMapper(): ReducerCB<IneligibleMetadata> {
    return (aggregate, current) => {
      return this.bucketMapper(aggregate, current, this.transform)
    }
  }

  transform = (
    metadata: IncomingRecruitmentBucketItemMetadata
  ): IneligibleMetadata => {
    if (!metadata.statusMetadata.ineligibleMetadata?.timestamp) {
      throw new MissingMetadataException(RecruitmentBucketKey.INELIGIBLE)
    }

    return {
      currentStatus:
        metadata.currentStatus as AllowedIneligibleRecruitmentStatuses,
      statusMetadata: {
        ineligibleAt: metadata.statusMetadata.ineligibleMetadata.timestamp
      }
    }
  }

  private bucketMapper = (
    buckets: Recruitment<IneligibleMetadata>[],
    incomingItem: Recruitment<IncomingRecruitmentBucketItemMetadata>,
    metadataTransform: (
      metadata: IncomingRecruitmentBucketItemMetadata
    ) => IneligibleMetadata
  ): Recruitment<IneligibleMetadata>[] => {
    return [
      ...buckets,
      {
        ...incomingItem,
        metadata: metadataTransform(incomingItem.metadata)
      }
    ]
  }
}
