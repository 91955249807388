import React, { PropsWithChildren } from 'react'
import ReactModal from 'react-modal'
import cx from 'classnames'

import styles from './Modal.module.css'

export enum ModalSize {
  Large,
  Regular
}

interface ModalProps extends PropsWithChildren<ReactModal.Props> {
  size?: ModalSize
  sessionTimeout?: boolean // adding this boolean to adjust zindex for timeout modal
}

export const Modal = ({
  children,
  isOpen,
  size,
  className,
  sessionTimeout,
  ...rest
}: ModalProps) => (
  <ReactModal
    isOpen={isOpen}
    ariaHideApp={false}
    className={cx(className, styles.D6Modal, {
      [styles.D6LargeModal]: size === ModalSize.Large
    })}
    overlayClassName={cx(styles.ModalOverlayBase, {
      [styles.D6ModalOverlay]: !sessionTimeout,
      [styles.D6SessionTimeoutModalOverlay]: sessionTimeout
    })}
    {...rest}
  >
    {children}
  </ReactModal>
)
