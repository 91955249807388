import React from 'react'
import { LegacyInput as Input } from '@deep6ai/component-library'

import { ConfirmButtons } from '../../Modal/ConfirmButtons'
import { FormGroup } from '../FormGroup'
import { Label } from '../Label'

interface FileAttachmentEditProps {
  fileName: string
  displayName?: string
  onUpdate: () => void
  onCancel: () => void
  onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const FileAttachmentEdit = ({
  fileName,
  displayName,
  onUpdate,
  onCancel,
  onChangeName
}: FileAttachmentEditProps) => {
  const handleUpdateButtonClick = () => {
    onUpdate()
  }

  const handleCancelButtonClick = () => {
    onCancel()
  }

  return (
    <div>
      <FormGroup>
        <Label htmlFor="fileName">Text to Display</Label>
        <Input
          id="fileName"
          onChange={onChangeName}
          placeholder="Update the file name"
          value={displayName}
        />
      </FormGroup>

      <div className="flex items-center justify-between truncate">
        <div className="items-center truncate">{fileName}</div>

        <ConfirmButtons
          confirmButtonText="Update"
          onCancel={handleCancelButtonClick}
          onConfirm={handleUpdateButtonClick}
        />
      </div>
    </div>
  )
}
