import round from 'lodash/round'

import { FileTypes } from '../FilePicker'
import { grammaticalJoin } from '../../../lib/text/grammaticalJoin'

export const errorMessages = {
  fieldRequired: 'This field is required.',
  onlyDigits: 'Numbers only, please.',
  recruitmentGoal: 'Please enter an integer between 0 and 2,000,000.',
  teamMemberInPrincipalInvestigators:
    'This person is already part of the team.',
  url: {
    required: 'URL is required.',
    invalid: 'Invalid URL, please check the URL and try again.'
  },
  file: {
    unacceptedType: (acceptedFileTypes: FileTypes[]) => {
      const acceptedFileTypesStr = grammaticalJoin(acceptedFileTypes, 'or')
      return `This is not a valid file type. Please select a ${acceptedFileTypesStr}.`
    },
    sizeTooLarge: (sizeLimitInBytes: number) => {
      return `Upload failed. The file exceeds size limit of ${displayBytesAsMegaBytes(
        sizeLimitInBytes
      )} MB.`
    }
  }
}

function displayBytesAsMegaBytes(bytes: number) {
  const mb = bytes / (1024 * 1024)
  return round(mb, 2)
}
