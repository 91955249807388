import React from 'react'

import logoFile from '../../img/logo-deep6.png'

interface D6LogoProps {
  className: string
  alt?: string
}

export const D6Logo = ({ className, alt, ...restProps }: D6LogoProps) => {
  const logo = {
    src: logoFile,
    alt: 'Deep 6 AI logo'
  }
  return (
    <img
      src={logo.src}
      alt={alt || logo.alt}
      className={className}
      {...restProps}
    />
  )
}
