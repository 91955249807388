import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { FeatureFlagClient, FeatureFlagClientFactory } from '@deep6ai/common'

import { useAsyncFunction } from '../../hooks/useAsyncFunction/useAsyncFunction'
import { useAggregatedAsyncStatus } from '../../hooks/useAggregatedAsyncStatus/useAggregatedAsyncStatus'
import { RouteResolver } from '../RouteResolver'
import { RouteResolverView } from '../../components/RouteResolverView/RouteResolverView'
import { studyReportingService } from '../../services/study-reporting/StudyReportingService'
import { FeatureFlagKey } from '../../config/feature-flags'

export interface DashboardsResolvedProps {
  dashboardUrlResponse: string
  dashboardId: string
}

export interface InjectedDependencies {
  getDashboardUrl: typeof studyReportingService.getDashboardUrl
}

export type DashboardRouteResolverProps = RouteResolver<
  DashboardsResolvedProps,
  InjectedDependencies
>

export function DashboardRouteResolver({
  getDashboardUrl = studyReportingService.getDashboardUrl,
  component: Component
}: DashboardRouteResolverProps) {
  const { dashboardId } = useParams<{ dashboardId: string }>()
  const featureFlagClient: FeatureFlagClient<FeatureFlagKey> =
    FeatureFlagClientFactory.getClient<FeatureFlagKey>()
  const isReportingEnabled = featureFlagClient.isFlagEnabled(
    FeatureFlagKey.REPORTING_DASHBOARDS
  )

  const [
    getDashboardUrlAsync,
    {
      data: getDashboardUrlResponse,
      status: getDashboardUrlStatus,
      error: getDashboardUrlError
    }
  ] = useAsyncFunction(getDashboardUrl)

  useEffect(() => {
    getDashboardUrlAsync(dashboardId, isReportingEnabled)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardId])

  const aggregatedStatus = useAggregatedAsyncStatus([getDashboardUrlStatus])

  return (
    <RouteResolverView
      status={aggregatedStatus}
      errors={[getDashboardUrlError]}
    >
      <Component
        resolvedProps={{
          dashboardUrlResponse: getDashboardUrlResponse?.body!,
          dashboardId
        }}
      />
    </RouteResolverView>
  )
}

export default DashboardRouteResolver
