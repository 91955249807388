import { useHistory } from 'react-router-dom'
import { LoggerFactory, UrlBuilderFactory } from '@deep6ai/common'

import { useQueryParams } from '../useQueryParams/useQueryParams'

export interface BreadcrumbProps {
  label: string
  href: string
  params?: { [key: string]: unknown }
}

type BreadcrumbCallback = (breadcrumb: BreadcrumbProps) => void

export type UseBreadcrumb = [
  Omit<BreadcrumbProps, 'params'>,
  BreadcrumbCallback
]

/**
 * @description set a breadcrumb link in the nav bar and anywhere else
 * we want to provide navigation.
 * */
export const useBreadcrumb = (
  logger = LoggerFactory.build(),
  urlBuilder = UrlBuilderFactory.build()
): UseBreadcrumb => {
  const history = useHistory()
  const [{ breadcrumb, ...rest }] = useQueryParams()

  const parseBreadcrumb = (unparsedBreadcrumb: unknown): BreadcrumbProps => {
    try {
      if (isBreadcrumb(unparsedBreadcrumb)) {
        const parsed = unparsedBreadcrumb
        const { label, href, params } = parsed
        return {
          label,
          href: urlBuilder.build(href, params)
        }
      }
    } catch (e) {
      logger.warn(
        `${unparsedBreadcrumb} is not a valid breadcrumb value and will be ignored`
      )
    }

    return {
      label: '',
      href: ''
    }
  }

  const setBreadcrumb = (newBreadcrumb: BreadcrumbProps) => {
    // important!!!
    // using replace here updates the URI but does not change browser history
    // we must use replace instead of push to avoid issues with browser's back button
    history.replace({
      search: urlBuilder.formatQueryString({
        breadcrumb: newBreadcrumb,
        ...rest,
        ...newBreadcrumb.params
      })
    })
  }

  return [parseBreadcrumb(breadcrumb), setBreadcrumb]
}

const isBreadcrumb = (val): val is BreadcrumbProps => {
  return val && val.label && val.href
}
