/**
 * Joins an array of strings to form a grammatically correct list.
 */
export function grammaticalJoin(arr: string[], conjunction = 'and'): string {
  return arr.reduce(
    (a, b, i, arr) =>
      a +
      (i < arr.length - 1
        ? ', '
        : arr.length > 2
        ? `, ${conjunction} `
        : ` ${conjunction} `) +
      b
  )
}
