import React from 'react'
import {
  toast,
  ToastContainer as ReactToastifyContainer,
  Slide
} from 'react-toastify'

export const ToastContainer = () => {
  return (
    <ReactToastifyContainer
      position={toast.POSITION.TOP_CENTER}
      toastClassName="rounded cursor-default"
      bodyClassName="px-1"
      transition={Slide}
      autoClose={5000}
      pauseOnHover
      pauseOnFocusLoss
      hideProgressBar
      closeOnClick={false}
      draggable={false}
    />
  )
}
