import { AbstractAction } from '../abstract-action'
import { RunActionTypes } from './run.actions'
import { RunDetails } from '../../services/run/StudyRunService'

interface RunReducerSlice {
  activeRun: RunDetails | null
}

export const initialState: RunReducerSlice = {
  activeRun: null
}

export default function runReducer(
  state = initialState,
  action: AbstractAction<RunActionTypes>
) {
  switch (action.type) {
    case RunActionTypes.setActiveRun:
      return {
        ...state,
        activeRun: action.payload
      }
    case RunActionTypes.clearActiveRun:
      return {
        ...state,
        activeRun: null
      }
    default:
      return state
  }
}
