import React, { PropsWithChildren } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import * as LaunchDarklySdk from 'launchdarkly-js-client-sdk'
import { If } from '@deep6ai/component-library'
import { FeatureFlagClientFactory } from '@deep6ai/common'

import { authenticationActions } from '../../../store/authentication/authentication.actions'
import { useMountEffect } from '../../../hooks/useMountEffect'
import { LoadingOverlay } from '../../Loading/LoadingOverlay'
import { ErrorPage } from '../../../pages/Error/ErrorPage'
import { configurationActions } from '../../../store/configuration/configuration.actions'
import { useAsyncFunction } from '../../../hooks/useAsyncFunction/useAsyncFunction'
import { configurationService } from '../../../services/configuration/configuration.service'
import { useAggregatedAsyncStatus } from '../../../hooks/useAggregatedAsyncStatus/useAggregatedAsyncStatus'
import { AsyncStatusView } from '../../AsyncStatusView/AsyncStatusView'
import { authenticationService } from '../../../services/authentication/authentication-service'
import { sessionService } from '../../../services/session/SessionService'
import { capabilitiesService } from '../../../services/capabilities/CapabilitiesService'

interface BootstrapProps extends PropsWithChildren<PropsFromRedux> {
  getAuthenticatedUser?: typeof authenticationService.getAuthenticatedUser
  getAuthenticatedUserClaims?: typeof authenticationService.getAuthenticatedUserClaims
  createSession?: typeof sessionService.create
  getConfig?: typeof configurationService.getConfig
  initializeFeatureFlags?: typeof FeatureFlagClientFactory.init
  initializeCapabilities?: typeof capabilitiesService.init
}

const _Bootstrap = ({
  setActiveUser,
  setConfig,
  getAuthenticatedUser = authenticationService.getAuthenticatedUser,
  getAuthenticatedUserClaims = authenticationService.getAuthenticatedUserClaims,
  createSession = sessionService.create,
  getConfig = configurationService.getConfig,
  initializeFeatureFlags = FeatureFlagClientFactory.init,
  initializeCapabilities = capabilitiesService.init,
  children
}: BootstrapProps) => {
  const [asyncGetConfig, { status: asyncGetConfigStatus }] =
    useAsyncFunction(getConfig)

  const [
    asyncInitializeFeatureFlagClient,
    { status: asyncInitializeFeatureFlagStatus }
  ] = useAsyncFunction(initializeFeatureFlags)

  const [
    asyncInitializeCapabilities,
    { status: asyncInitializeCapabilitiesStatus }
  ] = useAsyncFunction(initializeCapabilities)

  const [
    asyncGetAuthenticatedUser,
    {
      status: asyncGetAuthenticatedUserStatus,
      error: asyncGetAuthenticatedUserError
    }
  ] = useAsyncFunction(getAuthenticatedUser)

  const [
    asyncGetAuthenticatedUserClaims,
    { status: asyncGetAuthenticatedUserClaimsStatus }
  ] = useAsyncFunction(getAuthenticatedUserClaims)

  const aggregatedStatus = useAggregatedAsyncStatus([
    asyncGetAuthenticatedUserStatus,
    asyncGetConfigStatus,
    asyncInitializeFeatureFlagStatus,
    asyncGetAuthenticatedUserClaimsStatus,
    asyncInitializeCapabilitiesStatus
  ])

  useMountEffect(() => {
    ;(async () => {
      const [user, config, claims] = await Promise.all([
        asyncGetAuthenticatedUser(),
        asyncGetConfig(),
        asyncGetAuthenticatedUserClaims(),
        asyncInitializeCapabilities()
      ])

      setConfig(config)
      setActiveUser(user)
      createSession(claims)

      await asyncInitializeFeatureFlagClient({
        sdk: LaunchDarklySdk,
        clientId: config.launchDarkly.clientId,
        user: {
          key: user.accountUuid,
          custom: {
            organization: user.organization.uuid
          },
          name: user.displayName
        }
      })
    })()
  })

  return (
    <AsyncStatusView
      onErrorShow={
        <If
          condition={asyncGetAuthenticatedUserError?.status === 401}
          otherwise={<ErrorPage />}
        >
          <LoadingOverlay />
        </If>
      }
      status={aggregatedStatus}
    >
      {children}
    </AsyncStatusView>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  setActiveUser: authenticationActions.setActiveUser,
  setConfig: configurationActions.setConfig
}

const connector = connect(mapStateToProps, mapDispatchToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export const Bootstrap = connector(_Bootstrap)
