import { container, inject, injectable } from 'tsyringe'

import { ReduxAsyncNotifier } from '../../lib/async-notifier/redux-async-notifier'
import type { AsyncNotifier } from '../../lib/async-notifier/async-notifier'
import {
  AccountDisplayName,
  AccountService,
  AccountState
} from '../../services/account/account-service'
import { AbstractAction } from '../abstract-action'
import { PaginatedResponse } from '../../lib/http/paginated-response'

export enum AccountActionTypes {
  addAvailableTeamMembers = 'ACCOUNTS::ADD_AVAILABLE_TEAM_MEMBERS',
  clearAvailableTeamMembers = 'ACCOUNTS::CLEAR_AVAILABLE_TEAM_MEMBERS',
  addAvailableShareRecipients = 'ACCOUNTS::ADD_AVAILABLE_SHARE_RECIPIENTS',
  clearAvailableShareRecipients = 'ACCOUNTS::CLEAR_AVAILABLE_SHARE_RECIPIENTS'
}

@injectable()
export class AccountActions {
  constructor(
    private accountService: AccountService,

    @inject(ReduxAsyncNotifier.injectionToken)
    private asyncNotifier: AsyncNotifier
  ) {}

  /* team members */
  addAvailableTeamMembers(
    accounts: PaginatedResponse<AccountDisplayName[]>
  ): AbstractAction<
    AccountActionTypes,
    PaginatedResponse<AccountDisplayName[]>
  > {
    return {
      type: AccountActionTypes.addAvailableTeamMembers,
      payload: accounts
    }
  }

  clearAvailableTeamMembers(): AbstractAction<AccountActionTypes> {
    return {
      type: AccountActionTypes.clearAvailableTeamMembers
    }
  }

  findAllAccountsByState = this.asyncNotifier.subscribe(
    (searchTerm?: string, states?: AccountState[]) => async (dispatch) => {
      if (searchTerm) {
        dispatch(
          this.addAvailableTeamMembers(
            await this.accountService.findAllAccountsByState(searchTerm, states)
          )
        )
      } else {
        dispatch(this.clearAvailableTeamMembers())
      }
    }
  )

  /* share recipients */
  addAvailableShareRecipients(
    accounts: PaginatedResponse<AccountDisplayName[]>
  ): AbstractAction<
    AccountActionTypes,
    PaginatedResponse<AccountDisplayName[]>
  > {
    return {
      type: AccountActionTypes.addAvailableShareRecipients,
      payload: accounts
    }
  }

  clearAvailableShareRecipients(): AbstractAction<AccountActionTypes> {
    return {
      type: AccountActionTypes.clearAvailableShareRecipients
    }
  }

  findAllShareRecipientsByHealthSystemUuidAndState =
    this.asyncNotifier.subscribe(
      (
          healthSystemUuid: string,
          searchTerm?: string,
          states?: AccountState[]
        ) =>
        async (dispatch) => {
          dispatch(
            this.addAvailableShareRecipients(
              await this.accountService.findAllShareRecipientsByHealthSystemUuidAndState(
                healthSystemUuid,
                searchTerm,
                states
              )
            )
          )
        }
    )

  static Build() {
    return container.resolve(AccountActions)
  }
}

export const accountActions = AccountActions.Build()
