import React from 'react'
import cx from 'classnames'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

interface LoadingSpinnerProps {
  size?: number
}

export const LoadingSpinner = ({ size = 48 }: LoadingSpinnerProps) => {
  const paddingClass = `p-${Math.round(size / 10)}`

  return (
    <div
      data-testid="LoadingSpinner"
      className={cx('h-full w-full flex flex-col justify-center', paddingClass)}
    >
      <div className="self-center">
        <AiOutlineLoading3Quarters
          className="m-auto animate-spin"
          size={size}
        />
      </div>
    </div>
  )
}
