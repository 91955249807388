import React from 'react'
import cx from 'classnames'
import {
  AiFillCaretDown,
  AiFillCaretRight,
  AiFillCaretLeft,
  AiFillCaretUp,
  AiOutlineQuestion
} from 'react-icons/ai'
import { RiForbid2Line } from 'react-icons/ri'

import { ReactComponent as Check } from './svg/thicc-check.svg'
import { ReactComponent as Bang } from './svg/thicc-exclamation.svg'
import { ReactComponent as Times } from './svg/thicc-cross.svg'
import { hasWidth, hasHeight } from '../shared/classname'
import styles from './Icon.module.css'

/* svg icons (custom) */

export const CheckIcon = ({
  className,
  ...restProps
}: React.SVGProps<SVGSVGElement>) => (
  <Check
    className={cx(
      'inline',
      {
        'w-4': !hasWidth(className),
        'h-4': !hasHeight(className)
      },
      className
    )}
    {...restProps}
  />
)

export const BangIcon = ({
  className,
  ...restProps
}: React.SVGProps<SVGSVGElement>) => (
  <Bang
    className={cx(
      'inline',
      {
        'w-4': !hasWidth(className),
        'h-4': !hasHeight(className)
      },
      className
    )}
    {...restProps}
  />
)

export const TimesIcon = ({
  className,
  ...restProps
}: React.SVGProps<SVGSVGElement>) => (
  <Times
    className={cx(
      'inline',
      {
        'w-3': !hasWidth(className),
        'h-3': !hasHeight(className)
      },
      className
    )}
    {...restProps}
  />
)

/* font icons (linear icons) https://fonts.thembay.com/linearicons/ */

type IconProps = React.HTMLAttributes<HTMLSpanElement>

interface BaseIconProps extends IconProps {
  name: string
}

const BaseIcon = ({ name, className, ...restProps }: BaseIconProps) => (
  <span className={cx(`icon-${name}`, className)} {...restProps} />
)

export const ClipboardIcon = (props: IconProps) => (
  <BaseIcon name="clipboard-text" {...props} />
)

export const ListIcon = (props: IconProps) => (
  <BaseIcon name="list" {...props} />
)

export const BuildingIcon = (props: IconProps) => (
  <BaseIcon name="apartment" {...props} />
)

export const TargetIcon = (props: IconProps) => (
  <BaseIcon name="archery" {...props} />
)

export const ShareIcon = (props: IconProps) => (
  <BaseIcon name="users2" {...props} />
)

export const UserPlusIcon = (props: IconProps) => (
  <BaseIcon name="user-plus" {...props} />
)

export const ThumbsUpIcon = (props: IconProps) => (
  <BaseIcon name="thumbs-up" style={{ padding: 1 }} {...props} />
)

export const ThumbsDownIcon = (props: IconProps) => (
  <BaseIcon name="thumbs-down" style={{ padding: 1 }} {...props} />
)

export const ChevronLeft = (props: IconProps) => (
  <BaseIcon name="chevron-left" {...props} />
)

export const ChevronRight = (props: IconProps) => (
  <BaseIcon name="chevron-right" {...props} />
)

export const DoubleChevronLeft = ({ className }: IconProps) => (
  <span className={cx(styles.doubleChevron, className)}>
    <ChevronLeft />
    <ChevronLeft />
  </span>
)

export const DoubleChevronRight = ({ className }: IconProps) => (
  <span className={cx(styles.doubleChevron, className)}>
    <ChevronRight />
    <ChevronRight />
  </span>
)

export const CaretRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <AiFillCaretRight {...props} />
)

export const CaretLeftIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <AiFillCaretLeft {...props} />
)

export const CaretDownIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <AiFillCaretDown {...props} />
)

export const CaretUpIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <AiFillCaretUp {...props} />
)

export const QuestionMarkIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <AiOutlineQuestion {...props} />
)

export const ForbidIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <RiForbid2Line {...props} />
)

export const FamilyIcon = (props: IconProps) => (
  <BaseIcon name="man-woman" {...props} />
)

export const WomanIcon = (props: IconProps) => (
  <BaseIcon name="woman" {...props} />
)

export const ManIcon = (props: IconProps) => <BaseIcon name="man" {...props} />

export const MagnifierIcon = (props: IconProps) => (
  <BaseIcon name="magnifier" {...props} />
)

export const FunnelIcon = (props: IconProps) => (
  <BaseIcon name="funnel" {...props} />
)

export const CrossIcon = (props: IconProps) => (
  <BaseIcon name="cross" {...props} />
)

export const EyeIcon = (props: IconProps) => <BaseIcon name="eye" {...props} />

export const SortAmountDescIcon = (props: IconProps) => (
  <BaseIcon name="sort-amount-desc" {...props} />
)

export const CheckThinIcon = (props: IconProps) => (
  <BaseIcon name="check" {...props} />
)

export const FlagIcon = (props: IconProps) => (
  <BaseIcon name="flag" {...props} />
)

export const UploadIcon = (props: IconProps) => (
  <BaseIcon name="upload2" {...props} />
)

export const HourGlassIcon = (props: IconProps) => (
  <BaseIcon name="hourglass" {...props} />
)

export const AlertIcon = (props: IconProps) => (
  <BaseIcon name="warning" {...props} />
)

export const QuestionMarkCircleIcon = (props: IconProps) => (
  <BaseIcon name="question-circle" {...props} />
)

export const SmallBangIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <Bang {...props} />
)

export const LinkIcon = (props: IconProps) => (
  <BaseIcon name="link" {...props} />
)

export const FileIcon = (props: IconProps) => (
  <BaseIcon name="file-empty" {...props} />
)

export const ProfileIcon = (props: IconProps) => (
  <BaseIcon name="profile" {...props} />
)
