import { useState } from 'react'
import { Logger, LoggerFactory, HttpError, isHttpError } from '@deep6ai/common'

import { AnyFunction } from '../../types/AnyFunction'
import { useIsMountedRef } from '../useIsMountedRef/useIsMountedRef'

export enum AsyncStatus {
  Pristine,
  Pending,
  Error,
  Resolved
}

/**
 * @name useAsyncFunction
 *
 * @author jeff@deep6.ai, tim@deep6.ai, gabriel.katz@deep6.ai
 *
 * @description Abstracts away a common pattern with async functions in
 * components where the caller needs to cache the last known response from
 * an async call, show some loading UI, and/or handle errors gracefully.
 *
 * This hook accepts an async function as an argument and returns a wrapped
 * version of that function and some state properties that map to its current
 * async status, the last response it returned successfully (if any), and any
 * relevant error information that might have occurred. These properties are
 * subscribed to the lifecycle of the wrapped function and update themselves
 * automatically every time it gets invoked.
 *
 * In other words, we encapsulate this pattern:
 *
 ```TypeScript
 const [response, setResponse] = useState();
 const [isLoading, setIsLoading] = useState(false);
 const [hasError, setHasError] = useState(false);
 const [error, setError] = useState();

 function async handleAction() {
      try {
        setIsLoading(true);
        const res = await someAsyncFunction();
        setResponse(res);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        setHasError(true);
        console.error(e);
        setError(e);
      }
  }
 ```
 *
 * into something much more compact and readable:
 *
 ```TypeScript
 const [wrappedFunction, {status, data, error}] = useAsyncFunction(someAsyncFunction);
 ```
 *
 * Pairs well with AsyncStatusView.
 *
 * @param fn {function} - the async function we want to subscribe to
 * @param [config] {UseAsyncFunctionConfigOptions<>ThenArg<ReturnType<typeof fn>>>}
 *   - Optional configuration data to customize the default behavior of the hook.
 *   Allows setting the initial state and can be configured to re-throw errors
 *   instead of swallowing them.
 *
 * @return {AsyncFunctionResponse}
 */
export const useAsyncFunction = <FunctionToWrap extends AnyFunction>(
  fn: FunctionToWrap,
  config?: UseAsyncFunctionConfigOptions<ThenArg<ReturnType<typeof fn>>>,
  logger: Logger = LoggerFactory.build()
): AsyncFunctionResponse<
  (
    ...args: ArgumentTypes<typeof fn>
  ) => Promise<ThenArg<ReturnType<typeof fn>>>,
  ThenArg<ReturnType<typeof fn>>
> => {
  const [status, setStatus] = useState<AsyncStatus>(
    !config?.initialData ? AsyncStatus.Pristine : AsyncStatus.Resolved
  )
  const [error, setError] = useState<HttpError | undefined>()

  const [response, setResponse] = useState(config?.initialData)

  const isMounted = useIsMountedRef()

  async function wrappedFn(...args: ArgumentTypes<typeof fn>) {
    try {
      setStatus(AsyncStatus.Pending)
      setError(undefined)
      const res = await fn(...args)
      if (isMounted.current) {
        setResponse(res)
        setStatus(AsyncStatus.Resolved)
      }
      return res
    } catch (e) {
      logger.error(e)

      if (isMounted.current) {
        setStatus(AsyncStatus.Error)
        const httpError: HttpError = isHttpError(e)
          ? e
          : {
              message: e?.message ?? 'An unhandled exception has occurred',
              status: e?.status ?? 500,
              trace: e?.trace ?? undefined
            }
        setError(httpError)
      }

      if (config?.throwExceptions) {
        throw e
      }
    }
  }

  function reset() {
    if (status !== AsyncStatus.Pending) {
      setStatus(AsyncStatus.Pristine)
      setError(undefined)
      setResponse(undefined)
    }
  }

  switch (status) {
    case AsyncStatus.Resolved: {
      return [wrappedFn, { data: response!, status, error: undefined }, reset]
    }
    case AsyncStatus.Error: {
      return [wrappedFn, { data: undefined, status, error: error! }, reset]
    }
    case AsyncStatus.Pending: {
      return [wrappedFn, { data: response, status, error: undefined }, reset]
    }
    default: {
      return [
        wrappedFn,
        {
          data: undefined,
          status,
          error: undefined
        },
        reset
      ]
    }
  }
}

// captures the incoming function args
type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any
  ? A
  : never

// captures the return type of the incoming function stripped of its promise
type ThenArg<T> = T extends PromiseLike<infer U> ? U : T

type ResetFn = () => void

export interface UseAsyncFunctionConfigOptions<InitialData> {
  /**
   * @description sets the value of the `data` return value. Useful for cases
   * where you want to start with an initial state. Perhaps provided by props
   * or similar.
   */
  initialData?: InitialData

  /**
   * @description by default useAsyncFunction sets errors into a state param
   * and does not throw, leaving the decision to the caller. There are times
   * where this is not desirable. Setting this to `true` will re-throw any
   * exceptions that are caught.
   */
  throwExceptions?: boolean
}

export type AsyncFunctionResponseState<ReturnType> =
  | {
      status: AsyncStatus.Pristine
      error: undefined
      data: undefined
    }
  | {
      status: AsyncStatus.Pending
      error: undefined
      data: ReturnType | undefined
    }
  | {
      status: AsyncStatus.Resolved
      error: undefined
      data: ReturnType
    }
  | {
      status: AsyncStatus.Error
      error: HttpError
      data: ReturnType | undefined
    }

export type AsyncFunctionResponse<FunctionToWrap extends Function, ReturnType> =
  [FunctionToWrap, AsyncFunctionResponseState<ReturnType>, ResetFn]
