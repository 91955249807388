import React from 'react'
import ReactTooltip, { TooltipProps as ReactTooltipProps } from 'react-tooltip'
import cx from 'classnames'

export type ToolTipProps = ReactTooltipProps

export const ToolTip = ({
  id,
  delayShow = 700,
  effect = 'solid',
  children,
  className,
  ...rest
}: ToolTipProps) => {
  const styles = cx(
    'leading-normal max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2xl',
    className
  )

  return (
    <ReactTooltip
      id={id}
      delayShow={delayShow}
      effect={effect}
      className={styles}
      overridePosition={({ left, top }, _e, _t, node) => {
        // adjust position if overflowing left
        // overflow right is already handled
        // https://github.com/wwayne/react-tooltip/issues/476#issuecomment-607880172
        return {
          top,
          left: typeof node === 'string' ? left : Math.max(left, 0)
        }
      }}
      {...rest}
    >
      {children}
    </ReactTooltip>
  )
}
