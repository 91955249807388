import React from 'react'
import { LegacyInput as Input } from '@deep6ai/component-library'

import { ConfirmButtons } from '../../Modal/ConfirmButtons'
import { FormControlErrorMessage } from '../Error'
import { FormGroup } from '../FormGroup'
import { Label } from '../Label'

export interface LinkAttachmentInputProps {
  displayName?: string
  error?: string
  onAdd: () => void
  onCancel: () => void
  onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void
  onChangeUrl: (event: React.ChangeEvent<HTMLInputElement>) => void
  url?: string
}

export const LinkAttachmentInput = ({
  displayName,
  error,
  onAdd,
  onCancel,
  onChangeName,
  onChangeUrl,
  url
}: LinkAttachmentInputProps) => {
  const handleAddButtonClick = () => {
    onAdd()
  }

  const handleCancelButtonClick = () => {
    onCancel()
  }

  return (
    <div>
      <FormGroup>
        <Label htmlFor="linkName">Text to Display</Label>
        <Input
          id="linkName"
          onChange={onChangeName}
          placeholder="Add a link name"
          value={displayName}
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor="linkUrl">Web address</Label>

        <div className="flex items-center">
          <Input
            id="linkUrl"
            isError={!!error}
            onChange={onChangeUrl}
            placeholder="Enter a url"
            value={url}
          />

          <ConfirmButtons
            confirmButtonText="Add"
            onCancel={handleCancelButtonClick}
            onConfirm={handleAddButtonClick}
          />
        </div>

        <FormControlErrorMessage error={error} />
      </FormGroup>
    </div>
  )
}
