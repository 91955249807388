import { container, inject, injectable } from 'tsyringe'
import { StorageClient } from '@deep6ai/common'

import * as InMemoryStorageClientIoc from '../../lib/storage/InMemoryStorageClient.ioc'

@injectable()
export class TrialRecommenderContextService {
  private readonly STORAGE_KEY = 'trialRecommenderCtx'

  constructor(
    @inject(InMemoryStorageClientIoc.injectionToken)
    private storageClient: StorageClient<boolean, boolean>
  ) {}

  setContext = (trialRecommenderContext: boolean) => {
    this.storageClient.set(this.STORAGE_KEY, trialRecommenderContext)
  }

  getContext = (): boolean => this.storageClient.get(this.STORAGE_KEY) ?? false

  static build() {
    return container.resolve(TrialRecommenderContextService)
  }
}

export const trialRecommenderContextService =
  TrialRecommenderContextService.build()
