import { AbstractAction } from '../abstract-action'
import { AccountActionTypes } from './account.actions'
import { AccountDisplayName } from '../../services/account/account-service'
import {
  PaginatedResponse,
  emptyPaginatedContent
} from '../../lib/http/paginated-response'

export interface AccountReducerSlice {
  availableTeamMembers: PaginatedResponse<AccountDisplayName[]>
  availableShareRecipients: PaginatedResponse<AccountDisplayName[]>
}

export const initialState: AccountReducerSlice = {
  availableTeamMembers: emptyPaginatedContent(),
  availableShareRecipients: emptyPaginatedContent()
}

export default function accountReducer(
  state = initialState,
  action: AbstractAction<AccountActionTypes>
): AccountReducerSlice {
  switch (action.type) {
    case AccountActionTypes.addAvailableTeamMembers:
      return {
        ...state,
        availableTeamMembers:
          action.payload as AccountReducerSlice['availableTeamMembers']
      }
    case AccountActionTypes.clearAvailableTeamMembers:
      return {
        ...initialState,
        availableTeamMembers: initialState.availableTeamMembers
      }
    case AccountActionTypes.addAvailableShareRecipients:
      return {
        ...state,
        availableShareRecipients:
          action.payload as AccountReducerSlice['availableShareRecipients']
      }
    case AccountActionTypes.clearAvailableShareRecipients:
      return {
        ...initialState,
        availableShareRecipients: initialState.availableShareRecipients
      }
    default:
      return state
  }
}
