const dynamicallyLoadScript = (url) => {
  const script = document.createElement('script')
  script.src = url
  document.head.appendChild(script)
}

const isInternetExplorer =
  !!window.MSInputMethodContext && !!document['documentMode']

if (isInternetExplorer) {
  /*
    this polyfill script is necessary to load outside of the config-override
    due to a race condition when rendering pages caused by a link or redirect
  */
  dynamicallyLoadScript('https://cdn.polyfill.io/v3/polyfill.min.js')
}

export {}
