import { injectable } from 'tsyringe'

import {
  BucketMapper,
  ReducerCB,
  MissingMetadataException
} from './bucket-mapper'
import {
  AllowedReferralRecruitmentStatuses,
  Recruitment,
  RecruitmentBucketKey,
  ReferralMetadata
} from '../Recruitment'
import { IncomingRecruitmentBucketItemMetadata } from './recruitment-bucket-mapper'
import { ValidatedMapper } from './validated-mapper'

@injectable()
export class ReferredMapper implements BucketMapper<ReferralMetadata> {
  constructor(private validatedMapper: ValidatedMapper) {}

  buildMapper(): ReducerCB<ReferralMetadata> {
    return (aggregate, current) => {
      return this.bucketMapper(aggregate, current, this.transform)
    }
  }

  transform = (
    metadata: IncomingRecruitmentBucketItemMetadata
  ): ReferralMetadata => {
    if (!metadata.statusMetadata.referredMetadata?.timestamp) {
      throw new MissingMetadataException(RecruitmentBucketKey.REFERRED)
    }

    const { statusMetadata: validationMetadata } =
      this.validatedMapper.transform(metadata)

    return {
      currentStatus:
        metadata.currentStatus as AllowedReferralRecruitmentStatuses,
      statusMetadata: {
        action: metadata.statusMetadata.referredMetadata.action,
        referredAt: metadata.statusMetadata.referredMetadata.timestamp,
        validationMetadata
      }
    }
  }

  private bucketMapper = (
    buckets: Recruitment<ReferralMetadata>[],
    incomingItem: Recruitment<IncomingRecruitmentBucketItemMetadata>,
    metadataTransform: (
      metadata: IncomingRecruitmentBucketItemMetadata
    ) => ReferralMetadata
  ): Recruitment<ReferralMetadata>[] => {
    return [
      ...buckets,
      {
        ...incomingItem,
        metadata: metadataTransform(incomingItem.metadata)
      }
    ]
  }
}
