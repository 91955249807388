import { container, inject, injectable } from 'tsyringe'

import { AbstractAction } from '../abstract-action'
import {
  ConfigurationService,
  PPPConfig
} from '../../services/configuration/configuration.service'
import { ReduxAsyncNotifier } from '../../lib/async-notifier/redux-async-notifier'
import type { AsyncNotifier } from '../../lib/async-notifier/async-notifier'

export enum ConfigurationActionTypes {
  setConfig = 'CONFIG::SET'
}

@injectable()
export class ConfigurationActions {
  constructor(
    @inject(ReduxAsyncNotifier.injectionToken)
    private asyncNotifier: AsyncNotifier,

    private configurationService: ConfigurationService
  ) {}

  setConfig(
    config: PPPConfig
  ): AbstractAction<ConfigurationActionTypes.setConfig, PPPConfig> {
    return {
      type: ConfigurationActionTypes.setConfig,
      payload: config
    }
  }

  getConfig = this.asyncNotifier.subscribe(() => async (dispatch) => {
    const config = await this.configurationService.getConfig()

    dispatch(this.setConfig(config))
  })

  static Build() {
    return container.resolve(ConfigurationActions)
  }
}

export const configurationActions = ConfigurationActions.Build()
