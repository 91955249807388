import { AbstractAction } from '../abstract-action'
import { OrganizationActionTypes } from './organization.actions'
import {
  Organization,
  DepartmentType
} from '../../services/organization/organization.service'

interface OrganizationReducerSlice {
  healthSystemOrganizations: Organization[]
  healthSystemDepartments: DepartmentType[]
}

export const initialState: OrganizationReducerSlice = {
  healthSystemOrganizations: [],
  healthSystemDepartments: []
}

export default function organizationReducer(
  state: OrganizationReducerSlice = initialState,
  action: AbstractAction<OrganizationActionTypes>
) {
  switch (action.type) {
    case OrganizationActionTypes.addTargetSites:
      return {
        ...state,
        healthSystemOrganizations: action.payload
      }
    case OrganizationActionTypes.addDepartments:
      return {
        ...state,
        healthSystemDepartments: action.payload
      }
    default:
      return state
  }
}
