import { injectable } from 'tsyringe'

import { ReducerCB, BucketMapper } from './bucket-mapper'
import {
  AllowedValidatedRecruitmentStatuses,
  ProviderSelection,
  ProviderSelectionType,
  Recruitment,
  ValidatedMetadata
} from '../Recruitment'
import { IncomingRecruitmentBucketItemMetadata } from './recruitment-bucket-mapper'

@injectable()
export class ValidatedMapper implements BucketMapper<ValidatedMetadata> {
  buildMapper(): ReducerCB<ValidatedMetadata> {
    return (aggregate, current) => {
      return this.bucketMapper(aggregate, current, this.transform)
    }
  }

  transform = (
    metadata: IncomingRecruitmentBucketItemMetadata
  ): ValidatedMetadata => {
    let providerSelection: ProviderSelection | null = null

    switch (
      metadata.statusMetadata.providerSelectedMetadata?.provider['@type']
    ) {
      case 'provider':
        providerSelection = {
          type: ProviderSelectionType.FOUND,
          provider: metadata.statusMetadata.providerSelectedMetadata.provider
        }
        break
      case 'providerNotFound':
        providerSelection = {
          type: ProviderSelectionType.NOT_FOUND
        }
        break
      case 'providerSkipped':
        providerSelection = {
          type: ProviderSelectionType.SKIPPED
        }
        break
    }

    return {
      currentStatus:
        metadata.currentStatus as AllowedValidatedRecruitmentStatuses,
      statusMetadata: {
        validatedAt:
          metadata.statusMetadata.validatedMetadata?.timestamp ?? null,
        providerSelection
      }
    }
  }

  private bucketMapper = (
    buckets: Recruitment<ValidatedMetadata>[],
    incomingItem: Recruitment<IncomingRecruitmentBucketItemMetadata>,
    metadataTransform: (
      metadata: IncomingRecruitmentBucketItemMetadata
    ) => ValidatedMetadata
  ): Recruitment<ValidatedMetadata>[] => {
    return [
      ...buckets,
      {
        ...incomingItem,
        metadata: metadataTransform(incomingItem.metadata)
      }
    ]
  }
}
