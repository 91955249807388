import React, { ReactNode } from 'react'
import cx from 'classnames'
import { If } from '@deep6ai/component-library'

import { HeadingThinLarge } from '../Text/Heading/Heading'
import { AlertIcon } from '../Icon/Icon'

interface ErrorMessageAlertProps extends React.HTMLAttributes<HTMLDivElement> {
  primaryText: ReactNode
  secondaryText?: ReactNode
}

export const ErrorMessageAlert = ({
  primaryText,
  secondaryText,
  className,
  id = 'errorMessage',
  ...rest
}: ErrorMessageAlertProps) => (
  <div
    id={id}
    data-testid={id}
    className={cx('text-center', className)}
    {...rest}
  >
    <HeadingThinLarge className="flex align-middle justify-center">
      <AlertIcon className="text-5xl mr-3" /> {primaryText}
    </HeadingThinLarge>
    <If condition={!!secondaryText}>
      <h3 className="text-grey-50 text-2xl mt-10">{secondaryText}</h3>
    </If>
  </div>
)
