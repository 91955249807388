import React from 'react'
import { datadogRum, DefaultPrivacyLevel } from '@datadog/browser-rum'
import { useSelector } from 'react-redux'

import { AuthenticationReducerSlice } from '../../../store/authentication/authentication.reducer'
import { ConfigurationReducerSlice } from '../../../store/configuration/configuration.reducer'
import { isProd } from '../../../config/env'

export function DataDogRUM() {
  const activeUser = useSelector(
    ({ authentication }: { authentication: AuthenticationReducerSlice }) =>
      authentication.activeUser!
  )

  const { applicationId, clientToken, environment } = useSelector(
    ({ config }: { config: ConfigurationReducerSlice }) =>
      config.pppConfig!.datadog
  )

  if (isProd) {
    datadogRum.init({
      applicationId,
      clientToken,
      service: 'patient-portal-ui',
      defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
      sampleRate: 100,
      premiumSampleRate: 0,
      trackInteractions: false, // setting this to true could expose sensitive data
      trackFrustrations: false, // requires trackInteractions to be enabled
      useSecureSessionCookie: true,
      env: `${environment}-${activeUser.organization.slug}`
    })

    datadogRum.setUser({
      id: activeUser.accountUuid,
      name: activeUser.displayName,
      organizationUuid: activeUser.organization.uuid,
      organizationName: activeUser.organization.displayName,
      organizationSlug: activeUser.organization.slug
    })
  }

  return <></>
}
