import { container, inject, injectable } from 'tsyringe'

import { RunDetails, StudyRunService } from '../../services/run/StudyRunService'
import { ReduxAsyncNotifier } from '../../lib/async-notifier/redux-async-notifier'
import type { AsyncNotifier } from '../../lib/async-notifier/async-notifier'
import { AbstractAction } from '../abstract-action'
import { RedirectActions } from '../redirect/redirect.actions'
import { StudyService, StudySummary } from '../../services/study/study.service'
import { User } from '../../services/authentication/authentication-service'
import { OrganizationService } from '../../services/organization/organization.service'
import { StudyNotificationService } from '../../services/study-notification/study-notification-service'

export enum RunActionTypes {
  setActiveRun = 'RUN::SET_ACTIVE_RUN',
  clearActiveRun = 'RUN::CLEAR_ACTIVE_RUN'
}

@injectable()
export class RunActions {
  constructor(
    private studyRunService: StudyRunService,
    private studyService: StudyService,
    @inject(ReduxAsyncNotifier.injectionToken)
    private asyncNotifier: AsyncNotifier,
    private redirectActions: RedirectActions,
    private studyNotificationService: StudyNotificationService
  ) {}

  setActiveRun(run: RunDetails): AbstractAction<RunActionTypes, RunDetails> {
    return {
      type: RunActionTypes.setActiveRun,
      payload: run
    }
  }

  clearActiveRun(): AbstractAction<RunActionTypes> {
    return {
      type: RunActionTypes.clearActiveRun
    }
  }

  createRunAndRedirect = this.asyncNotifier.subscribe(
    (studyUuid: string) => async (dispatch) => {
      const createdRun = await this.studyRunService.createRun(studyUuid)
      dispatch(this.setActiveRun(createdRun))
      dispatch(this.redirectActions.goTo(`/studies/${studyUuid}/site-counts`))
    }
  )

  createRun = this.asyncNotifier.subscribe(
    (studyUuid: string) => async (dispatch) => {
      const createdRun = await this.studyRunService.createRun(studyUuid)
      dispatch(this.setActiveRun(createdRun))
    }
  )

  clearUnreadShareNotificationsAndRedirect = this.asyncNotifier.subscribe(
    (study: StudySummary, activeUser: User) => async (dispatch) => {
      await this.studyNotificationService.clearAllUnread(study.notifications)
      dispatch(
        this.redirectActions.goTo(
          this.getStudyDetailsRedirectUrl(study, activeUser)
        )
      )
    }
  )

  private getStudyDetailsRedirectUrl(
    study: StudySummary,
    activeUser: User
  ): string {
    const noRunsRedirectUrl = OrganizationService.isHealthSystemUser(activeUser)
      ? `/studies/${study.studyUuid}/healthSystem/${activeUser.organization.healthSystem?.healthSystemUuid}/build-query`
      : `/studies/${study.studyUuid}/build-query`

    const hasRunsRedirectUrl = OrganizationService.isHealthSystemUser(
      activeUser
    )
      ? `/studies/${study.studyUuid}/healthSystem/${activeUser.organization.healthSystem?.healthSystemUuid}/patient-list`
      : `/studies/${study.studyUuid}/site-counts`

    const studyHasRuns = !!study.counts

    return studyHasRuns ? hasRunsRedirectUrl : noRunsRedirectUrl
  }

  static Build() {
    return container.resolve(RunActions)
  }
}

export const runActions = RunActions.Build()
