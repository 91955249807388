export function isPromiseFulfilledResult<T = unknown>(
  promise: PromiseSettledResult<T>
): promise is PromiseFulfilledResult<T> {
  return promise.status === 'fulfilled'
}

export function isPromiseRejectedResult(
  promise: PromiseSettledResult<unknown>
): promise is PromiseRejectedResult {
  return promise.status === 'rejected'
}
