import { container, inject, injectable } from 'tsyringe'
import type { Logger } from '@deep6ai/common'
import { HttpError, isHttpError } from '@deep6ai/common'

import { AsyncNotifierActions } from '../../store/async-notifier/async-notifier.reducer'
import type { AsyncNotifier } from './async-notifier'
import * as LoggerIoc from '../logging/Logger.ioc'

export type ActionSubscriber = (...args) => (dispatch: (...args) => void) => any

@injectable()
export class ReduxAsyncNotifier implements AsyncNotifier<ActionSubscriber> {
  static readonly injectionToken = 'ReduxAsyncNotifier'

  private readonly metadataKey = 'async-notifier-notifier-id'

  constructor(@inject(LoggerIoc.injectionToken) private logger: Logger) {}

  /**
   * @name subscribe
   * @param {ActionSubscriber} fn - an async-notifier redux action that has been wired up to the dispatch call via mapDispatchToProps
   * @returns {ActionSubscriber} fn - returns the original function passed but wrapped in an outer function that creates a unique identifier,
   * dispatches that identifier to a pending queue in redux, calls the original function, and then removes that identifier from the queue in redux.
   * Said identifier is then attached to the wrapper function via metadata so other parts of the app can subscribe to it.
   */
  public subscribe(fn: ActionSubscriber): ActionSubscriber {
    const id = this.generateUuid()

    const newFn: ActionSubscriber =
      (...args) =>
      async (dispatch) => {
        try {
          dispatch({
            type: AsyncNotifierActions.PendingPush,
            payload: id
          })

          const res = await fn(...args)(dispatch)

          dispatch({
            type: AsyncNotifierActions.PendingPop,
            payload: id
          })

          return res
        } catch (e) {
          this.logger.error(e)

          const payload: HttpError = isHttpError(e)
            ? e
            : {
                message: e?.message ?? 'An unhandled exception has occurred',
                status: e?.status ?? 500,
                trace: e?.trace ?? undefined
              }

          dispatch({
            type: AsyncNotifierActions.ErrorPush,
            payload: {
              [id]: payload
            }
          })
        }
      }

    Reflect.defineMetadata(this.metadataKey, id, newFn)

    return newFn
  }

  public getSubscription = (action: ActionSubscriber): string => {
    return Reflect.getMetadata(this.metadataKey, action)
  }

  private generateUuid() {
    // taken from: https://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
    const replacer = (current) => {
      const rand = (Math.random() * 16) | 0,
        replaceWith = current === 'x' ? rand : (rand & 0x3) | 0x8
      return replaceWith.toString(16)
    }

    const pattern = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'

    return pattern.replace(/[xy]/g, replacer)
  }

  static Build() {
    container.register(ReduxAsyncNotifier.injectionToken, {
      useClass: ReduxAsyncNotifier
    })
    return container.resolve(ReduxAsyncNotifier)
  }
}

export const reduxAsyncNotifier = ReduxAsyncNotifier.Build()
