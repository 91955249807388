import React from 'react'
import * as components from '@reach/menu-button'
import { positionRight } from '@reach/popover'
import { IconBaseProps } from 'react-icons'
import { FaEllipsisH } from 'react-icons/fa'
import { Link } from 'react-router-dom'

export const Menu = (props: components.MenuProps) => (
  <components.Menu data-testid="Menu" {...props} />
)

export const MenuButton = (props: components.MenuButtonProps) => (
  <components.MenuButton data-testid="MenuButton" {...props} />
)

export const MenuIcon = (props: IconBaseProps) => <FaEllipsisH {...props} />

const classNameMenuList = 'w-auto rounded-sm z-10 relative'

export const MenuList = ({ id, ...props }: components.MenuListProps) => (
  <components.MenuList
    className={classNameMenuList}
    data-testid={id || 'MenuList'}
    {...props}
  />
)

export const MenuListRight = ({
  id,
  ...props
}: components.MenuPopoverProps) => (
  <components.MenuPopover
    position={positionRight}
    className={classNameMenuList}
    data-testid={id || 'MenuList'}
    {...props}
  />
)

export const MenuItems = (props: components.MenuItemsProps) => (
  <components.MenuItems
    className="py-1 rounded-sm bg-white shadow-xs"
    data-testid="MenuItems"
    {...props}
  />
)

const classNameMenuItem = 'block px-4 py-2 hover:bg-grey-05 cursor-pointer'

export const MenuItem = ({
  id,
  ...props
}: { id?: string } & components.MenuItemProps) => (
  <components.MenuItem
    className={classNameMenuItem}
    data-testid={id || `MenuItem-${props.children}`}
    {...props}
  />
)

export const MenuLink = (props: any) => (
  <components.MenuLink
    as={Link}
    className={classNameMenuItem}
    data-testid={`MenuLink-${props.to}`}
    {...props}
  >
    {props.children}
  </components.MenuLink>
)
