import { container, inject, injectable } from 'tsyringe'
import { JsonHttpClient, UrlBuilder } from '@deep6ai/common'

import * as SpokeHttpClientIoc from '../../lib/spoke-http-client/SpokeHttpClient.ioc'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import {
  EligibleRecruitmentStatusMetadata,
  PISelection,
  PISelectionType,
  Recruitment,
  RecruitmentBucketKey,
  RecruitmentBuckets,
  UnbucketedPISelection
} from './Recruitment'
import {
  IncomingRecruitmentBucketItemMetadata,
  RecruitmentBucketMapper
} from './mappers/recruitment-bucket-mapper'

@injectable()
export class RecruitmentService {
  protected readonly basePath = '/recruitment'

  constructor(
    @inject(SpokeHttpClientIoc.injectionToken)
    private spokeHttpClient: JsonHttpClient,
    @inject(UrlBuilderIoc.injectionToken) protected urlBuilder: UrlBuilder,
    private bucketMapper: RecruitmentBucketMapper
  ) {}

  getRecruitingPatients = async (
    studyUuid: string,
    healthSystemUuid: string
  ): Promise<RecruitmentBuckets> => {
    const url = this.urlBuilder.build(this.formatPath(studyUuid), {
      healthSystemUuid
    })

    const res: Recruitment<IncomingRecruitmentBucketItemMetadata>[] =
      await this.spokeHttpClient.getJson(url)

    return this.bucketMapper.bucketRecruitmentResponse(res)
  }

  trackEnrollment = (
    studyUuid: string,
    patientUuid: string
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/enrollments/track`
    )
    return this.spokeHttpClient.postJson(url)
  }

  trackConsent = (studyUuid: string, patientUuid: string): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/consents/track`
    )
    return this.spokeHttpClient.postJson(url)
  }

  trackConsultation = (
    studyUuid: string,
    patientUuid: string
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/consultations/track`
    )
    return this.spokeHttpClient.postJson(url)
  }

  saveConsultationDetails = (
    studyUuid: string,
    patientUuid: string,
    consultationDate: string,
    principleInvestigator: PISelection
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/consultations/details`
    )

    return this.spokeHttpClient.putJson(url, {
      body: JSON.stringify({
        consultationDate,
        principleInvestigator:
          RecruitmentService.pISelectionToUnbucketedPISelection(
            principleInvestigator
          )
      })
    })
  }

  trackReferral = (
    studyUuid: string,
    patientUuid: string
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/track-referral`
    )
    return this.spokeHttpClient.putJson(url)
  }

  trackProviderReferralDenied = (
    studyUuid: string,
    patientUuid: string
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/referral/deny`
    )
    return this.spokeHttpClient.postJson(url)
  }

  retryReferral = (
    studyUuid: string,
    patientUuid: string
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/referral/retry`
    )
    return this.spokeHttpClient.postJson(url)
  }

  skipReferral = (studyUuid: string, patientUuid: string): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/referral/skip`
    )
    return this.spokeHttpClient.postJson(url)
  }

  trackRejectedReferralDenied = (
    studyUuid: string,
    patientUuid: string
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/rejected-referral-denied/track`
    )
    return this.spokeHttpClient.postJson(url)
  }

  trackNotInterested = (
    studyUuid: string,
    patientUuid: string
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/not-interested/track`
    )
    return this.spokeHttpClient.postJson(url)
  }

  trackConsentDenied = (
    studyUuid: string,
    patientUuid: string
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/consent-denied/track`
    )
    return this.spokeHttpClient.postJson(url)
  }

  trackFailedScreening = (
    studyUuid: string,
    patientUuid: string
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/failed-screening/track`
    )
    return this.spokeHttpClient.postJson(url)
  }

  selectProvider = (
    studyUuid: string,
    patientUuid: string,
    providerId: string
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/select-provider/${providerId}`
    )
    return this.spokeHttpClient.postJson(url)
  }

  undoSelectProvider = (
    studyUuid: string,
    patientUuid: string
  ): Promise<unknown> => {
    const url = this.formatPath(
      `${studyUuid}/patient/${patientUuid}/select-provider`
    )
    return this.spokeHttpClient.putJson(url)
  }

  restore = (studyUuid: string, patientUuid: string): Promise<unknown> => {
    const url = this.formatPath(`${studyUuid}/patient/${patientUuid}/restore`)
    return this.spokeHttpClient.postJson(url)
  }

  static findSelectedRecruitment = (
    patientUuid: string | undefined,
    recruitmentBuckets: RecruitmentBuckets | undefined | null
  ) => {
    return Object.keys(recruitmentBuckets!)
      .filter((key) => {
        return (
          key !== RecruitmentBucketKey.ENROLLED &&
          key !== RecruitmentBucketKey.INELIGIBLE
        )
      })
      .flatMap((key) => {
        const bucket: Recruitment<EligibleRecruitmentStatusMetadata>[] =
          recruitmentBuckets![key]
        return bucket
      })
      .find((recruitment) => {
        return recruitment.patient.uuid === patientUuid
      })
  }

  private static pISelectionToUnbucketedPISelection = (
    piSelection: PISelection
  ): UnbucketedPISelection => {
    if (piSelection.type === PISelectionType.NOT_FOUND) {
      return {
        '@type': 'accountNotFound'
      }
    }
    return {
      '@type': 'accountReference',
      userUuid: piSelection.accountUuid
    }
  }

  static getPatientRecruitmentBucketDisplayName = (
    bucketKey: RecruitmentBucketKey
  ) => {
    switch (bucketKey) {
      case RecruitmentBucketKey.VALIDATED:
        return 'Validated'
      case RecruitmentBucketKey.REFERRED:
        return 'Referred'
      case RecruitmentBucketKey.CONSULT_SCHEDULED:
        return 'Consult Scheduled'
      case RecruitmentBucketKey.IN_SCREENING:
        return 'In Screening'
      case RecruitmentBucketKey.ENROLLED:
        return 'Enrolled'
      case RecruitmentBucketKey.INELIGIBLE:
        return 'Ineligible'
      default:
        return 'N/A'
    }
  }

  private formatPath(p = ''): string {
    return this.urlBuilder.joinPath(this.basePath, p)
  }

  static Build() {
    return container.resolve(RecruitmentService)
  }
}

export const recruitmentService = RecruitmentService.Build()
