import { container } from 'tsyringe'
import { JsonHttpClient, SpokeHttpClientFactory } from '@deep6ai/common'

import { CentralApiHttpClient } from '../central-api-http-client/central-api-http-client'

export const injectionToken = 'SpokeHttpClient'

container.register(injectionToken, {
  useFactory: () => {
    const centralApiHttpClient = container.resolve<JsonHttpClient>(
      CentralApiHttpClient.injectionToken
    )
    return SpokeHttpClientFactory.build(centralApiHttpClient)
  }
})
