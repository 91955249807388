import { container, inject, injectable } from 'tsyringe'

import {
  User,
  AuthenticationService
} from '../../services/authentication/authentication-service'
import { ReduxAsyncNotifier } from '../../lib/async-notifier/redux-async-notifier'
import type { AsyncNotifier } from '../../lib/async-notifier/async-notifier'
import { AbstractAction } from '../abstract-action'

export enum AuthenticationActionTypes {
  setActiveUser = 'AUTH::SET_ACTIVE_USER',
  clearActiveUser = 'AUTH::CLEAR_ACTIVE_USER'
}

@injectable()
export class AuthenticationActions {
  constructor(
    @inject(ReduxAsyncNotifier.injectionToken)
    private asyncNotifier: AsyncNotifier,

    private authenticationService: AuthenticationService
  ) {}

  setActiveUser(user: User): AbstractAction<AuthenticationActionTypes, User> {
    return { type: AuthenticationActionTypes.setActiveUser, payload: user }
  }

  clearActiveUser(): AbstractAction<AuthenticationActionTypes> {
    return { type: AuthenticationActionTypes.clearActiveUser }
  }

  getActiveUserDetails = this.asyncNotifier.subscribe(
    () => async (dispatch) => {
      dispatch(
        this.setActiveUser(
          await this.authenticationService.getAuthenticatedUser()
        )
      )
    }
  )

  /**
   * @deprecated use AuthenticationService.logOut instead
   */
  logOut = this.asyncNotifier.subscribe(() => async (dispatch) => {
    await this.authenticationService.logOut()
  })

  static Build() {
    return container.resolve(AuthenticationActions)
  }
}

export const authenticationActions = AuthenticationActions.Build()
