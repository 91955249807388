import React, { MouseEvent } from 'react'
import { FaEllipsisH as MenuIcon } from 'react-icons/fa'
import cx from 'classnames'
import {
  If,
  CopyIcon,
  DownloadIcon,
  ButtonOutlineDefault
} from '@deep6ai/component-library'
import { toast } from 'react-toastify'

import {
  Attachment,
  AttachmentType,
  DownloadableAttachment,
  isCreateAttachmentFormValue,
  isDeleteAttachmentFormValue,
  isDownloadableAttachment
} from '../../services/study/StudyAttachmentService'
import { useClipboard } from '../../hooks/useClipboard/useClipboard'
import { FileIcon, LinkIcon } from '../Icon/Icon'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuListRight
} from '../Menu/Menu'
import { ToolTip } from '../ToolTip/ToolTip'
import { AttachmentFormValue } from '../Form/StudyForm/Controls/AttachmentsFormControl/AttachmentsFormControl'
import { preventDefault } from '../../lib/events/preventDefault'
import { stopPropagation } from '../../lib/events/stopPropagation'

export interface AttachmentItemProps {
  id?: string
  attachment: Attachment | AttachmentFormValue
  onDownload: (attachment: DownloadableAttachment) => void
  onPreview: (attachment: DownloadableAttachment) => void
  onEdit?: (attachment: Attachment | AttachmentFormValue) => void
  onRemove?: (attachment: Attachment | AttachmentFormValue) => void
  isEditable?: boolean
}

const attachmentContainerStyles =
  'flex items-center justify-between py-4 px-2 hover:bg-grey-05'

export const AttachmentItem = ({
  id = 'AttachmentItem',
  attachment,
  onDownload,
  onPreview,
  onEdit,
  onRemove,
  isEditable = true
}: AttachmentItemProps) => {
  const { copyToClipboard } = useClipboard()

  if (isDeleteAttachmentFormValue(attachment)) return null

  function handleMenuClick(e: MouseEvent<HTMLButtonElement>) {
    preventDefault(e)
    stopPropagation(e)
  }

  async function copyUrl() {
    if (attachment.type === AttachmentType.LINK) {
      await copyToClipboard(attachment.url)

      toast('URL copied to clipboard', {
        type: toast.TYPE.SUCCESS
      })
    }
  }

  function handleCopyLinkButtonClick(e: MouseEvent<HTMLButtonElement>) {
    preventDefault(e)
    stopPropagation(e)
    copyUrl()
  }

  function handleCopyLinkMenuItemClick() {
    copyUrl()
  }

  function handleFileDownload(e?: React.SyntheticEvent) {
    e?.stopPropagation()

    if (isDownloadableAttachment(attachment)) {
      onDownload(attachment)
    }
  }

  function handleFilePreview() {
    if (isDownloadableAttachment(attachment)) {
      onPreview(attachment)
    }
  }

  function handleEdit() {
    onEdit?.(attachment)
  }

  function handleRemove() {
    onRemove?.(attachment)
  }

  switch (attachment.type) {
    case AttachmentType.LINK:
      return (
        <a
          className={attachmentContainerStyles}
          href={attachment.url}
          style={{ outline: 'none', width: '100%' }}
          target="_blank"
          rel="noreferrer noopener"
          data-testid={id}
        >
          <div className="flex items-center truncate">
            <LinkIcon className="mr-2" />
            <p className="truncate" style={{ textDecoration: 'underline' }}>
              {attachment.displayName}
            </p>
          </div>

          <If
            condition={isEditable}
            otherwise={
              <>
                <ButtonOutlineDefault
                  data-tip
                  aria-label="Copy link to clipboard"
                  data-for="tip-CopyLink-Button"
                  id="CopyLink-Button"
                  className="mr-2 px-2 py-1"
                  onClick={handleCopyLinkButtonClick}
                >
                  <CopyIcon className="mx-1" />
                </ButtonOutlineDefault>
                <ToolTip id="tip-CopyLink-Button">
                  Copy Link to Clipboard
                </ToolTip>
              </>
            }
          >
            <Menu>
              <MenuButton className="h-6" onClick={handleMenuClick}>
                <MenuIcon />
              </MenuButton>
              <MenuListRight>
                <MenuItems>
                  <MenuItem onSelect={handleCopyLinkMenuItemClick}>
                    Copy Link
                  </MenuItem>
                  <MenuItem onSelect={handleEdit}>Edit</MenuItem>
                  <MenuItem onSelect={handleRemove}>Remove</MenuItem>
                </MenuItems>
              </MenuListRight>
            </Menu>
          </If>
        </a>
      )
    case AttachmentType.FILE:
      return (
        <div
          className={cx(attachmentContainerStyles, {
            'cursor-pointer': !isEditable
          })}
          onClick={() => !isEditable && handleFilePreview()}
          data-testid={id}
        >
          <div className="flex items-center truncate">
            <FileIcon className="mr-2" />
            <p className="truncate" style={{ textDecoration: 'none' }}>
              {attachment.displayName}
            </p>
          </div>

          <If
            condition={isEditable}
            otherwise={
              <>
                <ButtonOutlineDefault
                  data-tip
                  aria-label="Download attachment"
                  data-for="tip-DownloadAttachment-Button"
                  id="DownloadAttachment-Button"
                  className="mr-2 px-2 py-1"
                  onClick={handleFileDownload}
                >
                  <DownloadIcon className="mx-1" />
                </ButtonOutlineDefault>
                <ToolTip id="tip-DownloadAttachment-Button">
                  Download Attachment
                </ToolTip>
              </>
            }
          >
            <Menu>
              <MenuButton className="h-6" onClick={handleMenuClick}>
                <MenuIcon />
              </MenuButton>
              <MenuListRight>
                <MenuItems>
                  <If condition={!isCreateAttachmentFormValue(attachment)}>
                    <MenuItem onSelect={handleFileDownload}>
                      Download Attachment
                    </MenuItem>
                  </If>
                  <MenuItem onSelect={handleEdit}>Edit</MenuItem>
                  <MenuItem onSelect={handleRemove}>Remove</MenuItem>
                </MenuItems>
              </MenuListRight>
            </Menu>
          </If>
        </div>
      )
  }
}
