export const hasWidth = (str?: string) => hasWordPrefix('w-', str)
export const hasHeight = (str?: string) => hasWordPrefix('h-', str)
export const hasMarginTop = (str?: string) => hasWordPrefix('mt-', str)
export const hasBgColor = (str?: string) => hasWordPrefix('bg-', str)
export const hasFont = (str?: string) => hasWordPrefix('font-', str)
export const hasText = (str?: string) => hasWordPrefix('text-', str)
export const hasBorder = (str?: string) => hasWordPrefix('border-', str)
export const hasPadding = (str?: string) => hasWordPrefix('p-', str)
export const hasXPadding = (str?: string) => hasWordPrefix('px-', str)
export const hasYMargin = (str?: string) => hasWordPrefix('my-', str)
export const hasYPadding = (str?: string) => hasWordPrefix('py-', str)

function hasWordPrefix(wordPrefix: string, str?: string) {
  if (str === undefined) return false
  const re = new RegExp(`\\b(${wordPrefix})`)
  return re.test(str)
}
