import React, { HTMLAttributes, PropsWithChildren } from 'react'
import cx from 'classnames'

const largeText = 'text-4xl'

type HeadingProps = PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>

export const Heading = ({ children, className, ...rest }: HeadingProps) => {
  const headingStyles = cx(className)

  return (
    <h1 className={headingStyles} {...rest}>
      {children}
    </h1>
  )
}

export const HeadingThin = ({ children, className, ...rest }: HeadingProps) => {
  const headingStyles = cx('font-light', className)

  return (
    <Heading className={headingStyles} {...rest}>
      {children}
    </Heading>
  )
}

export const HeadingThinLarge = ({
  children,
  className,
  ...rest
}: HeadingProps) => {
  const headingStyles = cx(largeText, className)

  return (
    <HeadingThin className={headingStyles} {...rest}>
      {children}
    </HeadingThin>
  )
}

export const PageTitleHeading = ({
  children,
  className,
  ...rest
}: HeadingProps) => {
  const headingStyles = cx(
    'mr-3 truncate cursor-pointer font-semibold text-4xl',
    className
  )

  return (
    <h1 className={headingStyles} style={{ lineHeight: '44px' }} {...rest}>
      {children}
    </h1>
  )
}
