import sortBy from 'lodash/sortBy'

import { AsyncStatus } from '../useAsyncAction/useAsyncAction'

/**
 * @description takes one or more AsyncStatus enums and returns an aggregated
 * status. Useful for cases where you are waiting on more than one async status
 */
export const useAggregatedAsyncStatus = (
  statuses: AsyncStatus[]
): AsyncStatus => {
  const [leastResolvedStatus] = sortBy(statuses, (status) => {
    switch (status) {
      case AsyncStatus.Error:
        return 0
      case AsyncStatus.Pending:
        return 1
      case AsyncStatus.Pristine:
        return 2
      case AsyncStatus.Resolved:
        return 3
    }
  })

  return leastResolvedStatus ?? AsyncStatus.Pristine
}
