import { container, inject, injectable } from 'tsyringe'
import { JsonHttpClient, UrlBuilder } from '@deep6ai/common'

import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'

export enum ProtocolCriteriaTextType {
  INCLUSION,
  EXCLUSION
}

export interface ProtocolCoordinates {
  sectionIndex: number
  rowIndex: number
  phraseIndex: number
}

export interface ProtocolCriteriaText {
  text: string
  type: ProtocolCriteriaTextType
}

export interface ProtocolView {
  protocolUuid: string
  version: number
  isLocked: boolean
  expression: any // TODO we dont care about this right now
  editHistory: any[] // TODO we dont care about this right now
  createdAt: string
  updatedAt: string
}

@injectable()
export class ProtocolService {
  private readonly basePath = '/protocol'

  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private centralApiHttpClient: JsonHttpClient,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder
  ) {}

  findInFlightProtocolByStudyUuid = (
    studyUuid: string
  ): Promise<ProtocolView> => {
    return this.centralApiHttpClient.getJson(
      this.urlBuilder.build(`${this.basePath}/v2/study-uuid/${studyUuid}`)
    )
  }

  updateProtocolCriteria = (
    protocol: ProtocolView,
    studyUuid: string,
    criteria: ProtocolCriteriaText[]
  ): Promise<unknown> => {
    return this.centralApiHttpClient.putJson(
      this.urlBuilder.build(
        `${this.basePath}/${protocol.protocolUuid}/criteria`
      ),
      {
        body: JSON.stringify({
          criteria,
          studyUuid,
          version: protocol.version
        })
      }
    )
  }

  static Build() {
    return container.resolve(ProtocolService)
  }
}

export const protocolService = ProtocolService.Build()
