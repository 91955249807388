import { container, inject, injectable } from 'tsyringe'
import type { JsonHttpClient, UrlBuilder, HttpError } from '@deep6ai/common'
import { FetchHttpError } from '@deep6ai/common'

import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'

export interface DashboardSummary {
  id: string
  name: string
}

export interface DashboardList {
  body: DashboardSummary[]
}

export interface GenerateEmbedUrlResponse {
  body: string
}

@injectable()
export class StudyReportingService {
  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private apiClient: JsonHttpClient,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder
  ) {}

  private readonly basePath = '/v1/reporting'

  getViews = async (
    isReportingEnabled = false
  ): Promise<DashboardList | undefined> => {
    if (!isReportingEnabled) {
      throw new FetchHttpError({
        status: 403,
        message: 'Forbidden'
      } as HttpError)
    }

    return await this.apiClient.getJson(
      this.urlBuilder.joinPath(this.basePath, 'fetch-dashboards')
    )
  }

  getDashboardUrl = async (
    dashboardId: string,
    isReportingEnabled = false
  ): Promise<GenerateEmbedUrlResponse | undefined> => {
    if (!isReportingEnabled) {
      throw new FetchHttpError({
        status: 403,
        message: 'Forbidden'
      } as HttpError)
    }

    return await this.apiClient.getJson(
      this.urlBuilder.joinPath(this.basePath, 'fetch-embed', dashboardId)
    )
  }

  static Build() {
    return container.resolve(StudyReportingService)
  }
}

export const studyReportingService = StudyReportingService.Build()
