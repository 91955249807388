import { AbstractAction } from '../abstract-action'
import { StudyActionTypes } from './study.actions'
import {
  emptyPaginatedContent,
  PaginatedResponse
} from '../../lib/http/paginated-response'
import {
  Study,
  StudySummary,
  StudyRunCounts,
  StudyShare
} from '../../services/study/study.service'

interface StudyReducerSlice {
  studySummaries: PaginatedResponse<StudySummary[]>
  studyTypes: string[]
  recruitmentStatusValues: string[]
  phaseValues: string[]
  activeStudy: Study | null
  activeStudyShares: StudyShare[]
  activeProtocolUuid: string | null
  latestProtocolUuid: string | null
  activeStudyHealthSystemCount: StudyRunCounts | null
}

export const initialState: StudyReducerSlice = {
  studySummaries: emptyPaginatedContent(),
  studyTypes: [],
  recruitmentStatusValues: [],
  phaseValues: [],
  activeStudy: null,
  activeStudyShares: [],
  activeProtocolUuid: null,
  latestProtocolUuid: null,
  activeStudyHealthSystemCount: null
}

export default function studyReducer(
  state: StudyReducerSlice = initialState,
  action: AbstractAction<StudyActionTypes>
) {
  switch (action.type) {
    case StudyActionTypes.addStudies:
      return {
        ...state,
        studySummaries: action.payload
      }
    case StudyActionTypes.removeStudy:
      if (
        !studyExistsInStore(
          action.payload as string,
          state.studySummaries.content
        )
      )
        return state

      return {
        ...state,
        studySummaries: {
          ...state.studySummaries,
          content: state.studySummaries.content.filter(
            (s) => s.studyUuid !== action.payload
          ),
          totalElements: state.studySummaries.totalElements - 1
        }
      }
    case StudyActionTypes.addStudyTypes:
      return {
        ...state,
        studyTypes: action.payload
      }
    case StudyActionTypes.addRecruitmentStatusValues:
      return {
        ...state,
        recruitmentStatusValues: action.payload
      }
    case StudyActionTypes.addPhaseValues:
      return {
        ...state,
        phaseValues: action.payload
      }
    case StudyActionTypes.clearPhaseValues:
      return {
        ...state,
        phaseValues: initialState.phaseValues
      }
    case StudyActionTypes.addActiveProtocolUuid:
      return {
        ...state,
        activeProtocolUuid: action.payload
      }
    case StudyActionTypes.addLatestProtocolUuid:
      return {
        ...state,
        latestProtocolUuid: action.payload
      }
    case StudyActionTypes.addActiveStudy:
      return {
        ...state,
        activeStudy: action.payload
      }
    case StudyActionTypes.clearActiveStudy:
      return {
        ...state,
        activeStudy: null
      }
    case StudyActionTypes.addActiveStudyShares:
      return {
        ...state,
        activeStudyShares: action.payload
      }
    case StudyActionTypes.clearActiveStudyShares:
      return {
        ...state,
        activeStudyShares: []
      }
    case StudyActionTypes.addActiveStudyHealthSystemCount:
      return {
        ...state,
        activeStudyHealthSystemCount: action.payload
      }
    case StudyActionTypes.clearActiveStudyHealthSystemCount:
      return {
        ...state,
        activeStudyHealthSystemCount: null
      }
    default:
      return state
  }
}

const studyExistsInStore = (uuid: string, studySummaries: StudySummary[]) =>
  studySummaries.find((s) => s.studyUuid === uuid)
