import { injectable } from 'tsyringe'

import {
  BucketMapper,
  ReducerCB,
  MissingMetadataException
} from './bucket-mapper'
import {
  AllowedEnrolledRecruitmentStatuses,
  EnrolledMetadata,
  Recruitment,
  RecruitmentBucketKey
} from '../Recruitment'
import { IncomingRecruitmentBucketItemMetadata } from './recruitment-bucket-mapper'

@injectable()
export class EnrolledMapper implements BucketMapper<EnrolledMetadata> {
  buildMapper(): ReducerCB<EnrolledMetadata> {
    return (aggregate, current) => {
      return this.bucketMapper(aggregate, current, this.transform)
    }
  }

  transform = (
    metadata: IncomingRecruitmentBucketItemMetadata
  ): EnrolledMetadata => {
    if (!metadata.statusMetadata.enrolledMetadata?.timestamp) {
      throw new MissingMetadataException(RecruitmentBucketKey.ENROLLED)
    }

    return {
      currentStatus:
        metadata.currentStatus as AllowedEnrolledRecruitmentStatuses,
      statusMetadata: {
        enrolledAt: metadata.statusMetadata.enrolledMetadata.timestamp
      }
    }
  }

  private bucketMapper = (
    buckets: Recruitment<EnrolledMetadata>[],
    incomingItem: Recruitment<IncomingRecruitmentBucketItemMetadata>,
    metadataTransform: (
      metadata: IncomingRecruitmentBucketItemMetadata
    ) => EnrolledMetadata
  ): Recruitment<EnrolledMetadata>[] => {
    return [
      ...buckets,
      {
        ...incomingItem,
        metadata: metadataTransform(incomingItem.metadata)
      }
    ]
  }
}
