import React, { PropsWithChildren } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { ActiveUserCanEditTermList } from '../Authorization/ActiveUserCanEditTermList'

export const _CanEditTermListGuard = ({
  termList,
  children
}: PropsWithChildren<PropsFromRedux>) => {
  return (
    <ActiveUserCanEditTermList
      termList={termList}
      otherwise={<Redirect to={`/lists/${termList.termListUuid}`} />}
    >
      {children}
    </ActiveUserCanEditTermList>
  )
}

const mapStateToProps = ({ termList }) => ({
  termList: termList.activeTermList
})

const connector = connect(mapStateToProps)

type PropsFromRedux = ConnectedProps<typeof connector>

export const CanEditTermListGuard = connector(_CanEditTermListGuard)
