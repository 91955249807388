import { AbstractAction } from '../abstract-action'
import { AuthenticationActionTypes } from './authentication.actions'
import { User } from '../../services/authentication/authentication-service'

export interface AuthenticationReducerSlice {
  activeUser: User | null
}

export const initialState: AuthenticationReducerSlice = {
  activeUser: null
}

export default function authenticationReducer(
  state = initialState,
  action: AbstractAction<AuthenticationActionTypes>
) {
  switch (action.type) {
    case AuthenticationActionTypes.setActiveUser:
      return {
        ...state,
        activeUser: action.payload
      }
    case AuthenticationActionTypes.clearActiveUser:
      return {
        ...state,
        activeUser: null
      }
    default:
      return state
  }
}
