import { container, inject, injectable } from 'tsyringe'
import { JsonHttpClient, StorageClient, UrlBuilder } from '@deep6ai/common'

import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import * as InMemoryStorageClientIoc from '../../lib/storage/InMemoryStorageClient.ioc'

export interface PPPConfig {
  recordExplorerUri: string
  patientTimelineUri: string
  queryBuilderUri: string
  trialRecommenderUri: string
  termListBuilderUri: string
  loginUri: string
  launchDarkly: {
    clientId: string
  }
  datadog: {
    applicationId: string
    clientToken: string
    environment: string
  }
}

@injectable()
export class ConfigurationService {
  private readonly basePath: string = '/config'

  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private centralApi: JsonHttpClient,
    @inject(UrlBuilderIoc.injectionToken)
    private urlBuilder: UrlBuilder,
    @inject(InMemoryStorageClientIoc.injectionToken)
    private storageClient: StorageClient
  ) {}

  getConfig = async (): Promise<PPPConfig> => {
    const config = await this.centralApi.getJson(this.formatPath('ppp'))
    Object.keys(config).forEach((k) => {
      this.storageClient.set(k, config[k])
    })
    return config
  }

  private formatPath(p = ''): string {
    return this.urlBuilder.joinPath(this.basePath, p)
  }

  static Build() {
    return container.resolve(ConfigurationService)
  }
}

export const configurationService = ConfigurationService.Build()
