import React, { MouseEvent, ReactNode, useRef } from 'react'
import { If, ButtonOutlineDefault } from '@deep6ai/component-library'

import { ToolTip } from '../ToolTip/ToolTip'
import { UploadIcon } from '../Icon/Icon'
import { errorMessages } from './StudyForm/errorMessages'

export enum FileTypes {
  CSV = 'CSV',
  PDF = 'PDF'
}

const fileTypesToMimeTypesMap = {
  [FileTypes.CSV]: 'text/csv',
  [FileTypes.PDF]: 'application/pdf'
}

const mimeTypesToFileTypesMap = {
  'text/csv': FileTypes.CSV,
  // Needed to account for how CSV MIME types are exposed to Chrome in Windows
  // environments that have Excel installed.
  // See: https://christianwood.net/posts/csv-file-upload-validation/
  'application/vnd.ms-excel': FileTypes.CSV,

  'application/pdf': FileTypes.PDF
}

// DetailedHTMLProps includes the necessary file input props
type NativeFileInputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'onChange' | 'accept' | 'onError'
>

export interface FilePickerProps extends NativeFileInputProps {
  onChange: (files: File[]) => void
  accept?: FileTypes[]
  sizeLimitInBytes?: number
  onError?: (e: FilePickerError) => void
  children?: ReactNode
  tooltipText?: string
}

export enum FilePickerErrorType {
  UNACCEPTED_TYPE,
  FILE_SIZE_TOO_LARGE
}

export interface FilePickerError {
  type: FilePickerErrorType
  message: string
}

export const FilePicker = ({
  id = 'FilePicker',
  className,
  onChange,
  accept,
  sizeLimitInBytes,
  onError,
  children = <UploadIcon />,
  disabled,
  tooltipText,
  ...rest
}: FilePickerProps) => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null)

  function handleClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault()

    if (hiddenFileInput.current) {
      hiddenFileInput.current.click()
    }
  }

  function fileTypeIsValid(file: File): boolean {
    if (!accept || !accept.length) return true
    return accept.includes(FileTypes[mimeTypesToFileTypesMap[file.type]])
  }

  function fileSizeIsValid(file: File): boolean {
    if (!sizeLimitInBytes) return true
    return file.size <= sizeLimitInBytes
  }

  function handleInvalidFileType() {
    if (!accept || !accept.length) return
    const acceptedFileTypes = Object.values(accept)
    onError?.({
      type: FilePickerErrorType.UNACCEPTED_TYPE,
      message: errorMessages.file.unacceptedType(acceptedFileTypes)
    })
  }

  function handleInvalidFileSize() {
    if (!sizeLimitInBytes) return
    onError?.({
      type: FilePickerErrorType.FILE_SIZE_TOO_LARGE,
      message: errorMessages.file.sizeTooLarge(sizeLimitInBytes)
    })
  }

  function resetFilePicker(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.files = null
    e.target.value = ''
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return

    const fileList: FileList = e.target.files
    const files = Array.from(fileList)
    if (files.some((f) => !fileTypeIsValid(f))) return handleInvalidFileType()
    if (files.some((f) => !fileSizeIsValid(f))) return handleInvalidFileSize()
    onChange(files)
    resetFilePicker(e)
  }

  const buttonProps = {}

  if (tooltipText) {
    Object.assign(buttonProps, {
      'data-tip': true,
      'data-for': 'tip-FilePicker-Button'
    })
  }

  return (
    <>
      <ButtonOutlineDefault
        id={`${id}-Button`}
        className={className}
        disabled={disabled}
        onClick={handleClick}
        {...buttonProps}
      >
        {children}
      </ButtonOutlineDefault>

      <If condition={Boolean(tooltipText)}>
        <ToolTip id="tip-FilePicker-Button">{tooltipText}</ToolTip>
      </If>

      <input
        id={id}
        name={id}
        className="hidden"
        accept={accept?.map((a) => fileTypesToMimeTypesMap[a]).join(',')}
        data-testid={`${id}-Input`}
        ref={hiddenFileInput}
        onChange={handleChange}
        type="file"
        disabled={disabled}
        {...rest}
      />
    </>
  )
}
