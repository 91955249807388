import React from 'react'
import { toast } from 'react-toastify'
import window from 'global/window'

import { useMountEffect } from '../../hooks/useMountEffect'

export const BrowserDeprecationWarning = () => {
  useMountEffect(() => {
    // window.document.documentMode is a property that is only defined in
    // Internet Explorer.
    // @see https://www.w3schools.com/jsref/prop_doc_documentmode.asp
    if ('documentMode' in window.document) {
      toast(
        'You are using an unsupported browser which impacts security and performance of the application. Please reach out to your administrator on which supported browsers are available at your institution.',
        {
          type: toast.TYPE.INFO,
          autoClose: false
        }
      )
    }
  })

  return <></>
}
export default BrowserDeprecationWarning
