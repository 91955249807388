import { container, inject, injectable } from 'tsyringe'
import { Logger, JsonHttpClient, HttpClient, UrlBuilder } from '@deep6ai/common'

import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as LoggerIoc from '../../lib/logging/Logger.ioc'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import { User } from '../authentication/authentication-service'

export interface DepartmentType {
  departmentUuid: string
  displayName: string
}
export interface Organization {
  uuid: string
  displayName: string
  type: OrganizationType
  slug: string
  healthSystem?: HealthSystem
}

export interface OrganizationDisplayName {
  organizationUuid: string
  displayName: string
}

export interface HealthSystem {
  healthSystemUuid: string
  city: string
  state: string
}

export enum OrganizationType {
  PHARMACEUTICAL = 'PHARMACEUTICAL',
  HEALTH_SYSTEM = 'HEALTH_SYSTEM'
}

@injectable()
export class OrganizationService {
  private basePath = '/organization'

  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private centralApi: JsonHttpClient & HttpClient,
    @inject(LoggerIoc.injectionToken) private logger: Logger,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder
  ) {}

  async findAllHealthSystemOrganizations(): Promise<Organization[]> {
    return await this.centralApi.getJson(this.basePath)
  }

  async fetchDepartments(healthSystemUuid: string): Promise<DepartmentType[]> {
    const url = this.urlBuilder.build(
      this.formatPath(`/${healthSystemUuid}/departments`)
    )
    return await this.centralApi.getJson(url)
  }

  private formatPath(p = ''): string {
    return this.urlBuilder.joinPath(this.basePath, p)
  }

  static isHealthSystemUser = (user: User) =>
    user.organization.type === OrganizationType.HEALTH_SYSTEM

  static isSponsorUser = (user: User) =>
    user.organization.type === OrganizationType.PHARMACEUTICAL

  static build() {
    return container.resolve(OrganizationService)
  }
}

export const organizationService = OrganizationService.build()
