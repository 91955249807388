import { injectable } from 'tsyringe'

import { PatientRecruitmentStatus, Recruitment } from './Recruitment'
import { IncomingRecruitmentBucketItemMetadata } from './mappers/recruitment-bucket-mapper'
import { AccountDisplayName, AccountService } from '../account/account-service'

@injectable()
export class PiSelectionService {
  constructor(private accountService: AccountService) {}

  getAccounts = async (
    unbucketed: Recruitment<IncomingRecruitmentBucketItemMetadata>[]
  ): Promise<AccountDisplayName[]> => {
    const accountUuids = unbucketed
      .filter(this.hasCorrectRecruitmentStatus)
      .map(this.getPIUserUuids)
      .filter(this.filterNulls) as string[] // TS is being dumb and not understanding we are filtering out the nulls here

    const { content } = await this.accountService.findAllAccountsByUuid(
      accountUuids,
      { page: 0, size: 100 }
    )

    return content
  }

  private filterNulls = (accountUuidOrNull: string | null) =>
    !!accountUuidOrNull

  private getPIUserUuids = (
    bucket: Recruitment<IncomingRecruitmentBucketItemMetadata>
  ): string | null => {
    const principleInvestigator =
      bucket.metadata.statusMetadata.consultScheduledMetadata
        ?.principleInvestigator

    if (
      !principleInvestigator ||
      principleInvestigator?.['@type'] === 'accountNotFound'
    ) {
      return null
    }

    return principleInvestigator.userUuid
  }

  private hasCorrectRecruitmentStatus = (
    bucket: Recruitment<IncomingRecruitmentBucketItemMetadata>
  ) =>
    bucket.metadata.currentStatus ===
      PatientRecruitmentStatus.CONSULTATION_SCHEDULED ||
    bucket.metadata.currentStatus ===
      PatientRecruitmentStatus.AWAITING_SCREENING
}
