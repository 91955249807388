import React, { Suspense, lazy } from 'react'
import { Provider } from 'react-redux'
import { Helmet } from 'react-helmet'
import { IconContext } from 'react-icons'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { If, Deep6ThemeProvider } from '@deep6ai/component-library'

import { isProd, isDev } from './config/env'
import store from './store/store'
import { ToastContainer } from './components/Toast/ToastContainer'
import { Bootstrap } from './components/GlobalStateCheckers/Bootstrap/Bootstrap'
import { Heap } from './components/Scripts/Heap/Heap'
import { DataDogRUM } from './components/Scripts/DataDog/DataDogRUM'
import { Userpilot } from './components/Scripts/Userpilot/Userpilot'
import { LogoutTimer } from './components/LogoutTimer/LogoutTimer'
import { iconContextValue } from './config/icons'
import { RedirectManager } from './components/GlobalStateCheckers/RedirectManager/RedirectManager'
import { StudyContextWatcher } from './components/GlobalStateCheckers/StudyContextWatcher/StudyContextWatcher'
import { ApplicationContainer } from './components/Layout/ApplicationContainer'
import { Navbar } from './components/Navbar/Navbar'
import { LoadingOverlay } from './components/Loading/LoadingOverlay'
import { DefaultErrorBoundary } from './components/Error/DefaultErrorBoundary'
import { CanEditTermListGuard } from './components/GlobalStateCheckers/Guard/CanEditTermListGuard'
import { BrowserDeprecationWarning } from './components/BrowserDeprecationWarning/BrowserDeprecationWarning'
import { ErrorPage } from './pages/Error/ErrorPage'
import { FourOhFour } from './pages/404/404'
import { TrialRecommenderContextWatcher } from './components/GlobalStateCheckers/TrialRecommenderContextWatcher/TrialRecommenderContextWatcher'
import DashboardRouteResolver from './pages/Dashboards/DashboardRouteResolver'
import TrialRecommenderApplication from './pages/TrialRecommender/TrialRecommenderApplication'
import { SessionTimeoutModalTimer } from './components/SessionTimeoutModalTimer/SessionTimeoutModalTimer'

// use prettier ignore range when this issue is resolved: https://github.com/prettier/prettier/issues/5287
const StudyDashboardRouteResolver = lazy(() => import('./pages/StudyDashboard/StudyDashboardRouteResolver')) // prettier-ignore
const StudyDashboard = lazy(() => import('./pages/StudyDashboard/StudyDashboard')) // prettier-ignore
const EditCriteriaRouteResolver = lazy(() => import('./pages/EditCriteria/EditCriteriaRouteResolver')) // prettier-ignore
const EditCriteria = lazy(() => import('./pages/EditCriteria/EditCriteria')) // prettier-ignore
const SponsorUserBuildQueryRouteResolver = lazy(() => import('./pages/BuildQuery/SponsorUser/SponsorUserBuildQueryRouteResolver')) // prettier-ignore
const SponsorUserBuildQuery = lazy(() => import('./pages/BuildQuery/SponsorUser/SponsorUserBuildQuery')) // prettier-ignore
const HealthSystemUserBuildQueryRouteResolver = lazy(() => import('./pages/BuildQuery/HealthSystemUser/HealthSystemUserBuildQueryRouteResolver')) // prettier-ignore
const HealthSystemUserBuildQuery = lazy(() => import('./pages/BuildQuery/HealthSystemUser/HealthSystemUserBuildQuery')) // prettier-ignore
const SiteCountsRouteResolver = lazy(() => import('./pages/SiteCounts/SiteCountsRouteResolver')) // prettier-ignore
const SiteCounts = lazy(() => import('./pages/SiteCounts/SiteCounts')) // prettier-ignore
const PatientListRouteResolver = lazy(() => import('./pages/PatientList/PatientListRouteResolver')) // prettier-ignore
const PatientList = lazy(() => import('./pages/PatientList/PatientList')) // prettier-ignore
const PatientTimelineRouteResolver = lazy(() => import('./pages/PatientTimeline/PatientTimelineRouteResolver')) // prettier-ignore
const PatientTimeline = lazy(() => import('./pages/PatientTimeline/PatientTimeline')) // prettier-ignore
const RecruiterRouteResolver = lazy(() => import('./pages/Recruiter/RecruiterRouteResolver')) // prettier-ignore
const Recruiter = lazy(() => import('./pages/Recruiter/Recruiter')) // prettier-ignore
const TermListsRouteResolver = lazy(() => import('./pages/TermLists/TermListsRouteResolver')) // prettier-ignore
const TermLists = lazy(() => import('./pages/TermLists/TermLists')) // prettier-ignore
const BuildAndApplyTermListRouteResolver = lazy(() => import('./pages/BuildAndApplyTermList/BuildAndApplyTermListRouteResolver')) // prettier-ignore
const BuildAndApplyTermList = lazy(() => import('./pages/BuildAndApplyTermList/BuildAndApplyTermList')) // prettier-ignore
const TermListDetailsRouteResolver = lazy(() => import('./pages/TermListDetails/TermListDetailsRouteResolver')) // prettier-ignore
const TermListDetailsPage = lazy(() => import('./pages/TermListDetails/TermListDetailsPage')) // prettier-ignore
const BuildTermListRouteResolver = lazy(() => import('./pages/BuildTermList/BuildTermListRouteResolver')) // prettier-ignore
const BuildTermList = lazy(() => import('./pages/BuildTermList/BuildTermList')) // prettier-ignore
const Dashboard = lazy(() => import('./pages/Dashboards/Dashboard')) // prettier-ignore
const MapRouteResolver = lazy(() => import('./pages/Map/MapRouteResolver')) // prettier-ignore
const Map = lazy(() => import('./pages/Map/Map')) // prettier-ignore

const App = () => {
  return (
    <Deep6ThemeProvider>
      <Provider store={store}>
        <Helmet defaultTitle="Deep 6 AI" titleTemplate="Deep 6 AI | %s" />
        <ToastContainer />
        <BrowserDeprecationWarning />
        <Bootstrap>
          <BrowserRouter>
            <If condition={isProd}>
              <DataDogRUM />
              <Heap apiKey="1823509920" />
              <Userpilot apiKey="NX-6ac03444" />
            </If>
            <If condition={isDev}>
              <Heap apiKey="1618970907" />
            </If>
            <LogoutTimer />
            <SessionTimeoutModalTimer />
            <IconContext.Provider value={iconContextValue}>
              <RedirectManager />
              <StudyContextWatcher />
              <TrialRecommenderContextWatcher />
              <ApplicationContainer>
                <Navbar className="application-nav-bar" />
                <Suspense fallback={<LoadingOverlay />}>
                  <Switch>
                    <Redirect exact from="/" to="/studies" />

                    <Route exact path={'/studies'}>
                      <DefaultErrorBoundary>
                        <StudyDashboardRouteResolver
                          component={StudyDashboard}
                        />
                      </DefaultErrorBoundary>
                    </Route>

                    <Route path={'/studies/:studyUuid/edit-criteria'}>
                      <DefaultErrorBoundary>
                        <EditCriteriaRouteResolver component={EditCriteria} />
                      </DefaultErrorBoundary>
                    </Route>

                    {/**
                     * Cohort Builder routes
                     */}

                    <Route
                      path={[
                        '/studies/:studyUuid/build-query',
                        `/studies/:studyUuid/protocol/:protocolUuid/build-query`
                      ]}
                    >
                      <DefaultErrorBoundary>
                        <SponsorUserBuildQueryRouteResolver
                          component={SponsorUserBuildQuery}
                        />
                      </DefaultErrorBoundary>
                    </Route>

                    <Route
                      path={[
                        `/studies/:studyUuid/healthSystem/:healthSystemUuid/build-query`,
                        `/studies/:studyUuid/healthSystem/:healthSystemUuid/protocol/:protocolUuid/build-query`
                      ]}
                    >
                      <DefaultErrorBoundary>
                        <HealthSystemUserBuildQueryRouteResolver
                          component={HealthSystemUserBuildQuery}
                        />
                      </DefaultErrorBoundary>
                    </Route>

                    <Route
                      path={[
                        '/studies/:studyUuid/site-counts',
                        '/studies/:studyUuid/run/:runUuid/site-counts'
                      ]}
                    >
                      <DefaultErrorBoundary>
                        <SiteCountsRouteResolver component={SiteCounts} />
                      </DefaultErrorBoundary>
                    </Route>

                    <Route
                      path={[
                        '/studies/:studyUuid/healthSystem/:healthSystemUuid/patient-list',
                        '/studies/:studyUuid/run/:runUuid/healthSystem/:healthSystemUuid/patient-list'
                      ]}
                    >
                      <DefaultErrorBoundary>
                        <PatientListRouteResolver component={PatientList} />
                      </DefaultErrorBoundary>
                    </Route>

                    <Route
                      path={[
                        '/studies/:studyUuid/healthSystem/:healthSystemUuid/patient/:patientUuid/explore',
                        '/studies/:studyUuid/run/:runUuid/healthSystem/:healthSystemUuid/patient/:patientUuid/explore'
                      ]}
                    >
                      <DefaultErrorBoundary>
                        <PatientTimelineRouteResolver
                          component={PatientTimeline}
                        />
                      </DefaultErrorBoundary>
                    </Route>

                    {/**
                     * Recruiter routes
                     */}

                    <Route
                      path={
                        '/studies/:studyUuid/healthSystem/:healthSystemUuid/recruit'
                      }
                    >
                      <DefaultErrorBoundary>
                        <RecruiterRouteResolver component={Recruiter} />
                      </DefaultErrorBoundary>
                    </Route>

                    {/**
                     * Term List routes
                     */}

                    <Route exact path={'/lists'}>
                      <DefaultErrorBoundary>
                        <TermListsRouteResolver component={TermLists} />
                      </DefaultErrorBoundary>
                    </Route>

                    <Route
                      path={[
                        '/studies/:studyUuid/apply/:termListUuid/build-list',
                        '/studies/:studyUuid/healthSystem/:healthSystemUuid/apply/:termListUuid/build-list'
                      ]}
                    >
                      <DefaultErrorBoundary>
                        <BuildAndApplyTermListRouteResolver
                          component={BuildAndApplyTermList}
                        />
                      </DefaultErrorBoundary>
                    </Route>

                    <Route exact path={'/lists/:termListUuid'}>
                      <DefaultErrorBoundary>
                        <TermListDetailsRouteResolver
                          component={TermListDetailsPage}
                        />
                      </DefaultErrorBoundary>
                    </Route>

                    <Route path={'/lists/:termListUuid/build-list'}>
                      <DefaultErrorBoundary>
                        <BuildTermListRouteResolver>
                          <CanEditTermListGuard>
                            <BuildTermList />
                          </CanEditTermListGuard>
                        </BuildTermListRouteResolver>
                      </DefaultErrorBoundary>
                    </Route>

                    {/**
                     * Trial Recommender routes
                     */}

                    <Route path={'/trial-recommender'}>
                      <DefaultErrorBoundary>
                        <TrialRecommenderApplication resolvedProps={{}} />
                      </DefaultErrorBoundary>
                    </Route>

                    {/**
                     * Dashboard routes
                     */}

                    <Route exact path={'/dashboards/:dashboardId'}>
                      <DefaultErrorBoundary>
                        <DashboardRouteResolver component={Dashboard} />
                      </DefaultErrorBoundary>
                    </Route>

                    {/**
                     * Map routes
                     */}

                    <Route exact path={'/🌎'}>
                      <DefaultErrorBoundary>
                        <MapRouteResolver component={Map} />
                      </DefaultErrorBoundary>
                    </Route>

                    {/**
                     * Utility routes
                     */}

                    <Route path={'/error'}>
                      <ErrorPage />
                    </Route>

                    <Route path={'*'}>
                      <FourOhFour />
                    </Route>
                  </Switch>
                </Suspense>
              </ApplicationContainer>
            </IconContext.Provider>
          </BrowserRouter>
        </Bootstrap>
      </Provider>
    </Deep6ThemeProvider>
  )
}

export default App
