import React from 'react'
import classNames from 'classnames'

export const FormGroup = ({
  id = 'FormGroup',
  children,
  className
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames('mb-3', className)} data-testid={id}>
    {children}
  </div>
)
