/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, EffectCallback } from 'react'

/**
 * Accepts a function that contains imperative, possibly effectful code,
 * that will run only on component mount.
 *
 * @param effect Callback function that gets passed directly to useEffect.
 *
 * @see https://reactjs.org/docs/hooks-reference.html#useeffect
 */
export const useMountEffect = (effect: EffectCallback) => useEffect(effect, [])
