import { container, inject, injectable } from 'tsyringe'

import { ReduxAsyncNotifier } from '../../lib/async-notifier/redux-async-notifier'
import type { AsyncNotifier } from '../../lib/async-notifier/async-notifier'
import { AbstractAction } from '../abstract-action'
import { RecruitmentBuckets } from '../../services/recruitment/Recruitment'
import { RecruitmentService } from '../../services/recruitment/recruitment-service'

export enum RecruitmentActionTypes {
  addActiveRecruitmentBuckets = 'RECRUITMENT::ADD_ACTIVE_RECRUITMENT_BUCKETS',
  clearActiveRecruitmentBuckets = 'RECRUITMENT::CLEAR_ACTIVE_RECRUITMENT_BUCKETS'
}

@injectable()
export class RecruitmentActions {
  constructor(
    private recruitmentService: RecruitmentService,
    @inject(ReduxAsyncNotifier.injectionToken)
    private asyncNotifier: AsyncNotifier
  ) {}

  addActiveRecruitmentBuckets = (
    recruitmentBuckets: RecruitmentBuckets
  ): AbstractAction<RecruitmentActionTypes, RecruitmentBuckets> => {
    return {
      type: RecruitmentActionTypes.addActiveRecruitmentBuckets,
      payload: recruitmentBuckets
    }
  }

  clearActiveRecruitmentBuckets =
    (): AbstractAction<RecruitmentActionTypes> => {
      return {
        type: RecruitmentActionTypes.clearActiveRecruitmentBuckets
      }
    }

  getRecruitingPatients = this.asyncNotifier.subscribe(
    (studyUuid: string, healthSystemUuid: string) => async (dispatch) => {
      const recruitmentBuckets =
        await this.recruitmentService.getRecruitingPatients(
          studyUuid,
          healthSystemUuid
        )
      dispatch(this.addActiveRecruitmentBuckets(recruitmentBuckets))
    }
  )

  static Build() {
    return container.resolve(RecruitmentActions)
  }
}

export const recruitmentActions = RecruitmentActions.Build()
