import React from 'react'
import { Link } from 'react-router-dom'

import { ErrorPage } from '../Error/ErrorPage'

export const FourOhFour = () => {
  const errorMessage = (
    <>
      Route not found.{' '}
      <Link to={'/'} className="text-blue underline">
        Return to the Studies page.
      </Link>
    </>
  )

  return <ErrorPage message={errorMessage} />
}
