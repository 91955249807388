import { Link } from 'react-router-dom'
import React from 'react'
import { If } from '@deep6ai/component-library'
import cx from 'classnames'

import { useBreadcrumb } from '../../hooks/useBreadcrumb/useBreadcrumb'
import { ChevronLeft } from '../Icon/Icon'

type NavBreadcrumbProps = React.HTMLAttributes<HTMLLinkElement>

export const NavBreadcrumb = ({ className }: NavBreadcrumbProps) => {
  const [breadcrumb] = useBreadcrumb()

  return (
    <If condition={!!breadcrumb.href && !!breadcrumb.label}>
      <Link
        className={cx(
          className,
          'mr-24 overflow-hidden truncate whitespace-no-wrap flex-0 text-blue max-w-screen-sm'
        )}
        to={breadcrumb.href}
      >
        <ChevronLeft className="text-xxs" /> {breadcrumb.label}
      </Link>
    </If>
  )
}
