import { container } from 'tsyringe'

export const injectionTokens = {
  loginUriFallback: 'loginUriFallback',
  phiClientPollingIntervalMs: 'phiClientPollingIntervalMs'
}

container.register(injectionTokens.loginUriFallback, {
  useValue: '/api/login'
})

container.register(injectionTokens.phiClientPollingIntervalMs, {
  useValue: 500
})
