import { PatientStatus } from '../patient-service'

/**
 * @name StudyMembershipFilters
 * @description - A collection of filters that are used for filtering a study
 * membership result set.
 * @param {StudyMembership[]} memberships - A structured hierarchy of statuses
 * applicable to patients who are members of a given study. Only certain
 * fields are applicable in certain contexts. For example, the
 * {StudyMembership.criteria} field is only valid when {StudyMembership.status}
 * is set to MembershipStatus.UNREVIEWED. Any other state will be flagged by the
 * type system.
 * @param {patientRecord} patientRecord - Allows filtering by patient name or MRN
 * and/or the contents of a patients records.
 *
 */
export interface StudyMembershipFilters {
  memberships: StudyMembership[]
  patientRecord: PatientRecordFilter
}

/**
 * @description - A convenience type containing the top level membership statuses.
 */
export type StudyMembership =
  | ReviewedMembershipStatusFilter
  | UnreviewedMembershipStatusFilter

/**
 * @description - Other filtering options not related to membership status.
 */
export interface PatientRecordFilter {
  nameOrMrns?: string[]
  contents?: string
  siteIds?: string[]
  patientStatuses?: PatientStatus[]
}

/**
 * @description - APPROVED and REJECTED don't have any criteria fitness
 * requirements since they have been vetted by the user.
 */
export interface ReviewedMembershipStatusFilter {
  criteria?: CriteriaFitness
  status:
    | MembershipStatus.YES
    | MembershipStatus.NO
    | MembershipStatus.WATCHLISTED
}

/**
 * @description - UNREVIEWED matches have an additional required field
 * delineating their respective criteria fitness. That is, the quality
 * of the match that our system found for them.
 */
export interface UnreviewedMembershipStatusFilter {
  status: MembershipStatus.UNREVIEWED
  criteria?: CriteriaFitness
}

/**
 * @deprecated - We're moving toward using ReviewStatus as it's more aligned
 * with our domain language, however this is dependent on backend changes.
 * @description - Top level patient membership status. A patient can never have
 * more than one of these.
 *
 * NOTE: these enum values _have_ to match their string value exactly
 * including case. They also need to be in shout case like this.
 */
export enum MembershipStatus {
  YES = 'YES',
  NO = 'NO',
  UNREVIEWED = 'UNREVIEWED',
  WATCHLISTED = 'WATCHLISTED'
}

export const isMembershipStatus = (val: any): val is MembershipStatus =>
  !!MembershipStatus[val]

/**
 * @description - A union type of all the CriteriaFitness varietals.
 */
type CriteriaFitness =
  | MatchesCriteriaFitness
  | PartialCriteriaFitness
  | ExcludedCriteriaFitness

/**
 * @description - Only applicable when a patient is in
 * {MembershipStatus.UNREVIEWED} status.
 */
interface MatchesCriteriaFitness {
  matchType: MatchType.MATCHES
}

/**
 * @description - Only applicable when a patient is in
 * {MembershipStatus.UNREVIEWED} and {MatchType.PARTIAL} status.
 * Allows for one or more (or none) sub statuses for futher filtering.
 */
interface PartialCriteriaFitness {
  matchType: MatchType.PARTIAL
  flags: PartialMatchFlag[]
}

/**
 * @description - Only applicable when a patient is in
 * {MembershipStatus.UNREVIEWED} status.
 */
interface ExcludedCriteriaFitness {
  matchType: MatchType.EXCLUDED
}

/**
 * @description - Sub statuses only applicable to patients in
 * {MembershipStatus.UNREVIEWED} status.
 *
 * NOTE: These enum values _have_ to match their string value exactly
 * including case. They also need to be in shout case like this.
 */
export enum MatchType {
  MATCHES = 'MATCHES',
  PARTIAL = 'PARTIAL',
  EXCLUDED = 'EXCLUDED'
}

export const isMatchType = (val: any): val is MatchType => !!MatchType[val]

/**
 * @description - Sub statuses of {MatchType.PARTIAL}. One or more (or none) can
 * be selected.
 *
 * NOTE: These enum values _have_ to match their string value exactly
 * including case. They also need to be in shout case like this.
 */
export enum PartialMatchFlag {
  NEGATION = 'NEGATION',
  FAMILY_HISTORY = 'FAMILY_HISTORY',
  DATA_MISSING = 'DATA_MISSING'
}
