import { Provider } from '../provider/ProviderService'
import { Patient } from '../patient/models/Patient'

export enum PatientRecruitmentStatus {
  AWAITING_PHYSICIAN_SELECTION = 'AWAITING_PHYSICIAN_SELECTION',
  AWAITING_REFERRAL = 'AWAITING_REFERRAL',
  AWAITING_SCHEDULING_OF_CONSULTATION = 'AWAITING_SCHEDULING_OF_CONSULTATION',
  CONSULTATION_SCHEDULED = 'CONSULTATION_SCHEDULED',
  AWAITING_SCREENING = 'AWAITING_SCREENING',
  ENROLLED = 'ENROLLED',
  INELIGIBLE_NOT_INTERESTED = 'INELIGIBLE_NOT_INTERESTED',
  INELIGIBLE_FAILED_SCREENING = 'INELIGIBLE_FAILED_SCREENING',
  INELIGIBLE_CONSENT_DENIED = 'INELIGIBLE_CONSENT_DENIED',
  INELIGIBLE_REFERRAL_DENIED = 'INELIGIBLE_REFERRAL_DENIED',
  REFERRAL_DENIED = 'REFERRAL_DENIED'
}

export enum ReferralAction {
  REFER = 'REFER',
  DENY = 'DENY',
  SKIP = 'SKIP'
}

export enum ProviderSelectionType {
  FOUND,
  NOT_FOUND,
  SKIPPED
}

export enum PISelectionType {
  NOT_FOUND,
  ACCOUNT
}

export type RecruitmentProvider = Pick<
  Provider,
  'firstName' | 'lastName' | 'id' | 'specialties' | 'phones' | 'email'
>

export type ProviderSelection =
  | {
      type: ProviderSelectionType.FOUND
      provider: RecruitmentProvider
    }
  | {
      type: ProviderSelectionType.NOT_FOUND
    }
  | {
      type: ProviderSelectionType.SKIPPED
    }

export type PISelection =
  | {
      type: PISelectionType.ACCOUNT
      displayName: string
      accountUuid: string
    }
  | {
      type: PISelectionType.NOT_FOUND
      displayName: string
    }

export type AllowedValidatedRecruitmentStatuses =
  | PatientRecruitmentStatus.AWAITING_PHYSICIAN_SELECTION
  | PatientRecruitmentStatus.REFERRAL_DENIED
  | PatientRecruitmentStatus.AWAITING_REFERRAL

export type AllowedReferralRecruitmentStatuses =
  PatientRecruitmentStatus.AWAITING_SCHEDULING_OF_CONSULTATION

export type AllowedConsultScheduledRecruitmentStatuses =
  PatientRecruitmentStatus.CONSULTATION_SCHEDULED

export type AllowedInScreeningRecruitmentStatuses =
  PatientRecruitmentStatus.AWAITING_SCREENING

export type AllowedEnrolledRecruitmentStatuses =
  PatientRecruitmentStatus.ENROLLED

export type AllowedIneligibleRecruitmentStatuses =
  | PatientRecruitmentStatus.INELIGIBLE_REFERRAL_DENIED
  | PatientRecruitmentStatus.INELIGIBLE_CONSENT_DENIED
  | PatientRecruitmentStatus.INELIGIBLE_NOT_INTERESTED
  | PatientRecruitmentStatus.INELIGIBLE_FAILED_SCREENING

export interface ValidatedMetadata {
  currentStatus: AllowedValidatedRecruitmentStatuses
  statusMetadata: {
    validatedAt: string | null
    providerSelection: ProviderSelection | null
  }
}

export interface ReferralMetadata {
  currentStatus: AllowedReferralRecruitmentStatuses
  statusMetadata: {
    action: ReferralAction
    referredAt: string
    validationMetadata: {
      validatedAt: string | null
      providerSelection: ProviderSelection | null
    }
  }
}

export interface ConsultScheduledMetadata {
  currentStatus: AllowedConsultScheduledRecruitmentStatuses
  statusMetadata: {
    scheduledAt?: string
    principleInvestigator?: PISelection
    validationMetadata: {
      validatedAt: string | null
      providerSelection: ProviderSelection | null
    }
    referralMetadata: {
      action: ReferralAction
      referredAt: string
    }
  }
}

export interface InScreeningMetadata {
  currentStatus: AllowedInScreeningRecruitmentStatuses
  statusMetadata: {
    updatedAt: string
    validationMetadata: {
      validatedAt: string | null
      providerSelection: ProviderSelection | null
    }
    referralMetadata: {
      action: ReferralAction
      referredAt: string
    }
    consultScheduledMetadata: {
      scheduledAt?: string
      primaryInvestigator?: PISelection
    }
  }
}

export interface EnrolledMetadata {
  currentStatus: AllowedEnrolledRecruitmentStatuses
  statusMetadata: {
    enrolledAt: string
  }
}

export interface IneligibleMetadata {
  currentStatus: AllowedIneligibleRecruitmentStatuses
  statusMetadata: {
    ineligibleAt: string
  }
}

export type EligibleRecruitmentStatusMetadata =
  | ValidatedMetadata
  | ReferralMetadata
  | ConsultScheduledMetadata
  | InScreeningMetadata

export type RecruitingPatient = Pick<
  Patient,
  | 'firstName'
  | 'lastName'
  | 'middleName'
  | 'birthDate'
  | 'mrn'
  | 'uuid'
  | 'preferredPhone'
  | 'sex'
  | 'email'
>

export interface Recruitment<RecruitmentStatusMetadata> {
  patient: RecruitingPatient
  metadata: RecruitmentStatusMetadata
}

export enum RecruitmentBucketKey {
  VALIDATED = 'VALIDATED',
  REFERRED = 'REFERRED',
  CONSULT_SCHEDULED = 'CONSULT_SCHEDULED',
  IN_SCREENING = 'IN_SCREENING',
  ENROLLED = 'ENROLLED',
  INELIGIBLE = 'INELIGIBLE'
}

export interface RecruitmentBuckets {
  [RecruitmentBucketKey.VALIDATED]: Recruitment<ValidatedMetadata>[]
  [RecruitmentBucketKey.REFERRED]: Recruitment<ReferralMetadata>[]
  [RecruitmentBucketKey.CONSULT_SCHEDULED]: Recruitment<ConsultScheduledMetadata>[]
  [RecruitmentBucketKey.IN_SCREENING]: Recruitment<InScreeningMetadata>[]
  [RecruitmentBucketKey.ENROLLED]: Recruitment<EnrolledMetadata>[]
  [RecruitmentBucketKey.INELIGIBLE]: Recruitment<IneligibleMetadata>[]
}

const statusToBucketMapping: {
  [key in PatientRecruitmentStatus]?: RecruitmentBucketKey
} = {
  [PatientRecruitmentStatus.AWAITING_PHYSICIAN_SELECTION]:
    RecruitmentBucketKey.VALIDATED,
  [PatientRecruitmentStatus.AWAITING_REFERRAL]: RecruitmentBucketKey.VALIDATED,
  [PatientRecruitmentStatus.AWAITING_SCHEDULING_OF_CONSULTATION]:
    RecruitmentBucketKey.REFERRED,
  [PatientRecruitmentStatus.CONSULTATION_SCHEDULED]:
    RecruitmentBucketKey.CONSULT_SCHEDULED,
  [PatientRecruitmentStatus.AWAITING_SCREENING]:
    RecruitmentBucketKey.IN_SCREENING,
  [PatientRecruitmentStatus.ENROLLED]: RecruitmentBucketKey.ENROLLED,
  [PatientRecruitmentStatus.INELIGIBLE_NOT_INTERESTED]:
    RecruitmentBucketKey.INELIGIBLE,
  [PatientRecruitmentStatus.INELIGIBLE_FAILED_SCREENING]:
    RecruitmentBucketKey.INELIGIBLE,
  [PatientRecruitmentStatus.INELIGIBLE_CONSENT_DENIED]:
    RecruitmentBucketKey.INELIGIBLE,
  [PatientRecruitmentStatus.INELIGIBLE_REFERRAL_DENIED]:
    RecruitmentBucketKey.INELIGIBLE,
  [PatientRecruitmentStatus.REFERRAL_DENIED]: RecruitmentBucketKey.INELIGIBLE
}

export const getBucketForStatus = (
  status: PatientRecruitmentStatus | null
): RecruitmentBucketKey | undefined => {
  if (!status) return undefined
  return statusToBucketMapping[status]
}

export type UnbucketedPISelection =
  | {
      ['@type']: 'accountNotFound'
    }
  | {
      ['@type']: 'accountReference'
      userUuid: string
    }

export type UnbucketedValidatedMetadata = {
  timestamp: string
} | null

export type UnbucketedProviderSelectedMetadata =
  | {
      provider: {
        ['@type']: 'provider'
        id: string
        firstName: string
        lastName: string
        phones: string[]
        specialties: string[]
        email?: string
      }
      userUuid: string
      timestamp: string
    }
  | {
      provider: {
        ['@type']: 'providerNotFound'
      }
      userUuid: string
      timestamp: string
    }
  | {
      provider: {
        ['@type']: 'providerSkipped'
      }
      userUuid: string
      timestamp: string
    }
  | null

export type UnbucketedReferredMetadata = {
  action: ReferralAction
  userUuid: string
  timestamp: string
} | null

export type UnbucketedConsentedMetadata = {
  userUuid: string
  timestamp: string
} | null

export type UnbucketedConsultationScheduledMetadata = {
  consultationDate: string
  principleInvestigator: UnbucketedPISelection
  userUuid: string
  timestamp: string
} | null

export type UnbucketedInScreeningMetadata = {
  userUuid: string
  timestamp: string
} | null

export type UnbucketedEnrolledMetadata = {
  userUuid: string
  timestamp: string
} | null

export type UnbucketedIneligibleMetadata = {
  userUuid: string
  timestamp: string
} | null

export interface UnbucketedRecruitmentMetadata {
  validatedMetadata: UnbucketedValidatedMetadata
  providerSelectedMetadata: UnbucketedProviderSelectedMetadata
  referredMetadata: UnbucketedReferredMetadata
  consultScheduledMetadata: UnbucketedConsultationScheduledMetadata
  consentedMetadata: UnbucketedConsentedMetadata
  inScreeningMetadata: UnbucketedInScreeningMetadata
  enrolledMetadata: UnbucketedEnrolledMetadata
  ineligibleMetadata: UnbucketedIneligibleMetadata
}
