import React from 'react'
import { Button, ButtonOutlinePrimary } from '@deep6ai/component-library'

import { Modal } from '../Modal'

interface SessionTimeoutModalProps {
  isOpen: boolean
  onStaySignedIn: () => void
  onLogOutNow: () => void
}

export const SessionTimeoutModal = ({
  isOpen,
  onStaySignedIn,
  onLogOutNow
}: SessionTimeoutModalProps) => {
  return (
    <Modal isOpen={isOpen} sessionTimeout={true}>
      <div className="m-5">
        <div className="flex items-center justify-between p-4 border-b border-grey-15">
          <p className="font-bold text-lg">Are you still there?</p>
        </div>
        <p className="p-4">
          <div>
            {`You've been inactive for a while.`}
            <p>
              For security purposes, your session will automatically end in
              approximately 1 minute.
            </p>
          </div>
        </p>
        <div className="border-t border-grey-15">
          <div className="p-4 float-right">
            <ButtonOutlinePrimary
              onClick={onLogOutNow}
              className="mr-4 ml-auto"
            >
              Log out now
            </ButtonOutlinePrimary>
            <Button onClick={onStaySignedIn}>Stay signed in</Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}
