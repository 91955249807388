import { container, injectable } from 'tsyringe'

import {
  PatientRecruitmentStatus,
  Recruitment,
  RecruitmentBucketKey,
  RecruitmentBuckets,
  UnbucketedRecruitmentMetadata
} from '../Recruitment'
import { ConsultationScheduledMapper } from './consultation-scheduled-mapper'
import { ValidatedMapper } from './validated-mapper'
import { InvalidRecruitmentStatusException } from './bucket-mapper'
import { ReferredMapper } from './referred-mapper'
import { InScreeningMapper } from './in-screening-mapper'
import { PiSelectionService } from '../pi-selection-service'
import { EnrolledMapper } from './enrolled-mapper'
import { IneligibleMapper } from './ineligible-mapper'

export interface IncomingRecruitmentBucketItemMetadata {
  currentStatus: PatientRecruitmentStatus
  statusMetadata: UnbucketedRecruitmentMetadata
}

@injectable()
export class RecruitmentBucketMapper {
  constructor(
    private pISelectionService: PiSelectionService,
    private consultationScheduledMapper: ConsultationScheduledMapper,
    private validatedMapper: ValidatedMapper,
    private referredMapper: ReferredMapper,
    private inScreeningMapper: InScreeningMapper,
    private enrolledMapper: EnrolledMapper,
    private ineligibleMapper: IneligibleMapper
  ) {}

  bucketRecruitmentResponse = async (
    unBucketed: Recruitment<IncomingRecruitmentBucketItemMetadata>[]
  ): Promise<RecruitmentBuckets> => {
    const principleInvestigatorAccounts =
      await this.pISelectionService.getAccounts(unBucketed)

    const consultationScheduledMapper =
      this.consultationScheduledMapper.buildMapper(
        principleInvestigatorAccounts
      )

    const validatedMapper = this.validatedMapper.buildMapper()

    const referredMapper = this.referredMapper.buildMapper()

    const inScreeningMapper = this.inScreeningMapper.buildMapper(
      principleInvestigatorAccounts
    )

    const enrolledMapper = this.enrolledMapper.buildMapper()

    const ineligibleMapper = this.ineligibleMapper.buildMapper()

    return unBucketed.reduce(
      (
        aggregate: RecruitmentBuckets,
        current: Recruitment<IncomingRecruitmentBucketItemMetadata>
      ): RecruitmentBuckets => {
        switch (current.metadata.currentStatus) {
          case PatientRecruitmentStatus.REFERRAL_DENIED:
          case PatientRecruitmentStatus.AWAITING_PHYSICIAN_SELECTION:
          case PatientRecruitmentStatus.AWAITING_REFERRAL:
            return {
              ...aggregate,
              [RecruitmentBucketKey.VALIDATED]: validatedMapper(
                aggregate[RecruitmentBucketKey.VALIDATED],
                current
              )
            }

          case PatientRecruitmentStatus.AWAITING_SCHEDULING_OF_CONSULTATION:
            return {
              ...aggregate,
              [RecruitmentBucketKey.REFERRED]: referredMapper(
                aggregate[RecruitmentBucketKey.REFERRED],
                current
              )
            }

          case PatientRecruitmentStatus.CONSULTATION_SCHEDULED:
            return {
              ...aggregate,
              [RecruitmentBucketKey.CONSULT_SCHEDULED]:
                consultationScheduledMapper(
                  aggregate[RecruitmentBucketKey.CONSULT_SCHEDULED],
                  current
                )
            }

          case PatientRecruitmentStatus.AWAITING_SCREENING:
            return {
              ...aggregate,
              [RecruitmentBucketKey.IN_SCREENING]: inScreeningMapper(
                aggregate[RecruitmentBucketKey.IN_SCREENING],
                current
              )
            }

          case PatientRecruitmentStatus.ENROLLED:
            return {
              ...aggregate,
              [RecruitmentBucketKey.ENROLLED]: enrolledMapper(
                aggregate[RecruitmentBucketKey.ENROLLED],
                current
              )
            }

          case PatientRecruitmentStatus.INELIGIBLE_FAILED_SCREENING:
          case PatientRecruitmentStatus.INELIGIBLE_NOT_INTERESTED:
          case PatientRecruitmentStatus.INELIGIBLE_CONSENT_DENIED:
          case PatientRecruitmentStatus.INELIGIBLE_REFERRAL_DENIED:
            return {
              ...aggregate,
              [RecruitmentBucketKey.INELIGIBLE]: ineligibleMapper(
                aggregate[RecruitmentBucketKey.INELIGIBLE],
                current
              )
            }

          default:
            throw new InvalidRecruitmentStatusException(
              current.metadata.currentStatus
            )
        }
      },
      {
        [RecruitmentBucketKey.VALIDATED]: [],
        [RecruitmentBucketKey.REFERRED]: [],
        [RecruitmentBucketKey.CONSULT_SCHEDULED]: [],
        [RecruitmentBucketKey.IN_SCREENING]: [],
        [RecruitmentBucketKey.ENROLLED]: [],
        [RecruitmentBucketKey.INELIGIBLE]: []
      }
    )
  }

  static Build() {
    return container.resolve(RecruitmentBucketMapper)
  }
}

export const recruitmentBucketMapper = RecruitmentBucketMapper.Build()
