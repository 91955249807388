import { HttpError } from '@deep6ai/common'

import { AbstractAction } from '../abstract-action'

export enum AsyncNotifierActions {
  PendingPush = 'ASYNC_WATCHES::PENDING_PUSH',
  PendingPop = 'ASYNC_WATCHES::PENDING_POP',
  ErrorPush = 'ASYNC_WATCHES::ERROR_PUSH',
  ErrorPop = 'ASYNC_WATCHES::ERROR_POP'
}

export type AsyncNotifierError = {
  [key: string]: HttpError
}

export interface AsyncNotifierSubscriptions {
  pending: string[]
  errors: AsyncNotifierError
}

export const initialState: AsyncNotifierSubscriptions = {
  pending: [],
  errors: {}
}

export default function asyncNotifierReducer(
  state = initialState,
  action: AbstractAction<AsyncNotifierActions>
) {
  switch (action.type) {
    case AsyncNotifierActions.PendingPush:
      return {
        ...state,
        pending: [...state.pending, action.payload]
      }
    case AsyncNotifierActions.PendingPop:
      return {
        ...state,
        pending: state.pending.filter((p) => p !== action.payload)
      }
    case AsyncNotifierActions.ErrorPush:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload
        }
      }
    case AsyncNotifierActions.ErrorPop: {
      const { [action.payload as string]: _, ...remainingErrors } = state.errors

      return {
        ...state,
        errors: remainingErrors
      }
    }
    default:
      return state
  }
}
