import React from 'react'
import cx from 'classnames'

import { hasYMargin } from '../shared/classname'

export const SeparatorThinnest = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLHRElement>) => (
  <hr
    {...rest}
    className={cx(
      'border-t border-grey-20',
      {
        'my-2': !hasYMargin(className)
      },
      className
    )}
  />
)

export const SeparatorThin = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLHRElement>) => (
  <hr {...rest} className={cx('border border-grey-20', className)} />
)

export const SeparatorThick = ({
  className,
  ...rest
}: React.HTMLAttributes<HTMLHRElement>) => (
  <hr {...rest} className={cx('border-2 border-grey-20', className)} />
)
