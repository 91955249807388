import React from 'react'
import { If } from '@deep6ai/component-library'
import { FeatureFlagClient, FeatureFlagClientFactory } from '@deep6ai/common'
import { Userpilot as UserpilotSdk } from 'userpilot'

import { FeatureFlagKey } from '../../config/feature-flags'
import { QuestionMarkCircleIcon } from '../Icon/Icon'

const WORKFLOW_ID = '1682549687tVsh0657'

export function UserpilotHelp() {
  const featureFlagClient: FeatureFlagClient<FeatureFlagKey> =
    FeatureFlagClientFactory.getClient<FeatureFlagKey>()

  return (
    <If
      condition={featureFlagClient.isFlagEnabled(
        FeatureFlagKey.USERPILOT_HELP_ICON
      )}
    >
      <QuestionMarkCircleIcon
        data-testid="userpilot-help"
        className="mr-6 cursor-pointer"
        onClick={() => UserpilotSdk.trigger(WORKFLOW_ID)}
      />
    </If>
  )
}
