import React from 'react'
import { LegacyInput as Input } from '@deep6ai/component-library'

import { ConfirmButtons } from '../../Modal/ConfirmButtons'
import { FormGroup } from '../FormGroup'
import { Label } from '../Label'

export interface LinkAttachmentEditProps {
  displayName?: string
  url?: string
  onUpdate: () => void
  onCancel: () => void
  onChangeName: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const LinkAttachmentEdit = ({
  displayName,
  url,
  onUpdate,
  onCancel,
  onChangeName
}: LinkAttachmentEditProps) => {
  const handleUpdateButtonClick = () => {
    onUpdate()
  }

  const handleCancelButtonClick = () => {
    onCancel()
  }

  return (
    <div>
      <FormGroup>
        <Label htmlFor="linkName">Text to Display</Label>
        <Input
          id="linkName"
          onChange={onChangeName}
          placeholder="Update the link name"
          value={displayName}
        />
      </FormGroup>

      <div className="flex items-center justify-between truncate">
        <div className="items-center truncate">{url}</div>

        <ConfirmButtons
          confirmButtonText="Update"
          onCancel={handleCancelButtonClick}
          onConfirm={handleUpdateButtonClick}
        />
      </div>
    </div>
  )
}
