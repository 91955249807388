import { container, inject, injectable } from 'tsyringe'
import {
  HttpClient,
  JsonHttpClient,
  StorageClient,
  UrlBuilder
} from '@deep6ai/common'

import { Organization } from '../organization/organization.service'
import { AccountState } from '../account/account-service'
import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as CookieStorageClientIoc from '../../lib/storage/CookieStorageClient.ioc'
import * as InMemoryStorageClientIoc from '../../lib/storage/InMemoryStorageClient.ioc'
import { PhiAccessLevel, SessionService } from '../session/SessionService'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import { injectionTokens } from '../../config/defaults'

export interface User {
  accountUuid: string
  displayName: string
  state: AccountState
  organization: Organization
}

export interface UserClaims {
  phiAccessLevel?: PhiAccessLevel
  irbMemberships?: string
  canShare: boolean
  trialRecommenderAccess: boolean
  buildQuery: boolean
  validateData: boolean
  versionView: boolean
  auditingLogs: boolean
  downloadData: boolean
}

@injectable()
export class AuthenticationService {
  private readonly basePath: string = '/auth'

  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private centralApi: JsonHttpClient & HttpClient,
    @inject(CookieStorageClientIoc.injectionToken)
    private cookieStorageClient: StorageClient,
    @inject(InMemoryStorageClientIoc.injectionToken)
    private inMemoryStorageClient: StorageClient,
    private session: SessionService,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder,
    @inject(injectionTokens.loginUriFallback) private loginUriFallback: string
  ) {}

  getAuthenticatedUser = (): Promise<User> => {
    return this.centralApi.getJson(this.formatPath('user'))
  }

  getAuthenticatedUserClaims = (): Promise<UserClaims> => {
    return this.centralApi.getJson(this.formatPath('claims'))
  }

  /**
   * Logs out the user and redirects to the login page.
   *
   * @param type - Logout can be initiated by the 'user' or the 'system'.
   * If logged out by the system, the user's current location is stored in a
   * cookie for the backend to redirect to after the user logs in again.
   */
  logOut = async (type: 'user' | 'system' = 'system') => {
    await this.centralApi.delete(this.formatPath('logout'))

    this.session.clear()

    const loginUri =
      this.inMemoryStorageClient.get('loginUri') ?? this.loginUriFallback

    if (type === 'system') {
      const rootDomain = window.location.hostname.split('.').slice(-2).join('.')
      this.cookieStorageClient.set('redirectUri', window.location.href, {
        domain: rootDomain
      })
    } else {
      this.cookieStorageClient.remove('redirectUri')
    }

    window.location.assign(loginUri)
  }

  refresh = () => {
    return this.centralApi.get(this.formatPath('refresh'))
  }

  private formatPath(p = ''): string {
    return this.urlBuilder.joinPath(this.basePath, p)
  }

  static Build() {
    return container.resolve(AuthenticationService)
  }
}

export const authenticationService = AuthenticationService.Build()
