import { PublishEvents } from '../../models/PubSub'

export enum TrialRecommenderSubscribeEvents {
  ToggleLoading = 'toggleLoading',
  HttpError = 'RestService'
}

export type WidgetMessageEvent = {
  data: {
    eventName: PublishEvents
    payload: Record<string, any>
  }
} & MessageEvent

export type UnsubscribeCallback = Function

export interface ToggleLoadingPayload {
  showLoading: boolean
}

export interface HttpResponsePayload {
  response: {
    ok: boolean
    status: number
    statusText: string
  }
}
