import { container, inject, injectable } from 'tsyringe'

import { ReduxAsyncNotifier } from '../../lib/async-notifier/redux-async-notifier'
import type { AsyncNotifier } from '../../lib/async-notifier/async-notifier'
import {
  Organization,
  OrganizationService,
  DepartmentType
} from '../../services/organization/organization.service'
import { AbstractAction } from '../abstract-action'

export enum OrganizationActionTypes {
  addTargetSites = 'ORGANIZATION::ADD_HEALTH_SYSTEMS',
  addDepartments = 'ORGANIZATION::ADD_DEPARTMENTS'
}

@injectable()
export class OrganizationActions {
  constructor(
    private organizationService: OrganizationService,
    @inject(ReduxAsyncNotifier.injectionToken)
    private asyncNotifier: AsyncNotifier
  ) {}

  addHealthSystemOrganizations(
    healthSystemOrganizations: Organization[]
  ): AbstractAction<OrganizationActionTypes, Organization[]> {
    return {
      type: OrganizationActionTypes.addTargetSites,
      payload: healthSystemOrganizations
    }
  }

  addHealthSystemDepartments(
    healthSystemDeparments: DepartmentType[]
  ): AbstractAction<OrganizationActionTypes, DepartmentType[]> {
    return {
      type: OrganizationActionTypes.addDepartments,
      payload: healthSystemDeparments
    }
  }

  getHealthSystemDepartments = this.asyncNotifier.subscribe(
    (healthSystemUuid: string) => async (dispatch) => {
      dispatch(
        this.addHealthSystemDepartments(
          await this.organizationService.fetchDepartments(healthSystemUuid)
        )
      )
    }
  )

  getHealthSystemOrganizations = this.asyncNotifier.subscribe(
    () => async (dispatch) => {
      dispatch(
        this.addHealthSystemOrganizations(
          await this.organizationService.findAllHealthSystemOrganizations()
        )
      )
    }
  )

  static Build() {
    return container.resolve(OrganizationActions)
  }
}

export const organizationActions = OrganizationActions.Build()
