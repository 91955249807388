import { injectable } from 'tsyringe'

import {
  BucketMapper,
  ReducerCB,
  MissingMetadataException
} from './bucket-mapper'
import {
  AllowedInScreeningRecruitmentStatuses,
  InScreeningMetadata,
  Recruitment,
  RecruitmentBucketKey
} from '../Recruitment'
import { ValidatedMapper } from './validated-mapper'
import { IncomingRecruitmentBucketItemMetadata } from './recruitment-bucket-mapper'
import { ReferredMapper } from './referred-mapper'
import { ConsultationScheduledMapper } from './consultation-scheduled-mapper'
import { AccountDisplayName } from '../../account/account-service'

@injectable()
export class InScreeningMapper implements BucketMapper<InScreeningMetadata> {
  constructor(
    private validatedMapper: ValidatedMapper,
    private referredMapper: ReferredMapper,
    private consultationScheduledMapper: ConsultationScheduledMapper
  ) {}

  buildMapper(
    principleInvestigators: AccountDisplayName[]
  ): ReducerCB<InScreeningMetadata> {
    return (aggregate, current) => {
      return this.bucketMapper(
        aggregate,
        current,
        this.transform,
        principleInvestigators
      )
    }
  }

  transform = (
    metadata: IncomingRecruitmentBucketItemMetadata,
    principleInvestigators: AccountDisplayName[]
  ): InScreeningMetadata => {
    if (!metadata.statusMetadata.consentedMetadata?.timestamp) {
      throw new MissingMetadataException(RecruitmentBucketKey.IN_SCREENING)
    }

    const { statusMetadata: validationMetadata } =
      this.validatedMapper.transform(metadata)

    const { statusMetadata: referralMetadata } =
      this.referredMapper.transform(metadata)

    const { statusMetadata: consultScheduledMetadata } =
      this.consultationScheduledMapper.transform(
        metadata,
        principleInvestigators
      )

    return {
      currentStatus:
        metadata.currentStatus as AllowedInScreeningRecruitmentStatuses,
      statusMetadata: {
        updatedAt: metadata.statusMetadata.consentedMetadata.timestamp,
        validationMetadata,
        referralMetadata,
        consultScheduledMetadata
      }
    }
  }

  private bucketMapper = (
    buckets: Recruitment<InScreeningMetadata>[],
    incomingItem: Recruitment<IncomingRecruitmentBucketItemMetadata>,
    metadataTransform: (
      metadata: IncomingRecruitmentBucketItemMetadata,
      principleInvestigators: AccountDisplayName[]
    ) => InScreeningMetadata,
    principleInvestigators: AccountDisplayName[]
  ): Recruitment<InScreeningMetadata>[] => {
    return [
      ...buckets,
      {
        ...incomingItem,
        metadata: metadataTransform(
          incomingItem.metadata,
          principleInvestigators
        )
      }
    ]
  }
}
