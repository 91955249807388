import React, { PropsWithChildren } from 'react'
import { HttpError } from '@deep6ai/common'

import { AsyncStatus } from '../../hooks/useAsyncAction/useAsyncAction'
import { ErrorStatusViewOverlay } from '../Error/ErrorStatusView'
import { LoadingOverlay } from '../Loading/LoadingOverlay'

type AsyncStatusHttpError = HttpError | undefined | null

export interface AsyncStatusViewProps extends PropsWithChildren<{}> {
  status: AsyncStatus
  errors?: AsyncStatusHttpError[]
  onErrorShow?: JSX.Element
  onPendingShow?: JSX.Element
  onPristineShow?: JSX.Element
  onPristineShowChildren?: boolean
}

export const AsyncStatusView = ({
  status,
  children,
  errors = [],
  onErrorShow = <ErrorStatusViewOverlay errors={errors} />,
  onPendingShow = <LoadingOverlay />,
  onPristineShow = <LoadingOverlay />,
  onPristineShowChildren = false
}: AsyncStatusViewProps) => {
  const renderChildren = () => <>{children}</>

  switch (status) {
    case AsyncStatus.Error:
      return onErrorShow
    case AsyncStatus.Pristine:
      return onPristineShowChildren ? renderChildren() : onPristineShow
    case AsyncStatus.Pending:
      return onPendingShow
    case AsyncStatus.Resolved:
      return renderChildren()
  }
}
