import React from 'react'

import { SeparatorThinnest } from '../Separator/Separator'

/**
 * Renders a list of items with a separator above, between, and below.
 *
 * @param items - An array of items.
 * @callback getKey - Extracts the React key for each item in the items array.
 * @callback renderItem - Renders each item in the items array.
 */
export function ListSeparated<T>({
  items,
  getKey,
  renderItem
}: {
  items: T[]
  getKey: (item: T, index: number) => React.Key
  renderItem: (item: T, index: number) => React.ReactNode
}) {
  return (
    <div>
      <SeparatorThinnest className="my-0" />

      {items.map((item, index) => (
        <div key={getKey(item, index)}>
          {renderItem(item, index)}
          <SeparatorThinnest className="my-0" />
        </div>
      ))}
    </div>
  )
}
