import React, { useEffect, useState } from 'react'
import isNull from 'lodash/isNull'
import cloneDeep from 'lodash/cloneDeep'
import { toast } from 'react-toastify'
import { UrlBuilderFactory } from '@deep6ai/common'
import { If, ButtonOutlineDefault } from '@deep6ai/component-library'

import { StudyFormContext } from '../StudyFormContext'
import { useMountEffect } from '../../../../../hooks/useMountEffect'
import { LinkAttachmentInput } from '../../../LinkAttachment/LinkAttachmentInput'
import { HelpToolTip } from '../../../../ToolTip/HelpToolTip'
import { ToolTip } from '../../../../ToolTip/ToolTip'
import { FileIcon, LinkIcon } from '../../../../Icon/Icon'
import { ListSeparated } from '../../../../List/ListSeparated'
import { AttachmentItem } from '../../../../Attachment/AttachmentItem'
import { Label } from '../../../Label'
import { FileAttachmentUploader } from '../../../FileAttachment/FileAttachmentUploader'
import { FilePicker, FileTypes } from '../../../FilePicker'
import { FileAttachmentEdit } from '../../../FileAttachment/FileAttachmentEdit'
import { LinkAttachmentEdit } from '../../../LinkAttachment/LinkAttachmentEdit'
import { AttachmentDeleteModal } from '../../../../Modal/Attachment/AttachmentDeleteModal'
import {
  Attachment,
  AttachmentType,
  CreateFileAttachment,
  CreateLinkAttachment,
  EditFileAttachment,
  EditLinkAttachment,
  DeleteLinkAttachment,
  DeleteFileAttachment,
  StudyAttachmentService,
  isAttachmentFormValue,
  isCreateAttachmentFormValue,
  isDeleteAttachmentFormValue,
  DownloadableAttachment
} from '../../../../../services/study/StudyAttachmentService'
import { errorMessages } from '../../errorMessages'

const urlBuilder = UrlBuilderFactory.build()

/**
 * @description we don't commit updates to attachments until after submission
 * so, we need to keep track of the attachments state internally and then
 * handle what happens in logic that handles the form payload.
 */
export enum StudyAttachmentFormControlState {
  NEW, // new attachment
  DELETE, // marked for deletion
  EDIT // existing attachment that has been edited
}

export interface AttachmentsFormControlProps {
  attachments?: Attachment[]
  formCtx: StudyFormContext
  onDownload: (attachment: DownloadableAttachment) => void
  onPreview: (attachment: DownloadableAttachment) => void
  isEditable?: boolean
  onAddClick?: () => void
  onAddSave?: () => void
  onAddCancel?: () => void
  onEditClick?: () => void
  onEditSave?: () => void
  onEditCancel?: () => void
}

export interface CreateLinkAttachmentFormValue extends CreateLinkAttachment {
  state: StudyAttachmentFormControlState.NEW
}

export interface CreateFileAttachmentFormValue extends CreateFileAttachment {
  state: StudyAttachmentFormControlState.NEW
}

export interface EditLinkAttachmentFormValue extends EditLinkAttachment {
  state: StudyAttachmentFormControlState.EDIT
}

export interface EditFileAttachmentFormValue extends EditFileAttachment {
  state: StudyAttachmentFormControlState.EDIT
}

export interface DeleteLinkAttachmentFormValue extends DeleteLinkAttachment {
  state: StudyAttachmentFormControlState.DELETE
}

export interface DeleteFileAttachmentFormValue extends DeleteFileAttachment {
  state: StudyAttachmentFormControlState.DELETE
}

export type AttachmentFormValue =
  | CreateFileAttachmentFormValue
  | CreateLinkAttachmentFormValue
  | EditFileAttachmentFormValue
  | EditLinkAttachmentFormValue
  | DeleteFileAttachmentFormValue
  | DeleteLinkAttachmentFormValue

export const PDF_FILE_SIZE_LIMIT_IN_BYTES = 150 * 1024 * 1024

export const AttachmentsFormControl = ({
  attachments = [],
  formCtx,
  onDownload,
  onPreview,
  isEditable = true,
  onAddClick,
  onEditClick,
  onAddSave,
  onEditSave,
  onAddCancel,
  onEditCancel
}: AttachmentsFormControlProps) => {
  const [isAddingLinkAttachment, setIsAddingLinkAttachment] = useState(false)
  const [linkNameInput, setLinkNameInput] = useState('')
  const [linkUrlInput, setLinkUrlInput] = useState('')
  const [linkError, setLinkError] = useState('')
  const [file, setFile] = useState<File | null>(null)
  const [fileNameInput, setFileNameInput] = useState('')
  const [attachmentToEdit, setAttachmentToEdit] =
    useState<Attachment | AttachmentFormValue | null>(null)
  const [attachmentToEditIndex, setAttachmentToEditIndex] =
    useState<number | null>(null)
  const [attachmentToDelete, setAttachmentToDelete] =
    useState<Attachment | AttachmentFormValue | null>(null)
  const [attachmentToDeleteIndex, setAttachmentToDeleteIndex] =
    useState<number | null>(null)

  const { register, setValue, watch } = formCtx
  const formAttachments: (Attachment | AttachmentFormValue)[] =
    watch('attachments') ?? []
  const nonDeleteFormAttachments = formAttachments.filter(
    (attachment) =>
      !isAttachmentFormValue(attachment) ||
      !isDeleteAttachmentFormValue(attachment)
  )

  useEffect(() => {
    register('attachments')
  }, [register])

  useMountEffect(() => {
    setValue('attachments', attachments)
  })

  function clearLinkForm() {
    setLinkNameInput('')
    setLinkUrlInput('')
    setLinkError('')
  }

  function clearFileForm() {
    setFileNameInput('')
    setFile(null)
  }

  /*
   * Adding a File Attachment
   */

  function handleFileAttachmentChange(newFile: File[]) {
    const file = newFile[0]
    if (file) {
      setFile(file)
      onAddClick?.()
    }
  }

  function handleFileAttachmentError(error) {
    toast(error.message, {
      type: toast.TYPE.ERROR
    })
  }

  function handleFileAttachmentInputChangeName(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setFileNameInput(event.target.value)
  }

  function handleFileAttachmentInputCancel() {
    onAddCancel?.()
    clearFileForm()
  }

  function handleFileAttachmentAdd() {
    if (!file) return

    const newFileAttachmentValues: CreateFileAttachmentFormValue = {
      displayName: fileNameInput || file.name,
      file,
      fileName: file.name,
      state: StudyAttachmentFormControlState.NEW,
      type: AttachmentType.FILE
    }
    setValue('attachments', [...formAttachments, newFileAttachmentValues])
    onAddSave?.()
    clearFileForm()
  }

  /*
   * Adding a Link Attachment
   */

  function handleLinkAttachmentAddButtonClicked(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    e.preventDefault()
    setIsAddingLinkAttachment(true)
    onAddClick?.()
  }

  function handleLinkAttachmentInputAdd() {
    if (!linkUrlInput) {
      setLinkError(errorMessages.url.required)
      return
    }

    const sanitizedUrl = sanitizeLinkUrlValue(linkUrlInput)

    if (!urlBuilder.validate(sanitizedUrl, { requireProtocol: true })) {
      setLinkError(errorMessages.url.invalid)
      return
    }

    const newLinkAttachmentValues: CreateLinkAttachmentFormValue = {
      displayName: linkNameInput || linkUrlInput,
      type: AttachmentType.LINK,
      state: StudyAttachmentFormControlState.NEW,
      url: sanitizedUrl
    }

    setValue('attachments', [...formAttachments, newLinkAttachmentValues])
    onAddSave?.()
    clearLinkForm()
    setIsAddingLinkAttachment(false)
  }

  function handleLinkAttachmentInputCancel() {
    onAddCancel?.()
    clearLinkForm()
    setIsAddingLinkAttachment(false)
  }

  function handleLinkAttachmentInputChangeName(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setLinkNameInput(event.target.value)
  }

  function handleLinkAttachmentInputUrlChange(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    setLinkUrlInput(event.target.value)
  }

  function sanitizeLinkUrlValue(url: string) {
    const urlWithProtocol = `http://${url}`
    return urlIsValidButMissingProtocol(url) ? urlWithProtocol : url
  }

  /*
   * Editing an Attachment
   */

  function handleAttachmentEdit(
    attachment: Attachment | AttachmentFormValue,
    index: number
  ) {
    onEditClick?.()
    setAttachmentToEdit(attachment)
    setAttachmentToEditIndex(index)
  }

  function handleAttachmentEditChangeName(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    const displayName = event.target.value
    setAttachmentToEdit((a) => {
      if (!a) return null
      return { ...a, displayName }
    })
  }

  function handleAttachmentEditCancel() {
    onEditCancel?.()
    setAttachmentToEdit(null)
    setAttachmentToEditIndex(null)
  }

  function handleAttachmentEditUpdate() {
    if (isNull(attachmentToEdit) || isNull(attachmentToEditIndex)) return

    if (
      isAttachmentFormValue(attachmentToEdit) &&
      isCreateAttachmentFormValue(attachmentToEdit)
    ) {
      const newAttachments = cloneDeep(formAttachments)
      newAttachments[attachmentToEditIndex] = {
        ...attachmentToEdit,
        displayName:
          StudyAttachmentService.getAttachmentDisplayName(attachmentToEdit)
      }
      setValue('attachments', newAttachments)
    } else {
      const newAttachments = cloneDeep(formAttachments)
      const newAttachmentEdit:
        | EditLinkAttachmentFormValue
        | EditFileAttachmentFormValue = {
        ...attachmentToEdit,
        displayName:
          StudyAttachmentService.getAttachmentDisplayName(attachmentToEdit),
        state: StudyAttachmentFormControlState.EDIT
      }
      newAttachments[attachmentToEditIndex] = newAttachmentEdit
      setValue('attachments', newAttachments)
    }

    onEditSave?.()
    setAttachmentToEdit(null)
    setAttachmentToEditIndex(null)
  }

  /*
   * Deleting an Attachment
   */

  function handleAttachmentDelete(
    attachment: Attachment | AttachmentFormValue,
    index: number
  ) {
    setAttachmentToDelete(attachment)
    setAttachmentToDeleteIndex(index)
  }

  function handleAttachmentConfirmRemove() {
    if (isNull(attachmentToDelete) || isNull(attachmentToDeleteIndex)) return

    if (
      isAttachmentFormValue(attachmentToDelete) &&
      isCreateAttachmentFormValue(attachmentToDelete)
    ) {
      const attachmentsCopy = [...formAttachments]
      attachmentsCopy.splice(attachmentToDeleteIndex, 1)
      setValue('attachments', attachmentsCopy)
    } else {
      const attachmentsCopy = cloneDeep(formAttachments)
      const attachmentToChange = attachmentsCopy[attachmentToDeleteIndex]
      attachmentToChange['state'] = StudyAttachmentFormControlState.DELETE
      setValue('attachments', attachmentsCopy)
    }

    setAttachmentToDelete(null)
    setAttachmentToDeleteIndex(null)
  }

  function handleAttachmentRemoveCancel() {
    setAttachmentToDelete(null)
    setAttachmentToDeleteIndex(null)
  }

  /*
   * Showing Attachments
   */

  const isUpdatingAttachments = Boolean(
    isAddingLinkAttachment || file || attachmentToEdit
  )
  const showPublishAttachmentsMessage =
    !nonDeleteFormAttachments.length && !isAddingLinkAttachment && !file
  const showAttachmentList =
    !!nonDeleteFormAttachments.length &&
    !isAddingLinkAttachment &&
    !file &&
    !attachmentToEdit
  const showFileUploader = !!file
  const showLinkAttachmentInput = isAddingLinkAttachment
  const showAttachmentEditor = !!attachmentToEdit

  return (
    <div data-testid="AttachmentsFormControl">
      <div className="flex justify-between mb-2">
        <div className="flex items-center">
          <Label>
            Share Links or PDFs
            <HelpToolTip
              id="HelpToolTip-attachments"
              helpText="Published links & files will be visible to study recipients and on Trial Recommender. These files should not include any PHI."
              className="ml-2"
            />
          </Label>
        </div>

        <If condition={isEditable}>
          <div>
            <ButtonOutlineDefault
              data-tip
              data-for="tip-AddLink-Button"
              id="AddLink-Button"
              className="mr-2 px-2 py-1"
              disabled={isUpdatingAttachments}
              onClick={handleLinkAttachmentAddButtonClicked}
            >
              <LinkIcon className="mr-1" /> +
            </ButtonOutlineDefault>
            <ToolTip id="tip-AddLink-Button">Share a Link</ToolTip>

            <FilePicker
              id="FilePicker"
              accept={[FileTypes.PDF]}
              className="px-2 py-1"
              disabled={isUpdatingAttachments}
              onChange={handleFileAttachmentChange}
              onError={handleFileAttachmentError}
              sizeLimitInBytes={PDF_FILE_SIZE_LIMIT_IN_BYTES}
              tooltipText="Share a PDF"
            >
              <FileIcon className="mr-1" /> +
            </FilePicker>
          </div>
        </If>
      </div>

      <If condition={showPublishAttachmentsMessage}>
        <div
          className="flex bg-grey-05 items-center justify-center"
          style={{ height: 150 }}
        >
          Publish links or files to share info about your study with site
          personnel
        </div>
      </If>

      <If condition={showAttachmentList}>
        <ListSeparated
          items={formAttachments}
          getKey={(_, index) => index}
          renderItem={(attachment, index) => (
            <AttachmentItem
              id={`AttachmentItem-${index}`}
              attachment={attachment}
              onDownload={onDownload}
              onPreview={onPreview}
              onEdit={(attachment) => handleAttachmentEdit(attachment, index)}
              onRemove={(attachment) =>
                handleAttachmentDelete(attachment, index)
              }
              isEditable={isEditable}
            />
          )}
        />
      </If>

      <If condition={showFileUploader}>
        <FileAttachmentUploader
          fileDisplayName={fileNameInput}
          fileName={file?.name!}
          onAdd={handleFileAttachmentAdd}
          onCancel={handleFileAttachmentInputCancel}
          onChangeName={handleFileAttachmentInputChangeName}
        />
      </If>

      <If condition={showLinkAttachmentInput}>
        <LinkAttachmentInput
          displayName={linkNameInput}
          error={linkError}
          onAdd={handleLinkAttachmentInputAdd}
          onCancel={handleLinkAttachmentInputCancel}
          onChangeName={handleLinkAttachmentInputChangeName}
          onChangeUrl={handleLinkAttachmentInputUrlChange}
          url={linkUrlInput}
        />
      </If>

      <If condition={showAttachmentEditor}>
        {attachmentToEdit &&
          (attachmentToEdit?.type === AttachmentType.FILE ? (
            <FileAttachmentEdit
              displayName={attachmentToEdit.displayName}
              fileName={attachmentToEdit.fileName}
              onUpdate={handleAttachmentEditUpdate}
              onCancel={handleAttachmentEditCancel}
              onChangeName={handleAttachmentEditChangeName}
            />
          ) : (
            <LinkAttachmentEdit
              displayName={attachmentToEdit.displayName}
              onUpdate={handleAttachmentEditUpdate}
              onCancel={handleAttachmentEditCancel}
              onChangeName={handleAttachmentEditChangeName}
              url={attachmentToEdit.url}
            />
          ))}
      </If>

      <AttachmentDeleteModal
        isOpen={!!attachmentToDelete}
        onCancel={handleAttachmentRemoveCancel}
        onDelete={handleAttachmentConfirmRemove}
      />
    </div>
  )
}

function urlIsValidButMissingProtocol(url: string) {
  const urlIsValid = urlBuilder.validate(url)
  const urlIsMissingProtocol = !urlBuilder.validate(url, {
    requireProtocol: true
  })
  return urlIsValid && urlIsMissingProtocol
}
