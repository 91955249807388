import { container, inject, injectable } from 'tsyringe'
import uniq from 'lodash/uniq'
import { ParseError } from 'papaparse'
import {
  HttpClient,
  JsonHttpClient,
  StorageClient,
  UrlBuilder,
  CsvParser,
  CsvFileParserInput,
  CsvFileParserConfig,
  CsvFileParserOutput
} from '@deep6ai/common'
import {
  LocalStorageGetValue,
  LocalStorageSetValue
} from '@deep6ai/common/dist/storage/local-storage/localstorage-storage-client'

import * as SpokeHttpClientIoc from '../../lib/spoke-http-client/SpokeHttpClient.ioc'
import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import { PatientService } from '../patient/patient-service'
import * as LocalStorageStorageClientIoc from '../../lib/storage/LocalStorageStorageClient.ioc'
import * as CsvFileParserIoc from '../../lib/csv/CsvFileParser.ioc'
import {
  GetPatientResult,
  Patient,
  StudiesForPatient
} from '../patient/models/Patient'
import { StudyPhase } from '../study/study.service'
import { PatientSummary } from './models/TrialRecommender'

interface ParsedMrnCsvResult {
  mrns: string[]
  errors: ParseError[]
}

@injectable()
export class TrialRecommenderService {
  private basePath = '/trial-recommender'

  // TODO: This will need to be synced to the trial-recommender iframe.
  // See: https://qurius.atlassian.net/browse/PROD-1916
  private readonly STORAGE_KEY = 'patientListUuids'

  constructor(
    @inject(SpokeHttpClientIoc.injectionToken)
    private spokeHttpClient: JsonHttpClient,
    @inject(CentralApiHttpClient.injectionToken)
    private centralApi: JsonHttpClient & HttpClient,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder,
    @inject(LocalStorageStorageClientIoc.injectionToken)
    private storageClient: StorageClient<
      LocalStorageGetValue,
      LocalStorageSetValue
    >,
    @inject(CsvFileParserIoc.injectionToken)
    private csvParser: CsvParser<
      CsvFileParserInput,
      CsvFileParserOutput,
      CsvFileParserConfig
    >,
    private patientService: PatientService
  ) {}

  getSavedPatients = async (): Promise<Patient[]> => {
    const savedPatientUuids = this.getSavedPatientUuids()
    if (!savedPatientUuids.length) return []

    const patientResults = await this.patientService.getPatientsByUuids(
      savedPatientUuids
    )
    const patients =
      TrialRecommenderService.filterPatientsFromPatientResults(patientResults)

    return patients
  }

  upsertSavedPatients = (patients: Patient[]): void => {
    const prevPatientUuids = this.getSavedPatientUuids()
    const newPatientUuids = patients.map((p) => p.uuid)
    const mergedPatientUuids = uniq([...prevPatientUuids, ...newPatientUuids])
    // TODO: This will need to be synced to the trial-recommender iframe.
    // See: https://qurius.atlassian.net/browse/PROD-1916
    this.storageClient.set(this.STORAGE_KEY, mergedPatientUuids)
  }

  removeSavedPatient = (uuidToRemove: string): void => {
    // TODO: This will need to be synced to the trial-recommender iframe.
    // See: https://qurius.atlassian.net/browse/PROD-1916
    this.storageClient.set(
      this.STORAGE_KEY,
      this.getSavedPatientUuids().filter((p) => p !== uuidToRemove)
    )
  }

  parseMrnsFromCsv = async (file: File | null): Promise<ParsedMrnCsvResult> => {
    if (!file) return Promise.resolve({ mrns: [], errors: [] })

    const { data, errors } = await this.csvParser.parse(file)

    const filterEmptyValues = (val: unknown) => Boolean(val)

    return {
      mrns: data.flatMap((d) => d).filter(filterEmptyValues),
      errors
    }
  }

  static filterPatientsFromPatientResults = (
    patientResults: GetPatientResult[]
  ): Patient[] => {
    if (!patientResults) return []
    return patientResults
      .map((result) => result.patient)
      .filter((patient): patient is Patient => !!patient)
  }

  static filterPatientUuidsFromPatientResults = (
    patientResults: GetPatientResult[]
  ): string[] => {
    return TrialRecommenderService.filterPatientsFromPatientResults(
      patientResults
    ).map((patient) => patient.uuid)
  }

  static filterErrorMessagesFromPatientResults = (
    patientResults: GetPatientResult[]
  ): string[] => {
    return patientResults
      .map((result) => result.message)
      .filter((message): message is string => !!message)
  }

  getPatientSummary = (
    patientUuids: string[],
    studyPhases: StudyPhase[]
  ): Promise<PatientSummary> => {
    return this.centralApi.postJson(
      this.urlBuilder.build(this.formatPath('summary'), {
        studyPhases: studyPhases
      }),
      {
        body: JSON.stringify(patientUuids)
      }
    )
  }

  // TODO:
  //  Duplicate of getStudiesForPatients so that we can have the TR landing page and the TR patient
  //  details page can call different endpoints. Currently both do exactly the same thing. This will
  //  change in follow up PRs.
  getStudiesForPatientsByPatientUuids = (
    patientUuids: string[],
    studyPhases: StudyPhase[]
  ): Promise<StudiesForPatient[]> => {
    return this.centralApi.postJson(
      this.urlBuilder.build(this.formatPath('find-studies-by-patient-uuids'), {
        studyPhases: studyPhases
      }),
      {
        body: JSON.stringify(patientUuids)
      }
    )
  }

  getSavedPatientUuids = () => {
    const isArray = (item: unknown): item is string[] => Array.isArray(item)
    const patientListUuids = this.storageClient.get(this.STORAGE_KEY) || []
    return isArray(patientListUuids) ? patientListUuids : []
  }

  setSavedPatientUuids = (savedPatientUuids: string[]) => {
    this.storageClient.set(this.STORAGE_KEY, savedPatientUuids)
  }

  private formatPath(p = ''): string {
    return this.urlBuilder.joinPath(this.basePath, p)
  }

  static build() {
    return container.resolve(TrialRecommenderService)
  }
}

export const trialRecommenderService = TrialRecommenderService.build()
