import React from 'react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import { NAV_HEIGHT } from './Navbar'

export interface NavItemProps {
  id?: string
  href: string
  className?: string
  disabled?: boolean
  children: React.ReactNode
}

// `nav a` styling is defined in tailwind.css to provide css specificity
// https://stackoverflow.com/questions/12258596/class-overrule-when-two-classes-assigned-to-one-div/12258654
export const NavItem = ({
  id,
  href,
  className,
  disabled,
  children
}: NavItemProps) => (
  <NavLink
    to={href}
    style={{ height: NAV_HEIGHT, lineHeight: `${NAV_HEIGHT}px` }}
    className={classNames(
      'flex items-center px-4 whitespace-no-wrap',
      className,
      { disabled }
    )}
    aria-disabled={disabled}
    activeClassName="active"
    data-testid={id || `NavLink-${children}`}
  >
    {children}
  </NavLink>
)
