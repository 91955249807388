import { useHistory, useLocation } from 'react-router-dom'
import { UrlBuilder, UrlBuilderFactory, ParsedQuery } from '@deep6ai/common'
import window from 'global/window'

export function useQueryParams(
  urlBuilder: UrlBuilder = UrlBuilderFactory.build()
): [
  ParsedQuery,
  (params: { [key: string]: any }) => void,
  (key: string) => void
] {
  const history = useHistory()
  const location = useLocation()
  const queryParams = urlBuilder.parse(location.search)

  // Since AG Grid selectively re-renders, components in the grid won't recieve
  // updated state changes. This results in a stale location object when this
  // hook doesn't run. Because AG Grid breaks the standard React rendering
  // model in this way, we must also deviate from it in order to ensure that
  // fresh query params are being used within this hook. TODOs are left at the
  // lines related to the workaround.
  //
  // See: doc/adr/0013-stale-uselocation.md

  function setQueryParams(newQueryParams: { [key: string]: any }) {
    // TODO: Delete the following line once AG Grid has been removed.
    const queryParams = urlBuilder.parse(window.location.search)

    const search = urlBuilder.formatQueryString({
      ...queryParams,
      ...newQueryParams
    })
    history.replace({ search })
  }

  function removeQueryParam(key: string) {
    // TODO: Delete the following line once AG Grid has been removed.
    const queryParams = urlBuilder.parse(window.location.search)

    const queryParamsCopy = { ...queryParams }
    delete queryParamsCopy[key]
    history.replace({ search: urlBuilder.formatQueryString(queryParamsCopy) })
  }

  return [queryParams, setQueryParams, removeQueryParam]
}
