import React from 'react'
import { connect } from 'react-redux'
import { If } from '@deep6ai/component-library'

import { User } from '../../../services/authentication/authentication-service'
import { TermList } from '../../../services/term-list/TermListService'

interface UserCanEditTermListProps {
  activeUser: User
  termList: TermList
  children: React.ReactNode
  otherwise?: React.ReactNode
}

export const canUserEditTermList = (user: User, termList: TermList) => {
  const termListUserOrg = termList.termListOrganization.find(
    (o) => o.organizationUuid === user.organization.uuid
  )
  const termListUserTeam = termList.termListTeam.find(
    (a) => a.accountUuid === user.accountUuid
  )
  return Boolean(
    termListUserOrg?.canEdit === true || termListUserTeam?.canEdit === true
  )
}

export const _ActiveUserCanEditTermList = ({
  activeUser,
  termList,
  children,
  otherwise
}: UserCanEditTermListProps) => {
  const userCanEditTermList = canUserEditTermList(activeUser, termList)
  return (
    <If condition={userCanEditTermList} otherwise={otherwise}>
      {children}
    </If>
  )
}

const mapStateToProps = ({ authentication }) => ({
  activeUser: authentication.activeUser
})

export const ActiveUserCanEditTermList = connect(mapStateToProps)(
  _ActiveUserCanEditTermList
)
