import React, { useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import { trialRecommenderContextService } from '../../../services/trial-recommender/TrialRecommenderContextService'

export interface TrialRecommenderContextWatcherProps {
  setContext: typeof trialRecommenderContextService.setContext
}

/**
 * @name TrialRecommenderContextWatcher
 * @description Watches all route changes and re-renders the setter on every change which
 * in turn triggers its useEffect callback which then dispatches the updated trial recommender context
 * */
export const TrialRecommenderContextWatcher = ({
  setContext = trialRecommenderContextService.setContext
}: Partial<TrialRecommenderContextWatcherProps>) => (
  <Switch>
    <Route path={['*']}>
      <TrialRecommenderContextSetter setContext={setContext} />
    </Route>
  </Switch>
)

export const TrialRecommenderContextSetter = ({
  setContext
}: TrialRecommenderContextWatcherProps) => {
  const location = useLocation()
  useEffect(() => {
    const isTrialRecommenderContext = location.pathname
      .split('/')
      .includes('trial-recommender')
    setContext(isTrialRecommenderContext)
  }, [setContext, location.pathname])
  return <></>
}
