import { container, inject, injectable } from 'tsyringe'
import { Logger, JsonHttpClient, HttpClient, UrlBuilder } from '@deep6ai/common'

import { AccountDisplayName } from '../account/account-service'
import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import { PaginatedResponse } from '../../lib/http/paginated-response'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import * as LoggerIoc from '../../lib/logging/Logger.ioc'
import { ProtocolView } from '../protocol/ProtocolService'

export interface TermList {
  termListUuid: string
  title: string
  description: string
  termListOrganization: TermListOrganization[]
  termListTeam: TermListTeamMember[]
  termConceptCount: number
  createdAt: string
  createdBy: AccountDisplayName
  updatedAt: string
  updatedBy: AccountDisplayName
}

export type TermListSummary = Pick<
  TermList,
  | 'termListUuid'
  | 'title'
  | 'description'
  | 'termConceptCount'
  | 'createdAt'
  | 'createdBy'
  | 'updatedAt'
  | 'updatedBy'
>

export interface TermListDefaultView {
  termListUuid: string
  title: string
  description: string
  termListState: string
  updatedByUuid: string
  createdByUuid: string
  updatedAt: string
  createdAt: string
}

export type TermListUpsert = Pick<TermList, 'title' | 'description'>

export interface TermListTeamMember {
  accountUuid: string
  displayName: string
  canView: boolean
  canEdit: boolean
}

export interface AddTeamMember {
  accountUuid: string
  permission: TeamMemberPermission
}

export enum TeamMemberPermission {
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}

export interface TermListOrganization {
  organizationUuid: string
  canView: boolean
  canEdit: boolean
}

export interface AddOrganization {
  organizationUuid: string
  permission: OrganizationPermission
}

export enum OrganizationPermission {
  NONE = 'NONE',
  VIEW = 'VIEW',
  EDIT = 'EDIT'
}

export interface TermConcept {
  name: string
  cui: string
  categories: string[]
}

@injectable()
export class TermListService {
  private basePath = '/term-list'

  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private centralApi: JsonHttpClient & HttpClient,
    @inject(LoggerIoc.injectionToken) private logger: Logger,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder
  ) {}

  findTermLists(
    search?: string
  ): Promise<PaginatedResponse<TermListSummary[]>> {
    return this.centralApi.getJson(
      this.urlBuilder.build(this.formatPath(), {
        search
      })
    )
  }

  findTermListByUuid(termListUuid: string): Promise<TermList> {
    return this.centralApi.getJson(this.formatPath(termListUuid))
  }

  archiveTermList = (termListUuid: string): Promise<Response> => {
    return this.centralApi.delete(this.formatPath(termListUuid))
  }

  findTermConceptsByTermListUuid(termListUuid: string): Promise<TermConcept[]> {
    return this.centralApi.getJson(this.formatPath(`${termListUuid}/concept`))
  }

  applyListToProtocol = (
    studyUuid: string,
    termListUuid: string,
    protocolCoordinates,
    protocol: ProtocolView
  ): Promise<Response> => {
    return this.centralApi.put(
      this.formatPath(`${termListUuid}/apply/${studyUuid}`),
      {
        body: JSON.stringify({
          protocolCoordinates,
          protocolVersion: protocol.version
        })
      }
    )
  }

  createTermList = (
    termListUpsert: TermListUpsert
  ): Promise<TermListDefaultView> => {
    return this.centralApi.postJson(this.formatPath(), {
      body: JSON.stringify(termListUpsert)
    })
  }

  updateTermList = (
    termListUuid: string,
    termListUpsert: TermListUpsert
  ): Promise<TermListDefaultView> => {
    return this.centralApi.putJson(this.formatPath(termListUuid), {
      body: JSON.stringify(termListUpsert)
    })
  }

  static teamUuidsToAddTeamMembers = (
    viewTeamUuids: string[],
    editTeamUuids: string[]
  ): AddTeamMember[] => {
    const viewTeamMembers = viewTeamUuids
      .filter((accountUuid) => !editTeamUuids.includes(accountUuid))
      .map((accountUuid) => ({
        accountUuid,
        permission: TeamMemberPermission.VIEW
      }))
    const editTeamMembers = editTeamUuids.map((accountUuid) => ({
      accountUuid,
      permission: TeamMemberPermission.EDIT
    }))
    return viewTeamMembers.concat(editTeamMembers)
  }

  upsertTeamMembers = (
    termListUuid: string,
    teamMembers: AddTeamMember[]
  ): Promise<unknown> => {
    return this.centralApi.putJson(this.formatPath(`/${termListUuid}/team`), {
      body: JSON.stringify(teamMembers)
    })
  }

  upsertViewOrganization = (
    termListUuid: string,
    organizationUuid: string
  ): Promise<unknown> => {
    const addOrganization: AddOrganization = {
      organizationUuid,
      permission: OrganizationPermission.VIEW
    }
    return this.upsertOrganization(termListUuid, addOrganization)
  }

  upsertEditOrganization = (
    termListUuid: string,
    organizationUuid: string
  ): Promise<unknown> => {
    const addOrganization: AddOrganization = {
      organizationUuid,
      permission: OrganizationPermission.EDIT
    }
    return this.upsertOrganization(termListUuid, addOrganization)
  }

  upsertNoneOrganization = (
    termListUuid: string,
    organizationUuid: string
  ): Promise<unknown> => {
    const addOrganization: AddOrganization = {
      organizationUuid,
      permission: OrganizationPermission.NONE
    }
    return this.upsertOrganization(termListUuid, addOrganization)
  }

  upsertOrganization = (
    termListUuid: string,
    organization: AddOrganization
  ): Promise<unknown> => {
    return this.centralApi.postJson(
      this.formatPath(`/${termListUuid}/organization`),
      {
        body: JSON.stringify(organization)
      }
    )
  }

  removeTermConcepts = (
    termListUuid: string,
    termCuis: string[]
  ): Promise<Response> => {
    return this.centralApi.delete(this.formatPath(`/${termListUuid}/terms`), {
      body: JSON.stringify(termCuis)
    })
  }

  private formatPath(p = ''): string {
    return this.urlBuilder.joinPath(this.basePath, p)
  }

  static build() {
    return container.resolve(TermListService)
  }
}

export const termListService = TermListService.build()
