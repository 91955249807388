import React, { Fragment } from 'react'
import { useInterval } from 'react-use'

import { sessionService } from '../../services/session/SessionService'
import { authenticationService } from '../../services/authentication/authentication-service'

const TIMER_INTERVAL_MS = 1000

interface LogoutTimerProps {
  isSessionExpired?: typeof sessionService.isExpired
  logOut?: typeof authenticationService.logOut
}

export function LogoutTimer({
  isSessionExpired = sessionService.isExpired,
  logOut = authenticationService.logOut
}: LogoutTimerProps) {
  function logOutIfExpired() {
    if (isSessionExpired()) {
      logOut()
    }
  }

  useInterval(logOutIfExpired, TIMER_INTERVAL_MS)

  return <Fragment />
}
