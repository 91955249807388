import { LoggerFactory } from '@deep6ai/common'

import { AnyFunction } from '../../../types/AnyFunction'
import { Recruitment } from '../Recruitment'
import { IncomingRecruitmentBucketItemMetadata } from './recruitment-bucket-mapper'

export interface BucketMapper<T> {
  buildMapper: (...args: any[]) => Promise<ReducerCB<T>> | ReducerCB<T>
  transform: AnyFunction
}

export type ReducerCB<T> = (
  aggregate: Recruitment<T>[],
  current: Recruitment<IncomingRecruitmentBucketItemMetadata>
) => Recruitment<T>[]

export class InvalidRecruitmentStatusException extends Error {
  private logger = LoggerFactory.build()

  constructor(key) {
    super(`${key} is not a valid RecruitmentStatus value`)
    this.logger.error(this.message)
  }
}

export class MissingMetadataException extends Error {
  private logger = LoggerFactory.build()

  constructor(metadataKey) {
    super(
      `Could not bucket recruitment http response bucket item of type ${metadataKey} has missing values on its metadata`
    )
    this.logger.error(this.message)
  }
}
