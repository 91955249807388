import { container, inject, injectable } from 'tsyringe'
import type { Logger, HttpClient, UrlBuilder } from '@deep6ai/common'

import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import * as LoggerIoc from '../../lib/logging/Logger.ioc'
import {
  StudyRecruitmentStatus,
  Study,
  StudyPhase,
  StudyType
} from '../study/study.service'
import { FetchHttpClient } from '../../lib/http/fetch-http-client'
import { NctGovResponse } from './remote-trial-interface'

export interface CtGovResponse {
  title: string
  summary: string
  phase: StudyPhase
  studyType: StudyType
  recruitingStatus: StudyRecruitmentStatus
  parsedCriteria: ParsedNctCriteria
}

export enum RemoteTrialSource {
  NCT = 'NCT'
}

export interface RemoteTrial {
  id: string
  source: RemoteTrialSource
  title: string
}

export interface ParsedNctCriteria {
  inclusions: string[]
  exclusions: string[]
}

export interface NctRemoteTrial extends RemoteTrial {
  summary: string
  phase: StudyPhase
  studyType: StudyType
  recruitingStatus: StudyRecruitmentStatus
  parsedCriteria: ParsedNctCriteria
}

export const nctPattern = /^NCT\d{8}$/

@injectable()
export class RemoteTrialService {
  private readonly basePath = 'https://clinicaltrials.gov'

  constructor(
    @inject(FetchHttpClient.injectionToken) private httpClient: HttpClient,
    @inject(LoggerIoc.injectionToken) private logger: Logger,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder
  ) {}

  async findByNctCode(nctCode: string): Promise<NctRemoteTrial | null> {
    if (!RemoteTrialService.isValidNctCode(nctCode)) {
      this.logger.debug(`${nctCode} is not a valid NCT code.`)
      return Promise.resolve(null)
    }

    const res: Response = await this.httpClient.get(
      `${this.basePath}/api/v2/studies/${nctCode}?format=json&fields=DescriptionModule%7CEligibilityModule%7CDesignModule%7CIdentificationModule%7CStatusModule`,
      {
        okIf: RemoteTrialService.remoteTrialNotFound
      }
    )

    if (RemoteTrialService.remoteTrialNotFound(res)) return null

    const nctGovResponse: NctGovResponse = await res.json()
    const { protocolSection } = nctGovResponse

    if (protocolSection === undefined || protocolSection === null) {
      return null
    }

    const criteria =
      protocolSection.eligibilityModule.eligibilityCriteria.split(
        'Exclusion Criteria'
      )

    const inclusion = criteria[0]
      .replace('Inclusion Criteria', '')
      .trim()
      .split('\n*')
    let exclusion = ['']
    if (criteria[1]) {
      exclusion = criteria[1].trim().split('\n*')
    }

    const phase: StudyPhase =
      protocolSection.designModule.phases &&
      protocolSection.designModule.phases.length > 0
        ? (RemoteTrialService.updatePhaseFormat(
            protocolSection.designModule?.phases[0]
          ) as StudyPhase)
        : StudyPhase.NOT_AVAILABLE

    const ctGovResponse: CtGovResponse = {
      title: protocolSection.identificationModule.briefTitle,
      summary: protocolSection.descriptionModule.briefSummary,
      phase: phase,
      studyType: protocolSection.designModule.studyType as StudyType,
      recruitingStatus: protocolSection.statusModule
        .overallStatus as StudyRecruitmentStatus,
      parsedCriteria: {
        inclusions: inclusion,
        exclusions: exclusion
      }
    }

    return {
      id: nctCode,
      source: RemoteTrialSource.NCT,
      ...ctGovResponse
    }
  }

  static isValidNctCode(nctCode: string | undefined): nctCode is string {
    if (!nctCode) return false
    return nctPattern.test(nctCode)
  }

  static isNctRemoteTrial(trial: RemoteTrial): trial is NctRemoteTrial {
    return trial.source === RemoteTrialSource.NCT
  }

  static findLatestTrialForStudy = (
    study: Study,
    source?: RemoteTrialSource
  ): RemoteTrial | undefined => {
    const remoteTrials =
      (source
        ? study.remoteTrials?.filter((rt) => rt.source === source)
        : study.remoteTrials) ?? []

    if (remoteTrials?.length > 0) {
      return remoteTrials[remoteTrials.length - 1]
    }
  }

  private static remoteTrialNotFound(res: Response): boolean {
    // 404 status is ok. It means that we couldn't find a remote
    // trial for that remote trial id
    return res.status === 404
  }

  static updatePhaseFormat(input: string): string {
    if (input.startsWith('EARLY_')) {
      input = input.replace('EARLY_', '')
    }

    if (input === 'NA') {
      return StudyPhase.NOT_AVAILABLE
    }
    const regex = /([A-Z]+)(\d+)/
    const result = input.replace(regex, '$1_$2')

    return result
  }

  static Build() {
    return container.resolve(RemoteTrialService)
  }
}

export const remoteTrialService = RemoteTrialService.Build()
