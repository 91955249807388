/**
 * @description - meant to be paired with the `FeatureFlagClientFactory` for
 * type safe feature flag keys.
 * @example
 * const featureFlagClient = FeatureFlagClientFactory.getClient<FeatureFlagKey>()
 * featureFlagClient.isFlagEnabled(FeatureFlagKey.MY_FEATURE) // works!
 * featureFlagClient.isFlagEnabled('NOT_A_KEY') // type error
 */
export enum FeatureFlagKey {
  COHORT_VECTOR = 'feature-cohort-vector',
  HEAP = 'heap-script',
  PERSIST_USER_STUDY_SETTINGS = 'persist-user-study-settings',
  MULTISITE_SELECT_SITES = 'multisite-select-sites',
  STUDY_DASHBOARD_REDESIGN = 'study-dashboard-redesign',
  SHOW_FEASIBILITY_COUNTS_ABOVE_10K = 'show-feasibility-counts-above-10k',
  USERPILOT_HELP_ICON = 'userpilot-help-icon',
  QUERY_VERSIONING_SPONSOR = 'query-versioning-sponsor',
  USE_NEW_TIMELINE_REPO = 'use-new-timeline-repo',
  DISEASE_PROGRESSION = 'disease-progression',
  PATIENT_WATCHLIST = 'patient-waitlist',
  CODE_SEARCH = 'code-search',
  REPORTING_DASHBOARDS = 'reporting-dashboards',
  RECRUIT_V2 = 'recruit-v2'
}
