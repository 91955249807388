import React, { SyntheticEvent } from 'react'
import { noop } from 'lodash'
import { Link } from 'react-router-dom'

import { useAsyncFunction } from '../../hooks/useAsyncFunction/useAsyncFunction'
import { stopPropagation } from '../../lib/events/stopPropagation'
import { studyReportingService } from '../../services/study-reporting/StudyReportingService'
import { CaretDownIcon } from '../Icon/Icon'
import { Menu, MenuButton, MenuItem, MenuItems, MenuList } from '../Menu/Menu'
import { AsyncStatusView } from '../AsyncStatusView/AsyncStatusView'
import { LoadingSpinner } from '../Loading/LoadingSpinner'

export interface NavDashboardProps {
  getViews?: typeof studyReportingService.getViews
  isReportingEnabled?: boolean
}

export const NavDashboard = ({
  getViews = studyReportingService.getViews,
  isReportingEnabled = false
}: NavDashboardProps) => {
  const [
    getViewsAsync,
    { status: getViewsStatus, error: getViewsError, data: getViewsResponse }
  ] = useAsyncFunction(getViews)

  const handleClick = async (e: SyntheticEvent) => {
    e.stopPropagation()
    await getViewsAsync(isReportingEnabled)
  }

  return (
    <Menu data-testid="NavDashboard">
      <MenuButton
        className="text-grey-77 px-4 hover:text-black whitespace-no-wrap border-none focus:outline-none"
        onClick={handleClick}
      >
        <span className="pr-2">Dashboards</span>
        <CaretDownIcon className="w-3 inline" />
      </MenuButton>
      <MenuList
        id="NavDashboard-MenuList"
        className="border-none focus:outline-none"
        onClick={stopPropagation}
      >
        <MenuItems>
          <AsyncStatusView
            status={getViewsStatus}
            onPendingShow={<LoadingSpinner />}
            errors={[getViewsError]}
            onErrorShow={<div className="p-1">Something went wrong</div>}
            onPristineShowChildren
          >
            {getViewsResponse?.body?.map(({ id, name }) => (
              <Link key={id} to={`/dashboards/${id}`}>
                <MenuItem onSelect={noop}>{name}</MenuItem>
              </Link>
            ))}
          </AsyncStatusView>
        </MenuItems>
      </MenuList>
    </Menu>
  )
}
