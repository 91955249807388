import { container, inject, injectable } from 'tsyringe'
import { Logger, HttpClient, UrlBuilder } from '@deep6ai/common'

import { AccountDisplayName } from '../account/account-service'
import { OrganizationDisplayName } from '../organization/organization.service'
import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as LoggerIoc from '../../lib/logging/Logger.ioc'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import { StudyNotificationView } from '../study/study.service'

export enum StudyNotificationStatus {
  UNREAD = 'UNREAD',
  READ = 'READ'
}

export enum StudyNotificationType {
  STUDY_SHARE = 'STUDY_SHARE',
  NEW_TEAM_MEMBER = 'NEW_TEAM_MEMBER',
  UPDATED_STUDY_COUNTS = 'UPDATED_STUDY_COUNTS'
}

export interface StudyNotification {
  notificationUuid: string
  studyUuid: string
  recipient: AccountDisplayName
  sender: AccountDisplayName
  senderOrganization: OrganizationDisplayName
  type: StudyNotificationType
  status: StudyNotificationStatus
  createdAt: string
  updatedAt: string
}

@injectable()
export class StudyNotificationService {
  private readonly basePath = 'study-notification'

  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private centralApi: HttpClient,
    @inject(LoggerIoc.injectionToken) private logger: Logger,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder
  ) {}

  updateStudyNotificationStatus = (
    studyNotificationUuid: string,
    status: StudyNotificationStatus
  ): Promise<unknown> => {
    const url = this.formatPath(`${studyNotificationUuid}/status`)
    return this.centralApi.put(url, {
      body: JSON.stringify(status)
    })
  }

  clearAllUnread = (
    studyNotifications: Array<StudyNotification | StudyNotificationView>
  ): Promise<unknown[]> => {
    return Promise.all(
      studyNotifications.map((n) =>
        this.updateStudyNotificationStatus(
          n.notificationUuid,
          StudyNotificationStatus.READ
        )
      )
    )
  }

  private formatPath(p = ''): string {
    return this.urlBuilder.joinPath(this.basePath, p)
  }

  static Build() {
    return container.resolve(StudyNotificationService)
  }
}

export const studyNotificationService = StudyNotificationService.Build()
