import { container, inject, injectable } from 'tsyringe'
import type { Logger } from '@deep6ai/common'
import {
  CustomHttpErrorHandler,
  HttpClient,
  HttpOptions,
  HttpVerb,
  isHttpError,
  FetchHttpError,
  StorageClient
} from '@deep6ai/common'

import * as LoggerIoc from '../logging/Logger.ioc'
import * as CookieStorageClientIoc from '../storage/CookieStorageClient.ioc'
import * as InMemoryStorageClientIoc from '../storage/InMemoryStorageClient.ioc'
import { injectionTokens } from '../../config/defaults'

type FetchHttpOptions = RequestInit & CustomHttpErrorHandler

@injectable()
export class FetchHttpClient implements HttpClient {
  static readonly injectionToken = 'FetchHttpClient'

  constructor(
    @inject(LoggerIoc.injectionToken) private logger: Logger,
    @inject(CookieStorageClientIoc.injectionToken)
    private cookieStorageClient: StorageClient,
    @inject(InMemoryStorageClientIoc.injectionToken)
    private inMemoryStorageClient: StorageClient,
    @inject(injectionTokens.loginUriFallback) private loginUriFallback: string
  ) {}

  get(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.GET
    })
  }

  post(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.POST
    })
  }

  put(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.PUT
    })
  }

  patch(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.PATCH
    })
  }

  delete(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.DELETE
    })
  }

  head(url: string, options?: HttpOptions): Promise<Response> {
    return this.request(url, {
      ...options,
      method: HttpVerb.HEAD
    })
  }

  private async request(
    url: string,
    options?: FetchHttpOptions
  ): Promise<Response> {
    try {
      const response = await fetch(url, options)
      return this.defaultErrorHandler(response, options)
    } catch (e) {
      this.logger.error(e)
      throw new FetchHttpError(e)
    }
  }

  private async defaultErrorHandler(
    response: Response,
    options?: FetchHttpOptions
  ) {
    if (!response.ok) {
      if (response.status === 401) {
        const rootDomain = window.location.hostname
          .split('.')
          .slice(-2)
          .join('.')
        this.cookieStorageClient.set('redirectUri', window.location.href, {
          domain: rootDomain
        })
        const loginUri =
          this.inMemoryStorageClient.get('loginUri') ?? this.loginUriFallback
        window.location.assign(loginUri)

        throw new FetchHttpError({
          status: response.status,
          message: response.statusText
        })
      }

      if (options?.okIf && options.okIf(response)) {
        return response
      }

      this.logger.error(response.status, response.statusText)

      const errorJson = await response.json()
      // most of the time our api hands back detailed error messages in a consistent format
      if (isHttpError(errorJson)) {
        throw new FetchHttpError(errorJson)
      }

      // and sometimes things just blow up so we do the best we can
      throw new FetchHttpError({
        status: response.status,
        message: response.statusText
      })
    }
    return response
  }

  static Build(): FetchHttpClient {
    container.register(FetchHttpClient.injectionToken, {
      useClass: FetchHttpClient
    })
    return container.resolve(FetchHttpClient)
  }
}

export const fetchHttpClient = FetchHttpClient.Build()
