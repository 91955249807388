import { injectable } from 'tsyringe'

import {
  AllowedConsultScheduledRecruitmentStatuses,
  ConsultScheduledMetadata,
  PISelection,
  PISelectionType,
  Recruitment,
  UnbucketedPISelection
} from '../Recruitment'
import { IncomingRecruitmentBucketItemMetadata } from './recruitment-bucket-mapper'
import { AccountDisplayName } from '../../account/account-service'
import { ReducerCB, BucketMapper } from './bucket-mapper'
import { ValidatedMapper } from './validated-mapper'
import { ReferredMapper } from './referred-mapper'

@injectable()
export class ConsultationScheduledMapper
  implements BucketMapper<ConsultScheduledMetadata>
{
  constructor(
    private validatedMapper: ValidatedMapper,
    private referredMapper: ReferredMapper
  ) {}

  buildMapper(
    principleInvestigators: AccountDisplayName[]
  ): ReducerCB<ConsultScheduledMetadata> {
    return (aggregate, current) => {
      return this.bucketMapper(
        aggregate,
        current,
        this.transform,
        principleInvestigators
      )
    }
  }

  private bucketMapper = (
    aggregate: Recruitment<ConsultScheduledMetadata>[],
    current: Recruitment<IncomingRecruitmentBucketItemMetadata>,
    transformer: (
      metadata: IncomingRecruitmentBucketItemMetadata,
      principleInvestigators: AccountDisplayName[]
    ) => ConsultScheduledMetadata,
    principleInvestigators: AccountDisplayName[]
  ): Recruitment<ConsultScheduledMetadata>[] => {
    return [
      ...aggregate,
      {
        ...current,
        metadata: transformer(current.metadata, principleInvestigators)
      }
    ]
  }

  transform = (
    metadata: IncomingRecruitmentBucketItemMetadata,
    principleInvestigators: AccountDisplayName[]
  ): ConsultScheduledMetadata => {
    const { statusMetadata: validationMetadata } =
      this.validatedMapper.transform(metadata)

    const { statusMetadata: referralMetadata } =
      this.referredMapper.transform(metadata)

    return {
      currentStatus:
        metadata.currentStatus as AllowedConsultScheduledRecruitmentStatuses,
      statusMetadata: {
        scheduledAt:
          metadata.statusMetadata.consultScheduledMetadata?.consultationDate,
        principleInvestigator:
          this.piSelectionToRecruitmentPrincipleInvestigator(
            metadata.statusMetadata?.consultScheduledMetadata
              ?.principleInvestigator,
            principleInvestigators
          ),
        validationMetadata,
        referralMetadata
      }
    }
  }

  private piSelectionToRecruitmentPrincipleInvestigator(
    unBucketedPISelection: UnbucketedPISelection | undefined,
    principleInvestigators: AccountDisplayName[]
  ): PISelection | undefined {
    if (!unBucketedPISelection) return

    switch (unBucketedPISelection['@type']) {
      case 'accountNotFound':
        return {
          type: PISelectionType.NOT_FOUND,
          displayName: 'Other'
        }
      case 'accountReference': {
        const accountReference = principleInvestigators.find(
          (account) => account.accountUuid === unBucketedPISelection.userUuid
        )

        return {
          type: PISelectionType.ACCOUNT,
          accountUuid: accountReference!.accountUuid,
          displayName: accountReference!.displayName
        }
      }
    }
  }
}
