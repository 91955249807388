import { injectable, inject, container } from 'tsyringe'

import {
  TermListService,
  TermListSummary,
  TermList,
  TermConcept
} from '../../services/term-list/TermListService'
import { ReduxAsyncNotifier } from '../../lib/async-notifier/redux-async-notifier'
import type { AsyncNotifier } from '../../lib/async-notifier/async-notifier'
import { AbstractAction } from '../abstract-action'
import { PaginatedResponse } from '../../lib/http/paginated-response'

export enum TermListActionTypes {
  addTermLists = 'TERM_LIST::ADD_TERM_LISTS',
  clearTermLists = 'TERM_LIST::CLEAR_TERM_LISTS',
  addActiveTermList = 'TERM_LIST::ADD_ACTIVE_TERM_LIST',
  clearActiveTermList = 'TERM_LIST::CLEAR_ACTIVE_TERM_LIST',
  updateTermConcepts = 'TERM_LIST::UPDATE_TERM_CONCEPTS',
  clearTermConcepts = 'TERM_LIST::CLEAR_TERM_CONCEPTS'
}

@injectable()
export class TermListActions {
  constructor(
    private termListService: TermListService,
    @inject(ReduxAsyncNotifier.injectionToken)
    private asyncNotifier: AsyncNotifier
  ) {}

  addTermLists(
    termListSummaries: PaginatedResponse<TermListSummary[]>
  ): AbstractAction<TermListActionTypes, PaginatedResponse<TermListSummary[]>> {
    return {
      type: TermListActionTypes.addTermLists,
      payload: termListSummaries
    }
  }

  clearTermLists(): AbstractAction<TermListActionTypes> {
    return { type: TermListActionTypes.clearTermLists }
  }

  addActiveTermList(
    termList: TermList
  ): AbstractAction<TermListActionTypes, TermList> {
    return { type: TermListActionTypes.addActiveTermList, payload: termList }
  }

  clearActiveTermList(): AbstractAction<TermListActionTypes> {
    return { type: TermListActionTypes.clearActiveTermList }
  }

  updateTermConcepts(
    termConcepts: TermConcept[]
  ): AbstractAction<TermListActionTypes, TermConcept[]> {
    return {
      type: TermListActionTypes.updateTermConcepts,
      payload: termConcepts
    }
  }

  clearTermConcepts(): AbstractAction<TermListActionTypes> {
    return { type: TermListActionTypes.clearTermConcepts }
  }

  /* async actions */

  getTermLists = this.asyncNotifier.subscribe(
    (search?: string) => async (dispatch) => {
      dispatch(
        this.addTermLists(await this.termListService.findTermLists(search))
      )
    }
  )

  getTermListAndAddActive = this.asyncNotifier.subscribe(
    (termListUuid: string) => async (dispatch) => {
      dispatch(
        this.addActiveTermList(
          await this.termListService.findTermListByUuid(termListUuid)
        )
      )
    }
  )

  getTermConceptsByTermListUuid = this.asyncNotifier.subscribe(
    (termListUuid: string) => async (dispatch) => {
      dispatch(
        this.updateTermConcepts(
          await this.termListService.findTermConceptsByTermListUuid(
            termListUuid
          )
        )
      )
    }
  )

  removeTermConcepts = this.asyncNotifier.subscribe(
    (termListUuid: string, terms: TermConcept[]) => async (dispatch) => {
      const termCuis = terms.map((it) => it.cui)
      await this.termListService.removeTermConcepts(termListUuid, termCuis)
      dispatch(
        this.updateTermConcepts(
          await this.termListService.findTermConceptsByTermListUuid(
            termListUuid
          )
        )
      )
      dispatch(this.getTermListAndAddActive(termListUuid))
    }
  )

  static Build() {
    return container.resolve(TermListActions)
  }
}

export const termListActions = TermListActions.Build()
