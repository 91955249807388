import React, { useEffect } from 'react'
import { useInterval } from 'react-use'

import { sessionService } from '../../services/session/SessionService'
import { SessionTimeoutModal } from '../Modal/SessionTimeout/SessionTimeoutModal'
import { useModalState } from '../../hooks/useModalState'
import { authenticationService } from '../../services/authentication/authentication-service'

const TIMER_INTERVAL_MS = 1000

interface SessionTimeoutModalTimerProps {
  shouldNotifyUserOfInactivity?: () => boolean
  logOut?: typeof authenticationService.logOut
  refresh?: typeof authenticationService.refresh
  setLastUserActivityTime?: typeof sessionService.setLastUserActivityTime
  shouldAutoRefreshToken?: typeof sessionService.shouldAutoRefreshToken
}

export function SessionTimeoutModalTimer({
  shouldNotifyUserOfInactivity = sessionService.shouldNotifyUserOfInactivity,
  logOut = authenticationService.logOut,
  refresh = authenticationService.refresh,
  setLastUserActivityTime = sessionService.setLastUserActivityTime,
  shouldAutoRefreshToken = sessionService.shouldAutoRefreshToken
}: SessionTimeoutModalTimerProps) {
  useEffect(() => {
    document.addEventListener('click', setLastUserActivityTime)
    return () => {
      document.removeEventListener('click', setLastUserActivityTime)
    }
  }, [setLastUserActivityTime])

  const [
    isSessionTimeoutModalOpen,
    openSessionTimeoutModal,
    closeSessionTimeoutModal
  ] = useModalState()

  async function checkShouldNotifyUserOfInactivity() {
    if (shouldNotifyUserOfInactivity()) {
      // if last user interaction was less than 15 min ago, refresh the token
      // else open the session timeout modal
      if (shouldAutoRefreshToken()) {
        await refresh()
      } else {
        openSessionTimeoutModal()
      }
    }
  }

  function handleLogOut() {
    logOut()
  }

  async function handleStaySignedIn() {
    await refresh()
    closeSessionTimeoutModal()
  }

  useInterval(checkShouldNotifyUserOfInactivity, TIMER_INTERVAL_MS)

  return (
    <SessionTimeoutModal
      isOpen={isSessionTimeoutModalOpen}
      onStaySignedIn={handleStaySignedIn}
      onLogOutNow={handleLogOut}
    />
  )
}
