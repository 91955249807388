import { container, inject, injectable } from 'tsyringe'
import { UrlBuilder } from '@deep6ai/common'

import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'

export enum RedirectActionTypes {
  Push = 'REDIRECT::PUSH',
  Pop = 'REDIRECT::POP'
}

@injectable()
export class RedirectActions {
  constructor(
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder
  ) {}

  goTo = (path: string, params?: any) => {
    const url = this.urlBuilder.build(path, params)
    return {
      type: RedirectActionTypes.Push,
      payload: url
    }
  }

  clear = () => ({ type: RedirectActionTypes.Pop })

  static Build() {
    return container.resolve(RedirectActions)
  }
}

export const redirectActions = RedirectActions.Build()
