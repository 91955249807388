export interface PaginatedResponse<T extends any[] = any[], M = any> {
  content: T
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: {
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    sort: {
      sorted: boolean
      unsorted: boolean
      empty: boolean
    }
    unpaged: boolean
  }
  size: number
  sort: {
    sorted: boolean
    unsorted: boolean
    empty: boolean
  }
  totalElements: number
  totalPages: number
  metadata?: M
}

export const DEFAULT_PAGE_SIZE = 20

export const emptyPaginatedContent = (content: any[] = []) => ({
  content,
  empty: true,
  first: false,
  last: false,
  number: 0,
  numberOfElements: 0,
  pageable: {
    offset: 0,
    pageNumber: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    paged: false,
    sort: {
      sorted: false,
      unsorted: false,
      empty: false
    },
    unpaged: true
  },
  size: 0,
  sort: {
    sorted: false,
    unsorted: false,
    empty: false
  },
  totalElements: 0,
  totalPages: 0
})
