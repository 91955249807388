import { AbstractAction } from '../abstract-action'
import { RedirectActionTypes } from './redirect.actions'

export interface RedirectReducerSlice {
  location: string | null
}

export const initialState: RedirectReducerSlice = {
  location: null
}

export default function redirectReducer(
  state = initialState,
  action: AbstractAction<RedirectActionTypes>
) {
  switch (action.type) {
    case RedirectActionTypes.Push:
      return {
        ...state,
        location: action.payload
      }
    case RedirectActionTypes.Pop:
      return {
        ...state,
        location: initialState.location
      }
    default:
      return state
  }
}
