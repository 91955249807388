import React, { ReactNode } from 'react'

import { QuestionMarkCircleIcon } from '../Icon/Icon'
import { ToolTip } from './ToolTip'

interface HelpLinkProps extends React.HTMLAttributes<HTMLSpanElement> {
  helpText: ReactNode
}

export const HelpToolTip = ({ helpText, id, ...rest }: HelpLinkProps) => (
  <>
    <QuestionMarkCircleIcon data-tip data-for={`tip-${id}`} {...rest} />
    <ToolTip wrapper="span" clickable={true} multiline={true} id={`tip-${id}`}>
      {helpText}
    </ToolTip>
  </>
)
