import { AbstractAction } from '../abstract-action'
import { TermListActionTypes } from './term-list.actions'
import {
  emptyPaginatedContent,
  PaginatedResponse
} from '../../lib/http/paginated-response'
import {
  TermConcept,
  TermList,
  TermListSummary
} from '../../services/term-list/TermListService'

interface TermListReducerSlice {
  termListSummaries: PaginatedResponse<TermListSummary[]>
  activeTermList: TermList | null
  termConcepts: TermConcept[] | null
}

export const initialState: TermListReducerSlice = {
  termListSummaries: emptyPaginatedContent(),
  activeTermList: null,
  termConcepts: null
}

export default function termListReducer(
  state: TermListReducerSlice = initialState,
  action: AbstractAction<TermListActionTypes>
) {
  switch (action.type) {
    case TermListActionTypes.addTermLists:
      return {
        ...state,
        termListSummaries: action.payload
      }
    case TermListActionTypes.clearTermLists:
      return {
        ...state,
        termListSummaries: emptyPaginatedContent()
      }
    case TermListActionTypes.addActiveTermList:
      return {
        ...state,
        activeTermList: action.payload
      }
    case TermListActionTypes.clearActiveTermList:
      return {
        ...state,
        activeTermList: null
      }
    case TermListActionTypes.updateTermConcepts:
      return {
        ...state,
        termConcepts: action.payload
      }
    case TermListActionTypes.clearTermConcepts:
      return {
        ...state,
        termConcepts: null
      }
    default:
      return state
  }
}
