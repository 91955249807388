import React from 'react'
import { Link } from 'react-router-dom'

import { D6Logo } from '../Logo/D6Logo'

export const NavLogo = () => (
  <Link to="/" className="block p-0 flex-shrink-0" data-testid="NavLogo">
    <D6Logo className="h-6" />
  </Link>
)
