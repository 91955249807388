import { AbstractAction } from '../abstract-action'
import { RemoteTrialActionTypes } from './remote-trial.actions'
import { NctRemoteTrial } from '../../services/remote-trial/remote-trial-service'

const initialState = {}

export interface RemoteTrialState {
  [key: string]: NctRemoteTrial
}

export default function remoteTrialReducer(
  state = initialState,
  action: AbstractAction<RemoteTrialActionTypes, NctRemoteTrial>
) {
  switch (action.type) {
    case RemoteTrialActionTypes.add:
      if (!action?.payload) {
        throw Error('remoteTrialReducer error. Missing payload')
      }
      return {
        ...state,
        [action.payload.id]: action.payload
      }
    default:
      return state
  }
}
