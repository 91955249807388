import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Logger, LoggerFactory } from '@deep6ai/common'
import { Userpilot as UserpilotSdk } from 'userpilot'
import { useLocation } from 'react-router-dom'

import {
  User,
  UserClaims
} from '../../../services/authentication/authentication-service'
import { sessionService } from '../../../services/session/SessionService'
import {
  capabilitiesService,
  UserCapabilitiesSummary
} from '../../../services/capabilities/CapabilitiesService'

interface UserPilotProps {
  apiKey: string
  activeUser: User
  logger?: Logger
  getUserCapabilities?: typeof capabilitiesService.getCapabilities
  getUserClaims?: typeof sessionService.getClaims
}

function transformUserPermissions(
  permissions: UserClaims | UserCapabilitiesSummary
) {
  const transformedPermissions = {}

  Object.entries(permissions).forEach(([key, value]) => {
    transformedPermissions[`Permission ${key}`] = value
  })

  return transformedPermissions
}

export const _Userpilot = ({
  apiKey,
  activeUser,
  logger = LoggerFactory.build(),
  getUserCapabilities = capabilitiesService.getCapabilities,
  getUserClaims = sessionService.getClaims
}: UserPilotProps) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const userClaims = getUserClaims()
  const userCapabilities = getUserCapabilities()

  const location = useLocation()

  useEffect(() => {
    UserpilotSdk.reload()
  }, [location])

  useEffect(() => {
    UserpilotSdk.initialize(apiKey)
  }, [apiKey])

  useEffect(() => {
    const hasRequiredData = !!(userClaims && userCapabilities)

    // The !userClaims check is logically redundant, but necessary to avoid
    // spurious type errors later in this function.
    if (!hasRequiredData || !userClaims) {
      logger.error('Userpilot is missing required data')
      return
    }

    if (isInitialized) return

    const { accountUuid, displayName, organization } = activeUser

    UserpilotSdk.identify(accountUuid, {
      user_uuid: accountUuid,
      user_fullname: displayName,
      account_UUID: organization.uuid,
      account_name: organization.displayName,
      account_type: organization.type,
      ...transformUserPermissions(userClaims),
      company: {
        id: organization.uuid,
        name: organization.displayName,
        type: organization.type,
        ...transformUserPermissions(userCapabilities)
      }
    })

    setIsInitialized(true)
  }, [activeUser, logger, isInitialized, userClaims, userCapabilities])

  return <></>
}

const mapStateToProps = ({ authentication }) => ({
  activeUser: authentication.activeUser
})

export const Userpilot = connect(mapStateToProps)(_Userpilot)
