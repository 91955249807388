import React from 'react'
import { Button, ButtonSecondary } from '@deep6ai/component-library'

export interface ConfirmButtonsProps {
  onCancel: () => void
  onConfirm: () => void
  cancelButtonText?: string
  confirmButtonText?: string
  confirmButtonIsDisabled?: boolean
}

export const ConfirmButtons = ({
  onCancel,
  onConfirm,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  confirmButtonIsDisabled = false
}: ConfirmButtonsProps) => (
  <div className="flex justify-between">
    <ButtonSecondary onClick={onCancel}>{cancelButtonText}</ButtonSecondary>
    <Button onClick={onConfirm} disabled={confirmButtonIsDisabled}>
      {confirmButtonText}
    </Button>
  </div>
)
