import { container, inject, injectable } from 'tsyringe'

import {
  RemoteTrialService,
  NctRemoteTrial
} from '../../services/remote-trial/remote-trial-service'
import { ReduxAsyncNotifier } from '../../lib/async-notifier/redux-async-notifier'
import type { AsyncNotifier } from '../../lib/async-notifier/async-notifier'
import { AbstractAction } from '../abstract-action'

export enum RemoteTrialActionTypes {
  add = 'REMOTE_TRIAL::ADD'
}

@injectable()
export class RemoteTrialActions {
  constructor(
    private remoteTrialService: RemoteTrialService,

    @inject(ReduxAsyncNotifier.injectionToken)
    private asyncNotifier: AsyncNotifier
  ) {}

  addRemoteTrial(
    remoteTrial: NctRemoteTrial
  ): AbstractAction<RemoteTrialActionTypes, NctRemoteTrial> {
    return { type: RemoteTrialActionTypes.add, payload: remoteTrial }
  }

  findByNct = this.asyncNotifier.subscribe(
    (nctCode: string) => async (dispatch) => {
      const remoteTrial: NctRemoteTrial | null =
        await this.remoteTrialService.findByNctCode(nctCode)
      if (remoteTrial) {
        dispatch(this.addRemoteTrial(remoteTrial))
      }
    }
  )

  static Build() {
    return container.resolve(RemoteTrialActions)
  }
}

export const remoteTrialActions = RemoteTrialActions.Build()
