import React from 'react'

import { User } from '../../services/authentication/authentication-service'
import {
  Menu,
  MenuButton,
  MenuListRight,
  MenuItems,
  MenuItem
} from '../Menu/Menu'
import { stopPropagation } from '../../lib/events/stopPropagation'
import { CaretDownIcon } from '../Icon/Icon'

interface NavUserProps {
  user: User
  onLogOut: () => void
}

export const NavUser = ({ user, onLogOut }: NavUserProps) => {
  const name = `${user.displayName}@${user.organization.displayName}`

  return (
    <div data-testid="NavUser">
      <Menu>
        <MenuButton
          className="text-grey-77 hover:text-black whitespace-no-wrap"
          onClick={stopPropagation}
        >
          {name} <CaretDownIcon className="w-3 inline" />
        </MenuButton>
        <MenuListRight id="NavUser-MenuList" onClick={stopPropagation}>
          <MenuItems>
            <MenuItem onSelect={onLogOut}>Log Out</MenuItem>
          </MenuItems>
        </MenuListRight>
      </Menu>
    </div>
  )
}
