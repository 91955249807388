import { useState } from 'react'
import { UrlBuilder, UrlBuilderFactory, ParsedQuery } from '@deep6ai/common'

import { WidgetApi, WidgetPublishEvent } from '../../components/Widgets/Widget'
import { PPPConfig } from '../../services/configuration/configuration.service'
import { TrialRecommenderService } from '../../services/trial-recommender/TrialRecommenderService'

type WidgetUrlProps = { pathname: string; searchParams?: ParsedQuery }
/**
 * For use in components that use `TrialRecommender`.
 * Currently handles iframe route manipulation.
 *
 * @returns An object with route manipulation functions.
 */
export function useTrialRecommender(
  config: PPPConfig,
  urlBuilder: UrlBuilder = UrlBuilderFactory.build(),
  trialRecommenderService: TrialRecommenderService = TrialRecommenderService.build()
) {
  const [widgetApi, setWidgetApi] = useState<WidgetApi>()
  const [startingUri, setStartingUriStr] = useState<string>()

  function setStartingUri(props: WidgetUrlProps) {
    const uri = buildWidgetUrl(props)
    setStartingUriStr(uri)
  }

  function buildWidgetUrl({
    pathname = '',
    searchParams = {}
  }: WidgetUrlProps) {
    const path = `${config.trialRecommenderUri}${pathname}`

    return urlBuilder.build(path, {
      ...searchParams
    })
  }

  function triggerWidgetRedirect(props: WidgetUrlProps) {
    const { pathname, searchParams } = props
    const newURL = urlBuilder.build(pathname, {
      ...searchParams
    })

    widgetApi?.publish(WidgetPublishEvent.ROUTE_CHANGE, {
      pathname: newURL
    })
  }

  return {
    startingUri,
    setStartingUri,
    widgetApi,
    setWidgetApi,
    triggerWidgetRedirect
  }
}
