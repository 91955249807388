import { container, inject, injectable } from 'tsyringe'
import { Logger, JsonHttpClient, HttpClient, UrlBuilder } from '@deep6ai/common'

import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import { PaginatedResponse } from '../../lib/http/paginated-response'
import {
  RemoteTrial,
  RemoteTrialSource
} from '../remote-trial/remote-trial-service'
import { Account, AccountDisplayName } from '../account/account-service'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import * as LoggerIoc from '../../lib/logging/Logger.ioc'
import {
  Organization,
  DepartmentType
} from '../organization/organization.service'
import { StudyNotificationType } from '../study-notification/study-notification-service'
import { MatchType } from '../patient/models/StudyMembershipFilters'
import { User } from '../authentication/authentication-service'
import { Referral } from '../referral/ReferralService'
import { Attachment } from './StudyAttachmentService'
import { AttachmentFormValue } from '../../components/Form/StudyForm/Controls/AttachmentsFormControl/AttachmentsFormControl'

export interface StudyHealthSystem {
  organizationUuid: string
  irbNumber?: string
  department?: DepartmentType
}

export interface Study {
  studyUuid: string
  title: string
  state: string
  type: StudyType
  allowDeceasedPatients: boolean
  phase?: StudyPhase
  purpose: StudyPurpose
  sponsorType: StudySponsorType
  recruitment: StudyRecruitmentStatus
  recruitmentGoal?: number
  studyOrganizationTeam?: StudyOrganizationTeam[]
  remoteTrials?: RemoteTrial[]
  isEditable: boolean
  createdAt: string
  createdBy: Account
  lastModifiedAt: string
  lastModifiedBy: Account
  studyHealthSystems: StudyHealthSystem[]
  attachments: Attachment[]
  departmentType?: string
}

export type MatchedStudy = {
  study: Study
  matchType: MatchType
  reviewStatus: ReviewStatus
  referrals: Referral[]
}

/**
 * @description - Patient review status.
 *
 * NOTE: Enum members and their constants must match and remain uppercase.
 */
export enum ReviewStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  UNREVIEWED = 'UNREVIEWED',
  WATCHLISTED = 'WATCHLISTED'
}

export type CreatedStudy = Pick<Study, 'studyUuid'>

type DuplicatedStudy = Pick<Study, 'createdAt' | 'title' | 'studyUuid'> & {
  createdByUuid: string
  protocolUuid: string
  studyState: string
  studyType: string
  updatedAt: string
}

export type StudySummary = Pick<
  Study,
  | 'studyUuid'
  | 'title'
  | 'recruitmentGoal'
  | 'isEditable'
  | 'lastModifiedAt'
  | 'createdAt'
> & {
  notifications: StudyNotificationView[]
  counts?: Omit<StudyCount, 'state'>
  createdBy: AccountDisplayName
  lastModifiedBy: AccountDisplayName
}

export type StudyFormValue = StudyType | StudyRecruitmentStatus | StudyPhase

export interface StudyNotificationView {
  notificationUuid: string
  type: StudyNotificationType
  organizationDisplayName: string
  createdAt: string
}

/**
 * @name StudyRunCounts
 * @description StudyRunCountsView represents the counts from the _latest_ run
 * and includes validating and recruiting counts. It is a study level concern
 * and is meant to provide the user with an "inbox" like experience.
 */
export interface StudyRunCounts {
  runUuid: string
  studyUuid: string
  isComplete: boolean
  // TODO: rename this on the backend. Sites means something very
  //  specific in our domain and this is not it
  sites: OrganizationStudyCounts
}

interface OrganizationStudyCounts {
  potential: OrganizationStudyCount[]
  recruiting: OrganizationStudyCount[]
}

export interface OrganizationStudyCount {
  organization: Organization
  count: StudyCount
}

export interface StudyCount {
  state: CountState
  matched: number
  partial: number
  excluded: number
  approved: number
  rejected: number
  validated: number
  referred: number
  consultScheduled: number
  inScreening: number
  enrolled: number
  ineligible: number
  watchlisted?: number
}

// using this for studyMembershipService.getPaginationStatus responses to ensure parity with StudyCount interface
export const StudyCountNameMap = {
  accepted: 'approved',
  excludedMatches: 'excluded',
  fullMatches: 'matched',
  partialMatches: 'partial',
  rejected: 'rejected',
  watchlisted: 'watchlisted'
}

export enum CountState {
  COMPLETE = 'COMPLETE',
  CACHING = 'CACHING',
  PENDING = 'PENDING',
  FAILED = 'FAILED'
}

export enum StudyType {
  INTERVENTIONAL = 'INTERVENTIONAL',
  OBSERVATIONAL = 'OBSERVATIONAL',
  PATIENT_REGISTRIES = 'PATIENT_REGISTRIES',
  EXPANDED_ACCESS = 'EXPANDED_ACCESS'
}

export enum StudySponsorType {
  GOVERNMENT = 'GOVERNMENT',
  INDUSTRY = 'INDUSTRY',
  INVESTIGATOR_INITIATED = 'INVESTIGATOR_INITIATED',
  NOT_SPECIFIED = 'NOT_SPECIFIED'
}

export enum StudyPhase {
  PHASE_1 = 'PHASE_1',
  PHASE_2 = 'PHASE_2',
  PHASE_3 = 'PHASE_3',
  PHASE_4 = 'PHASE_4',
  NOT_AVAILABLE = 'NOT_AVAILABLE'
}

export const allPhases = Object.values(StudyPhase)

export enum StudyRecruitmentStatus {
  NOT_YET_RECRUITING = 'NOT_YET_RECRUITING',
  RECRUITING = 'RECRUITING',
  ACTIVE_NOT_RECRUITING = 'ACTIVE_NOT_RECRUITING',
  SUSPENDED = 'SUSPENDED',
  TERMINATED = 'TERMINATED',
  COMPLETED = 'COMPLETED',
  WITHDRAWN = 'WITHDRAWN',
  NOT_AVAILABLE = 'N/A'
}

export interface TeamMember {
  accountUuid: string
  displayName?: string
  role: StudyTeamRole
}

export interface StudyOrganizationTeam {
  organizationUuid: string
  studyTeam: TeamMember[]
}

export enum StudyTeamRole {
  MEMBER = 'MEMBER',
  PRINCIPAL_INVESTIGATOR = 'PRINCIPAL_INVESTIGATOR'
}

export enum StudyPurpose {
  RESEARCH = 'RESEARCH',
  OPERATIONS = 'OPERATIONS'
}

export enum StudyFilter {
  ALL = 'ALL',
  VALIDATED = 'VALIDATED'
}

export type NewStudy = Pick<
  Study,
  'title' | 'phase' | 'recruitment' | 'recruitmentGoal' | 'remoteTrials'
> & {
  studyPurpose: string
  studyType: string
  allowDeceasedPatients: boolean
  studySponsorType: string
  studyTeam: TeamMember[]
  irbNumber?: string
  published: boolean
  attachments: AttachmentFormValue[]
  departmentType?: string
}

export type UpdatedStudy = Pick<
  Study,
  | 'studyUuid'
  | 'title'
  | 'phase'
  | 'recruitment'
  | 'recruitmentGoal'
  | 'remoteTrials'
> & {
  studyType: string
  studySponsorType: string
  studyTeam: TeamMember[]
  irbNumber?: string
  published: boolean
  attachments: (Attachment | AttachmentFormValue)[]
  departmentType?: string
}

export type CreateStudyBody = Pick<Study, 'title'> & {
  studyPurpose: string
  studyType: string
  allowDeceasedPatients: boolean
  studySponsorType: string
  published: boolean
}

export type UpdateStudyBody = Pick<Study, 'title'> & {
  studyType: string
  studySponsorType: string
  published: boolean
}

export interface DuplicateStudyBody {
  sourceStudyUuid: string
  study: CreateStudyBody
}

export interface StudyShare {
  shareUuid: string
  studyUuid: string
  healthSystemUuid: string
  recipient: AccountDisplayName
  sender: AccountDisplayName
  shareDate: string
}

interface FilterOptions {
  search?: string
}

@injectable()
export class StudyService {
  private basePath = '/study'

  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private centralApi: JsonHttpClient & HttpClient,
    @inject(LoggerIoc.injectionToken) private logger: Logger,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder
  ) {}

  create = (createStudyBody: CreateStudyBody): Promise<CreatedStudy> => {
    return this.centralApi.postJson(this.formatPath(), {
      body: JSON.stringify(createStudyBody)
    })
  }

  update = (
    studyUuid: string,
    upsertStudyBody: UpdateStudyBody
  ): Promise<Study> => {
    return this.centralApi.putJson(this.formatPath(studyUuid), {
      body: JSON.stringify(upsertStudyBody)
    })
  }

  list = ({ search }: FilterOptions = {}): Promise<
    PaginatedResponse<StudySummary[]>
  > => {
    return this.centralApi.getJson(
      this.urlBuilder.build(this.formatPath(), {
        search
      })
    )
  }

  duplicate = (
    duplicateStudyBody: DuplicateStudyBody
  ): Promise<DuplicatedStudy> => {
    return this.centralApi.postJson(this.formatPath('/duplicate'), {
      body: JSON.stringify(duplicateStudyBody)
    })
  }

  findByUuid = async (studyUuid: string): Promise<Study> => {
    const study = await this.centralApi.getJson(this.formatPath(studyUuid))
    const formattedStudy = {
      ...study,
      recruitment: study.recruitment || StudyRecruitmentStatus.NOT_AVAILABLE,
      studyHealthSystems: study.studyHealthSystems || []
    }
    // if phase is not present, conditionally add N/A to options
    if (!study.phase) {
      const phaseOptions = await this.getPhaseValuesByStudyType(study.type)
      return {
        ...formattedStudy,
        phase: phaseOptions.length ? StudyPhase.NOT_AVAILABLE : null
      }
    }
    return formattedStudy
  }

  getStudyTypes = (): Promise<StudyType[]> => {
    return this.centralApi.getJson(this.formatPath('/study-type'))
  }

  getRecruitmentStatusValues = (): Promise<StudyRecruitmentStatus[]> => {
    return this.centralApi.getJson(this.formatPath('/recruitment-value'))
  }

  getPhaseValuesByStudyType = (studyType: StudyType) => {
    return this.centralApi.getJson(this.formatPath(`/${studyType}/phase-value`))
  }

  getStudyRunCounts = async (studyUuid: string): Promise<StudyRunCounts> => {
    return this.centralApi.getJson(
      this.formatPath(`/${studyUuid}/study-counts`),
      {
        okIf: (res) => res.status === 404
      }
    )
  }

  upsertRemoteTrial = (
    studyUuid: string,
    remoteTrial: RemoteTrial
  ): Promise<Study> => {
    return this.centralApi.postJson(
      this.formatPath(`/${studyUuid}/remote-trial`),
      {
        body: JSON.stringify(remoteTrial)
      }
    )
  }

  deleteRemoteTrial = (
    studyUuid: string,
    source: RemoteTrialSource
  ): Promise<Response> => {
    const url = this.urlBuilder.build(
      this.formatPath(`/${studyUuid}/remote-trial`),
      { source: RemoteTrialSource.NCT }
    )
    return this.centralApi.delete(url, {
      okIf: (res) => res.status === 404
    })
  }

  upsertStudyRecruitment = (
    studyUuid: string,
    recruitmentStatus: StudyRecruitmentStatus
  ): Promise<Response> => {
    const url = this.urlBuilder.build(
      this.formatPath(`/${studyUuid}/recruitment`),
      {
        upsertRecruitmentValue: recruitmentStatus
      }
    )
    return this.centralApi.postJson(url)
  }

  deleteStudyRecruitment = (studyUuid: string): Promise<Response> => {
    return this.centralApi.delete(
      this.formatPath(`/${studyUuid}/recruitment`),
      {
        okIf: (res) => res.status === 404
      }
    )
  }

  upsertStudyDepartment = (
    studyUuid: string,
    departmentUuid: string
  ): Promise<Response> => {
    const url = this.urlBuilder.build(
      this.formatPath(`/${studyUuid}/department/${departmentUuid}`)
    )
    return this.centralApi.postJson(url)
  }

  deleteStudyDepartment = (studyUuid: string): Promise<Response> => {
    return this.centralApi.delete(this.formatPath(`/${studyUuid}/department`), {
      okIf: (res) => res.status === 404
    })
  }

  upsertStudyRecruitmentGoal = (
    studyUuid: string,
    recruitmentGoal: number
  ): Promise<Response> => {
    const url = this.urlBuilder.build(
      this.formatPath(`/${studyUuid}/recruitment-goal`),
      {
        upsertRecruitmentGoalValue: recruitmentGoal
      }
    )
    return this.centralApi.postJson(url)
  }

  deleteStudyRecruitmentGoal = (studyUuid: string): Promise<Response> => {
    return this.centralApi.delete(
      this.formatPath(`/${studyUuid}/recruitment-goal`),
      {
        okIf: (res) => res.status === 404
      }
    )
  }

  upsertStudyPhase = (
    studyUuid: string,
    studyPhase: StudyPhase
  ): Promise<Response> => {
    const url = this.urlBuilder.build(this.formatPath(`/${studyUuid}/phase`), {
      upsertPhaseValue: studyPhase
    })
    return this.centralApi.postJson(url)
  }

  deleteStudyPhase = (studyUuid: string): Promise<Response> => {
    return this.centralApi.delete(this.formatPath(`/${studyUuid}/phase`), {
      // 404 status is ok. It just couldn't find a phase to delete.
      okIf: (res) => res.status === 404
    })
  }

  upsertTeamMembers = (
    studyUuid: string,
    teamMembers: TeamMember[]
  ): Promise<Response> => {
    return this.centralApi.putJson(this.formatPath(`/${studyUuid}/team`), {
      body: JSON.stringify(teamMembers)
    })
  }

  archive = (studyUuid: string): Promise<Response> => {
    return this.centralApi.delete(this.formatPath(studyUuid))
  }

  findAllStudyShareByStudyUuid = (studyUuid: string): Promise<StudyShare[]> => {
    return this.centralApi.getJson(this.formatPath(`/${studyUuid}/share`))
  }

  createStudyShare = (
    studyUuid: string,
    accountUuid: string
  ): Promise<StudyShare> => {
    return this.centralApi.postJson(this.formatPath(`/${studyUuid}/share`), {
      body: JSON.stringify(accountUuid)
    })
  }

  deleteStudyShare = (shareUuid: string): Promise<Response> => {
    return this.centralApi.delete(this.formatPath(`/share/${shareUuid}`))
  }

  upsertIrbNumber = (
    studyUuid: string,
    irbNumber: string
  ): Promise<Response> => {
    return this.centralApi.postJson(this.formatPath(`/${studyUuid}/irb`), {
      body: JSON.stringify({ irb: irbNumber })
    })
  }

  deleteIrbNumber = (studyUuid: string): Promise<Response> => {
    return this.centralApi.delete(this.formatPath(`/${studyUuid}/irb`), {
      // 404 status is ok. It just couldn't find an irb to delete.
      okIf: (res) => res.status === 404
    })
  }

  static userIsOnStudyTeam = (user: User, study: Study): boolean => {
    const studyOrganizationTeam = study.studyOrganizationTeam?.find(
      (t) => t.organizationUuid === user.organization.uuid
    )
    const studyTeam = studyOrganizationTeam?.studyTeam ?? []
    const accountUuids = studyTeam.map((t) => t.accountUuid)

    const isOnStudyTeam = accountUuids.includes(user.accountUuid)
    const isCreator = user.accountUuid === study.createdBy.uuid

    return isOnStudyTeam || isCreator
  }

  static userIsStudyCreator = (user: User, study: Study | StudySummary) => {
    if (isStudySummary(study)) {
      return user.accountUuid === study.createdBy.accountUuid
    }

    return user.accountUuid === study.createdBy.uuid
  }

  static getStudyHealthSystemForUser = (
    user: User,
    study: Study
  ): StudyHealthSystem | undefined => {
    return study.studyHealthSystems.find(
      (healthSystem) => healthSystem.organizationUuid === user.organization.uuid
    )
  }

  static getStudyFormDisplayName = (value: StudyFormValue) => {
    switch (value) {
      case StudyType.EXPANDED_ACCESS:
        return 'Expanded access'
      case StudyType.INTERVENTIONAL:
        return 'Interventional'
      case StudyType.OBSERVATIONAL:
        return 'Observational'
      case StudyType.PATIENT_REGISTRIES:
        return 'Patient registries'

      case StudyRecruitmentStatus.ACTIVE_NOT_RECRUITING:
        return 'Active, not recruiting'
      case StudyRecruitmentStatus.COMPLETED:
        return 'Completed'
      case StudyRecruitmentStatus.NOT_YET_RECRUITING:
        return 'Not yet recruiting'
      case StudyRecruitmentStatus.RECRUITING:
        return 'Recruiting'
      case StudyRecruitmentStatus.SUSPENDED:
        return 'Suspended'
      case StudyRecruitmentStatus.TERMINATED:
        return 'Terminated'
      case StudyRecruitmentStatus.WITHDRAWN:
        return 'Withdrawn'
      case StudyRecruitmentStatus.NOT_AVAILABLE:
        return 'N/A'

      case StudyPhase.PHASE_1:
        return 'Phase 1'
      case StudyPhase.PHASE_2:
        return 'Phase 2'
      case StudyPhase.PHASE_3:
        return 'Phase 3'
      case StudyPhase.PHASE_4:
        return 'Phase 4'
      case StudyPhase.NOT_AVAILABLE:
        return 'N/A'

      default:
        return ''
    }
  }

  private formatPath(p = ''): string {
    return this.urlBuilder.joinPath(this.basePath, p)
  }

  static build() {
    return container.resolve(StudyService)
  }
}

export const studyService = StudyService.build()

export const isStudySummary = (
  study: Study | StudySummary
): study is StudySummary => {
  return 'notifications' in study
}
