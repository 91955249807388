import { combineReducers } from 'redux'

import study from './study/study.reducer'
import termList from './term-list/term-list.reducer'
import remoteTrial from './remote-trial/remote-trial.reducer'
import account from './account/account.reducer'
import asyncNotifierSubscriptions from './async-notifier/async-notifier.reducer'
import redirect from './redirect/redirect.reducer'
import run from './run/run.reducer'
import authentication from './authentication/authentication.reducer'
import organization from './organization/organization.reducer'
import config from './configuration/configuration.reducer'
import patient from './patient/patient.reducer'
import recruitment from './recruitment/recruitment.reducer'

export const rootReducer = combineReducers({
  study,
  termList,
  remoteTrial,
  account,
  asyncNotifierSubscriptions,
  redirect,
  run,
  authentication,
  organization,
  config,
  patient,
  recruitment
})

export type RootState = ReturnType<typeof rootReducer>
