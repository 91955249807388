import { container, inject, injectable } from 'tsyringe'
import { StorageClient } from '@deep6ai/common'

import { isPhiAccessLevel } from '../../types/type-guards/isPhiAccessLevel'
import { UserClaims } from '../authentication/authentication-service'
import * as InMemoryStorageClientIoc from '../../lib/storage/InMemoryStorageClient.ioc'
import * as CookieStorageClientIoc from '../../lib/storage/CookieStorageClient.ioc'

export enum PhiAccessLevel {
  NONE = 'NONE',
  METRICS_ONLY = 'METRICS_ONLY',
  PATIENT_LEVEL_CONDITIONAL = 'PATIENT_LEVEL_CONDITIONAL',
  PATIENT_LEVEL_FULL = 'PATIENT_LEVEL_FULL'
}

@injectable()
export class SessionService {
  private readonly claimsStorageKey = 'session::claims'
  private readonly timeBeforeExpToNotifyInSeconds = 60

  constructor(
    @inject(InMemoryStorageClientIoc.injectionToken)
    private memoryStorage: StorageClient,
    @inject(CookieStorageClientIoc.injectionToken)
    private cookieStorage: StorageClient
  ) {}

  create = (claims: UserClaims): void => {
    this.memoryStorage.set(this.claimsStorageKey, JSON.stringify(claims))
  }

  clear = (): void => {
    this.memoryStorage.remove(this.claimsStorageKey)
  }

  isExpired = (): boolean => {
    const exp = this.getExpFromCookie()
    const now = new Date()
    return exp < now
  }

  shouldNotifyUserOfInactivity = (): boolean => {
    const exp = this.getExpFromCookie()
    const notificationTime = new Date(
      exp.getTime() - this.timeBeforeExpToNotifyInSeconds * 1000
    )
    const now = new Date()
    return notificationTime <= now
  }

  shouldAutoRefreshToken = (): boolean => {
    const now = new Date()
    const lastUserInteraction = this.getLastUserActivityTime()
    const differenceFromLastInteraction =
      now.getTime() - lastUserInteraction.getTime()
    return differenceFromLastInteraction < 1000 * 60 * 15
  }

  setLastUserActivityTime = (): void => {
    this.cookieStorage.set('lastUserActivityTime', new Date().toISOString())
  }

  getLastUserActivityTime = (): Date => {
    const timeStamp = this.cookieStorage.get('lastUserActivityTime')
    return new Date(timeStamp)
  }

  getPhiAccessLevel = (): PhiAccessLevel => {
    const claims = this.getClaims()
    if (!claims) return PhiAccessLevel.NONE
    const { phiAccessLevel } = claims
    if (!isPhiAccessLevel(phiAccessLevel)) return PhiAccessLevel.NONE
    return phiAccessLevel
  }

  getCanShare = (): boolean => {
    const claims = this.getClaims()
    return !!claims && claims.canShare
  }

  getTrAccess = (): boolean => {
    const claims = this.getClaims()
    return !!claims && claims.trialRecommenderAccess
  }

  getIrbMemberships = (): string[] => {
    const claims = this.getClaims()
    if (!claims) return []
    const { irbMemberships } = claims
    if (!irbMemberships) return []
    return irbMemberships.split(':')
  }

  getBuildQuery = (): boolean => {
    const claims = this.getClaims()
    return !!claims && claims.buildQuery
  }

  getValidateData = (): boolean => {
    const claims = this.getClaims()
    return !!claims && claims.validateData
  }

  getVersionView = (): boolean => {
    const claims = this.getClaims()
    return !!claims && claims.versionView
  }

  getAuditingLogs = (): boolean => {
    const claims = this.getClaims()
    return !!claims && claims.auditingLogs
  }

  getDownloadData = (): boolean => {
    const claims = this.getClaims()
    return !!claims && claims.downloadData
  }

  getClaims = (): UserClaims | undefined => {
    const claims = this.memoryStorage.get(this.claimsStorageKey)
    if (!claims) return
    return JSON.parse(claims)
  }

  private getExpFromCookie = (): Date => {
    const expStr = this.cookieStorage.get('tokenExpiration')
    const exp = new Date(expStr)
    if (isNaN(exp.getTime())) return new Date(0)
    return exp
  }

  static Build() {
    return container.resolve(SessionService)
  }
}

export const sessionService = SessionService.Build()
