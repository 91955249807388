import { useRef } from 'react'

import { useMountEffect } from '../useMountEffect'

/**
 * Mainly used to prevent state updates after unmount, like on page navigation,
 * to avoid the `Can't perform a React state update on an unmounted component`
 * error.
 *
 * Uses a ref to maintain `isMounted` across renders.
 * Avoids React state so that the effect is immediate.
 * The entire ref must be returned to prevent stale values.
 *
 * For example:
 * ```ts
 * const isMounted = useIsMountedRef()
 * if (isMounted.current) {
 *   setSomeState(1)
 * }
 * ```
 *
 * Consider removing when we can cancel async functions.
 */
export function useIsMountedRef() {
  const isMountedRef = useRef(true)

  useMountEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  })

  return isMountedRef
}
