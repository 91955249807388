import { container } from 'tsyringe'
import { InMemoryStorageClientFactory } from '@deep6ai/common'

export const injectionToken = 'InMemoryStorageClient'

const instance = InMemoryStorageClientFactory.build()

// because we are dealing with in memory storage, we always
// want to resolve the same instance. ~JS
container.registerInstance(injectionToken, instance)
