import React from 'react'
import cx from 'classnames'

export const Label = ({
  htmlFor,
  className,
  children,
  ...rest
}: React.LabelHTMLAttributes<HTMLLabelElement>) => {
  const styles = cx('flex items-center mb-1 font-bold text-grey50', className)
  return (
    <label htmlFor={htmlFor} className={styles} {...rest}>
      {children}
    </label>
  )
}
