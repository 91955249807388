import React, { PropsWithChildren, ReactNode } from 'react'
import { HttpError } from '@deep6ai/common'

import { AsyncStatus } from '../../hooks/useAsyncAction/useAsyncAction'
import { LoadingOverlay } from '../Loading/LoadingOverlay'
import { ErrorStatusView } from '../Error/ErrorStatusView'

type RouteResolverHttpError = HttpError | undefined | null

export interface RouteResolverViewProps extends PropsWithChildren<any> {
  status: AsyncStatus
  errors?: RouteResolverHttpError[]
  onErrorShow?: ReactNode
  onPendingShow?: ReactNode
  onPristineShow?: ReactNode
}

export const RouteResolverView = ({
  status,
  children,
  errors = [],
  onErrorShow = <ErrorStatusView errors={errors} />,
  onPendingShow = <LoadingOverlay />,
  onPristineShow = <LoadingOverlay />
}: RouteResolverViewProps) => {
  switch (status) {
    case AsyncStatus.Pristine:
      return onPristineShow
    case AsyncStatus.Pending:
      return onPendingShow
    case AsyncStatus.Error:
      return onErrorShow
    case AsyncStatus.Resolved:
      return children
  }
}
