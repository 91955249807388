import { container, inject, injectable } from 'tsyringe'
import { Logger, JsonHttpClient, UrlBuilder } from '@deep6ai/common'

import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import * as LoggerIoc from '../../lib/logging/Logger.ioc'
import { Organization } from '../organization/organization.service'
import { StudyRunCounts } from '../study/study.service'

export interface RunDetails {
  runUuid: string
  studyUuid: string
  runDate: string
  organizations: Organization[]
  userUuid?: string
}

export type StudyRunSummary = Pick<
  RunDetails,
  'runDate' | 'runUuid' | 'studyUuid'
>

@injectable()
export class StudyRunService {
  private readonly basePath = '/run'

  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private centralApiClient: JsonHttpClient,
    @inject(LoggerIoc.injectionToken) private logger: Logger,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder
  ) {}

  getLatestRunFromStudyUuid = (studyUuid: string): Promise<StudyRunSummary> => {
    return this.centralApiClient.getJson(
      this.urlBuilder.build(this.formatPath(), {
        studyUuid
      })
    )
  }

  createRun = (studyUuid: string): Promise<RunDetails> => {
    return this.centralApiClient.postJson(
      this.urlBuilder.build(this.formatPath(), {
        studyUuid
      })
    )
  }

  getCountsForRun = async (runUuid: string): Promise<StudyRunCounts> => {
    return this.centralApiClient.getJson(
      this.urlBuilder.build(this.formatPath(`/${runUuid}/count`))
    )
  }

  private formatPath(p = ''): string {
    return this.urlBuilder.joinPath(this.basePath, p)
  }

  static Build() {
    return container.resolve(StudyRunService)
  }
}

export const studyRunService = StudyRunService.Build()
