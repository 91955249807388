import { container, inject, injectable } from 'tsyringe'
import { JsonHttpClient, StorageClient } from '@deep6ai/common'

import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as InMemoryStorageClientIoc from '../../lib/storage/InMemoryStorageClient.ioc'

export enum CapabilityType {
  MULTI_SITE = 'MULTI_SITE',
  GENOMICS = 'GENOMICS'
}

export interface Capability {
  type: CapabilityType
  enabled: boolean
}

export interface UserCapabilities {
  capabilities: Set<Capability>
}

export type UserCapabilitiesSummary = Record<CapabilityType, boolean>

@injectable()
export class CapabilitiesService {
  private readonly capabilitiesStorageKeyPrefix = 'capabilities'
  private readonly basePath = '/capability'

  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private centralApi: JsonHttpClient,
    @inject(InMemoryStorageClientIoc.injectionToken)
    private storage: StorageClient<Capability, Capability>
  ) {}

  init = async (): Promise<void> => {
    if (!this.isInitialized()) {
      const { capabilities }: UserCapabilities = await this.centralApi.getJson(
        this.basePath
      )
      capabilities.forEach((capability) => {
        this.saveCapability(capability)
      })
    }
  }

  isMultiSiteCapabilityEnabled = (): boolean =>
    this.getCapability(CapabilityType.MULTI_SITE).enabled

  isGenomicCapabilityEnabled = (): boolean =>
    this.getCapability(CapabilityType.GENOMICS).enabled

  getCapabilities = (): UserCapabilitiesSummary => {
    const userCapabilitiesSummary: UserCapabilitiesSummary = {
      [CapabilityType.GENOMICS]: false,
      [CapabilityType.MULTI_SITE]: false
    }

    for (const type in userCapabilitiesSummary) {
      const capability = this.getCapability(CapabilityType[type])
      userCapabilitiesSummary[capability.type] = capability.enabled
    }

    return userCapabilitiesSummary
  }

  private getCapability(type: CapabilityType): Capability {
    if (!this.isInitialized())
      throw new UninitializedCapabilitiesException(type)
    const key = this.buildStorageKey(type)
    return this.storage.get(key)
  }

  private saveCapability = (capability: Capability) => {
    const key = this.buildStorageKey(capability.type)
    this.storage.set(key, capability)
  }

  private buildStorageKey = (key: CapabilityType) =>
    `${this.capabilitiesStorageKeyPrefix}::${key}`

  private isInitialized = (): boolean => {
    let initialized = false
    for (const type in CapabilityType) {
      const key = this.buildStorageKey(CapabilityType[type])
      const value = this.storage.get(key)
      if (value) {
        initialized = true
      }
    }
    return initialized
  }
}

export const capabilitiesService = container.resolve(CapabilitiesService)

export class UninitializedCapabilitiesException extends Error {
  constructor(key: CapabilityType) {
    super(`Attempted to access capability ${key} before initialization.`)
  }
}
