import { container, inject, injectable } from 'tsyringe'
import { StorageClient } from '@deep6ai/common'

import * as InMemoryStorageClientIoc from '../../lib/storage/InMemoryStorageClient.ioc'

@injectable()
export class StudyContextService {
  private readonly STORAGE_KEY = 'studyCtx'

  constructor(
    @inject(InMemoryStorageClientIoc.injectionToken)
    private storageClient: StorageClient<string | null, string | undefined>
  ) {}

  setContext = (studyUuid: string | undefined) => {
    this.storageClient.set(this.STORAGE_KEY, studyUuid)
  }

  getContext = (): string | null =>
    this.storageClient.get(this.STORAGE_KEY) ?? null

  static build() {
    return container.resolve(StudyContextService)
  }
}

export const studyContextService = StudyContextService.build()
