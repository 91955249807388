import { container, inject, injectable } from 'tsyringe'

import {
  NewStudy,
  StudyRecruitmentStatus,
  Study,
  StudyRunCounts,
  StudyPhase,
  StudyService,
  StudyShare,
  StudySummary,
  StudyType,
  UpdatedStudy
} from '../../services/study/study.service'
import { ReduxAsyncNotifier } from '../../lib/async-notifier/redux-async-notifier'
import type { AsyncNotifier } from '../../lib/async-notifier/async-notifier'
import { AbstractAction } from '../abstract-action'
import { PaginatedResponse } from '../../lib/http/paginated-response'
import { redirectActions } from '../redirect/redirect.actions'
import {
  RemoteTrialService,
  RemoteTrialSource
} from '../../services/remote-trial/remote-trial-service'
import {
  ProtocolCriteriaText,
  ProtocolService,
  ProtocolView
} from '../../services/protocol/ProtocolService'
import { User } from '../../services/authentication/authentication-service'
import { OrganizationService } from '../../services/organization/organization.service'
import { StudyAttachmentService } from '../../services/study/StudyAttachmentService'
import { unspecifiedDepartment } from '../../components/Form/StudyForm/Controls/DepartmentFormControl/DepartmentFormControl'

export enum StudyActionTypes {
  addStudies = 'STUDY::ADD_STUDIES',
  addStudyTypes = 'STUDY::ADD_STUDY_TYPES',
  addActiveStudy = 'STUDY::ADD_ACTIVE_STUDY',
  clearActiveStudy = 'STUDY::CLEAR_ACTIVE_STUDY',
  addActiveStudyShares = 'STUDY::ADD_ACTIVE_STUDY_SHARES',
  clearActiveStudyShares = 'STUDY::CLEAR_ACTIVE_STUDY_SHARES',
  addRecruitmentStatusValues = 'STUDY::ADD_RECRUITMENT_STATUS_VALUES',
  addPhaseValues = 'STUDY::ADD_PHASE_VALUES',
  clearPhaseValues = 'STUDY::CLEAR_PHASE_VALUES',
  addActiveProtocolUuid = 'STUDY::ADD_ACTIVE_PROTOCOL_UUID',
  addLatestProtocolUuid = 'STUDY::ADD_LATEST_PROTOCOL_UUID',
  removeStudy = 'STUDY::REMOVE',
  addActiveStudyHealthSystemCount = 'STUDY::ADD_ACTIVE_STUDY_HEALTH_SYSTEM_COUNT',
  clearActiveStudyHealthSystemCount = 'STUDY::CLEAR_ACTIVE_STUDY_HEALTH_SYSTEM_COUNT'
}

/**
 * @deprecated
 */
@injectable()
export class StudyActions {
  constructor(
    private studyService: StudyService,
    @inject(ReduxAsyncNotifier.injectionToken)
    private asyncNotifier: AsyncNotifier,
    private remoteTrialService: RemoteTrialService,
    private protocolService: ProtocolService,
    private studyAttachmentService: StudyAttachmentService
  ) {}

  addStudies(
    studySummaries: PaginatedResponse<StudySummary[]>
  ): AbstractAction<StudyActionTypes, PaginatedResponse<StudySummary[]>> {
    return { type: StudyActionTypes.addStudies, payload: studySummaries }
  }

  removeStudy(studyUuid: string): AbstractAction<StudyActionTypes, string> {
    return { type: StudyActionTypes.removeStudy, payload: studyUuid }
  }

  addActiveStudy(study: Study): AbstractAction<StudyActionTypes, Study> {
    return { type: StudyActionTypes.addActiveStudy, payload: study }
  }

  clearActiveStudy(): AbstractAction<StudyActionTypes> {
    return { type: StudyActionTypes.clearActiveStudy }
  }

  addActiveStudyShares(
    studyShares: StudyShare[]
  ): AbstractAction<StudyActionTypes, StudyShare[]> {
    return {
      type: StudyActionTypes.addActiveStudyShares,
      payload: studyShares
    }
  }

  clearActiveStudyShares(): AbstractAction<StudyActionTypes> {
    return { type: StudyActionTypes.clearActiveStudyShares }
  }

  addStudyTypes(
    studyTypes: StudyType[]
  ): AbstractAction<StudyActionTypes, StudyType[]> {
    return { type: StudyActionTypes.addStudyTypes, payload: studyTypes }
  }

  addRecruitmentStatusValues(
    recruitmentStatusValues: StudyRecruitmentStatus[]
  ): AbstractAction<StudyActionTypes, StudyRecruitmentStatus[]> {
    return {
      type: StudyActionTypes.addRecruitmentStatusValues,
      payload: recruitmentStatusValues
    }
  }

  addPhaseValues(
    phaseValues: string[]
  ): AbstractAction<StudyActionTypes, string[]> {
    return {
      type: StudyActionTypes.addPhaseValues,
      payload: phaseValues
    }
  }

  clearPhaseValues(): AbstractAction<StudyActionTypes> {
    return {
      type: StudyActionTypes.clearPhaseValues
    }
  }

  addActiveProtocolUuid(
    activeProtocolUuid: string
  ): AbstractAction<StudyActionTypes, string> {
    return {
      type: StudyActionTypes.addActiveProtocolUuid,
      payload: activeProtocolUuid
    }
  }

  addLatestProtocolUuid(
    protocolUuid: string
  ): AbstractAction<StudyActionTypes, string> {
    return {
      type: StudyActionTypes.addLatestProtocolUuid,
      payload: protocolUuid
    }
  }

  addActiveStudyHealthSystemCount(
    activeStudyHealthSystemCount: StudyRunCounts
  ): AbstractAction<StudyActionTypes, StudyRunCounts> {
    return {
      type: StudyActionTypes.addActiveStudyHealthSystemCount,
      payload: activeStudyHealthSystemCount
    }
  }

  clearActiveStudyHealthSystemCount(): AbstractAction<StudyActionTypes> {
    return {
      type: StudyActionTypes.clearActiveStudyHealthSystemCount
    }
  }

  /* async actions */

  getActiveStudy = this.asyncNotifier.subscribe(
    (studyUuid: string) => async (dispatch) => {
      dispatch(
        this.addActiveStudy(await this.studyService.findByUuid(studyUuid))
      )
    }
  )

  getActiveStudyShares = this.asyncNotifier.subscribe(
    (studyUuid: string) => async (dispatch) => {
      dispatch(
        this.addActiveStudyShares(
          await this.studyService.findAllStudyShareByStudyUuid(studyUuid)
        )
      )
    }
  )

  createAndSelect = this.asyncNotifier.subscribe(
    (study: NewStudy) => async (dispatch) => {
      const { studyUuid } = await this.studyService.create({
        title: study.title,
        studyType: study.studyType,
        allowDeceasedPatients: study.allowDeceasedPatients,
        studyPurpose: study.studyPurpose,
        studySponsorType: study.studySponsorType,
        published: study.published
      })

      if (study.irbNumber) {
        await this.studyService.upsertIrbNumber(studyUuid, study.irbNumber)
      }

      if (this.shouldUpsertStudyRecruitment(study.recruitment)) {
        await this.studyService.upsertStudyRecruitment(
          studyUuid,
          study.recruitment
        )
      }

      if (study.recruitmentGoal) {
        await this.studyService.upsertStudyRecruitmentGoal(
          studyUuid,
          study.recruitmentGoal
        )
      }

      if (
        study.departmentType &&
        study.departmentType !== unspecifiedDepartment.value
      ) {
        await this.studyService.upsertStudyDepartment(
          studyUuid,
          study.departmentType
        )
      }

      if (this.shouldUpsertStudyPhase(study.phase)) {
        await this.studyService.upsertStudyPhase(studyUuid, study.phase)
      }

      await this.studyAttachmentService.handleAttachmentByState(
        studyUuid,
        study.attachments
      )

      await Promise.all(
        study.remoteTrials?.map((remoteTrial) =>
          this.studyService.upsertRemoteTrial(studyUuid, remoteTrial)
        ) ?? []
      )

      await this.studyService.upsertTeamMembers(
        studyUuid,
        study.studyTeam || []
      )

      await dispatch(
        this.addActiveStudy(await this.studyService.findByUuid(studyUuid))
      )

      dispatch(redirectActions.goTo(`/studies/${studyUuid}/edit-criteria`))
    }
  )

  duplicateStudy = this.asyncNotifier.subscribe(
    (sourceStudyUuid: string, study: NewStudy, activeUser: User) =>
      async (dispatch) => {
        const { studyUuid } = await this.studyService.duplicate({
          sourceStudyUuid,
          study
        })

        if (study.irbNumber) {
          await this.studyService.upsertIrbNumber(studyUuid, study.irbNumber)
        }

        if (this.shouldUpsertStudyRecruitment(study.recruitment)) {
          await this.studyService.upsertStudyRecruitment(
            studyUuid,
            study.recruitment
          )
        }

        if (study.recruitmentGoal) {
          await this.studyService.upsertStudyRecruitmentGoal(
            studyUuid,
            study.recruitmentGoal
          )
        }

        if (this.shouldUpsertStudyPhase(study.phase)) {
          await this.studyService.upsertStudyPhase(studyUuid, study.phase)
        }

        if (
          study.departmentType &&
          study.departmentType !== unspecifiedDepartment.value
        ) {
          await this.studyService.upsertStudyDepartment(
            studyUuid,
            study.departmentType
          )
        }

        await this.studyAttachmentService.handleAttachmentByState(
          studyUuid,
          study.attachments
        )

        await Promise.all(
          study.remoteTrials?.map((remoteTrial) =>
            this.studyService.upsertRemoteTrial(studyUuid, remoteTrial)
          ) ?? []
        )

        await this.studyService.upsertTeamMembers(
          studyUuid,
          study.studyTeam || []
        )

        if (OrganizationService.isHealthSystemUser(activeUser)) {
          window.location.assign(
            `/studies/${studyUuid}/healthSystem/${activeUser.organization.healthSystem?.healthSystemUuid}/build-query`
          )
        } else {
          window.location.assign(`/studies/${studyUuid}/build-query`)
        }
      }
  )

  updateStudy = this.asyncNotifier.subscribe(
    (updatedStudy: UpdatedStudy, previousStudy: Study, activeUser?: User) =>
      async (dispatch) => {
        await this.studyService.update(updatedStudy.studyUuid, {
          title: updatedStudy.title,
          studyType: updatedStudy.studyType,
          studySponsorType: updatedStudy.studySponsorType,
          published: updatedStudy.published
        })

        if (updatedStudy.irbNumber) {
          await this.studyService.upsertIrbNumber(
            updatedStudy.studyUuid,
            updatedStudy.irbNumber
          )
        } else if (
          activeUser &&
          OrganizationService.isHealthSystemUser(activeUser) &&
          StudyService.getStudyHealthSystemForUser(activeUser, previousStudy)
            ?.irbNumber
        ) {
          await this.studyService.deleteIrbNumber(updatedStudy.studyUuid)
        }

        if (this.shouldUpsertStudyRecruitment(updatedStudy.recruitment)) {
          await this.studyService.upsertStudyRecruitment(
            updatedStudy.studyUuid,
            updatedStudy.recruitment
          )
        } else {
          await this.studyService.deleteStudyRecruitment(updatedStudy.studyUuid)
        }

        if (updatedStudy.recruitmentGoal) {
          await this.studyService.upsertStudyRecruitmentGoal(
            updatedStudy.studyUuid,
            updatedStudy.recruitmentGoal
          )
        } else if (previousStudy.recruitmentGoal) {
          await this.studyService.deleteStudyRecruitmentGoal(
            updatedStudy.studyUuid
          )
        }

        if (
          updatedStudy.departmentType &&
          updatedStudy.departmentType !== unspecifiedDepartment.value &&
          activeUser &&
          OrganizationService.isHealthSystemUser(activeUser)
        ) {
          await this.studyService.upsertStudyDepartment(
            updatedStudy.studyUuid,
            updatedStudy.departmentType
          )
        } else if (
          activeUser &&
          OrganizationService.isHealthSystemUser(activeUser) &&
          StudyService.getStudyHealthSystemForUser(activeUser, previousStudy)
            ?.department
        ) {
          await this.studyService.deleteStudyDepartment(updatedStudy.studyUuid)
        }

        if (this.shouldUpsertStudyPhase(updatedStudy.phase)) {
          await this.studyService.upsertStudyPhase(
            updatedStudy.studyUuid,
            updatedStudy.phase
          )
        } else if (previousStudy.phase) {
          await this.studyService.deleteStudyPhase(updatedStudy.studyUuid)
        }

        if (updatedStudy.remoteTrials?.length) {
          await Promise.all(
            updatedStudy.remoteTrials.map((remoteTrial) =>
              this.studyService.upsertRemoteTrial(
                updatedStudy.studyUuid,
                remoteTrial
              )
            )
          )
        } else if (previousStudy.remoteTrials?.length) {
          await this.studyService.deleteRemoteTrial(
            updatedStudy.studyUuid,
            RemoteTrialSource.NCT
          )
        }

        await this.studyAttachmentService.handleAttachmentByState(
          updatedStudy.studyUuid,
          updatedStudy.attachments
        )

        await this.studyService.upsertTeamMembers(
          updatedStudy.studyUuid,
          updatedStudy.studyTeam || []
        )

        dispatch(
          this.addActiveStudy(
            await this.studyService.findByUuid(updatedStudy.studyUuid)
          )
        )
      }
  )

  updateProtocolCriteriaAndRedirect = this.asyncNotifier.subscribe(
    (
        protocol: ProtocolView,
        studyUuid: string,
        criteriaTexts: ProtocolCriteriaText[],
        activeUser: User
      ) =>
      async (dispatch) => {
        await this.protocolService.updateProtocolCriteria(
          protocol,
          studyUuid,
          criteriaTexts.filter(
            (c: ProtocolCriteriaText) => c.text.trim().length > 0
          )
        )

        const redirectUrl = OrganizationService.isHealthSystemUser(activeUser)
          ? `/studies/${studyUuid}/healthSystem/${activeUser.organization.healthSystem?.healthSystemUuid}/build-query`
          : `/studies/${studyUuid}/build-query`

        dispatch(redirectActions.goTo(redirectUrl))
      }
  )

  getStudies = this.asyncNotifier.subscribe(() => async (dispatch) => {
    dispatch(this.addStudies(await this.studyService.list()))
  })

  archiveStudy = this.asyncNotifier.subscribe(
    (studyUuid: string) => async (dispatch) => {
      await this.studyService.archive(studyUuid)
      dispatch(this.removeStudy(studyUuid))
    }
  )

  getStudyTypes = this.asyncNotifier.subscribe(() => async (dispatch) => {
    dispatch(this.addStudyTypes(await this.studyService.getStudyTypes()))
  })

  getRecruitmentStatusValues = this.asyncNotifier.subscribe(
    () => async (dispatch) => {
      dispatch(
        this.addRecruitmentStatusValues(
          await this.studyService.getRecruitmentStatusValues()
        )
      )
    }
  )

  getProtocolUuidByStudyUuid = this.asyncNotifier.subscribe(
    (studyUuid: string) => async (dispatch) => {
      const { protocolUuid } =
        await this.protocolService.findInFlightProtocolByStudyUuid(studyUuid)
      dispatch(this.addLatestProtocolUuid(protocolUuid))
      dispatch(this.addActiveProtocolUuid(protocolUuid))
    }
  )

  getPhaseValuesByStudyType = this.asyncNotifier.subscribe(
    (studyType: StudyType) => async (dispatch) => {
      const phaseValues = await this.studyService.getPhaseValuesByStudyType(
        studyType
      )
      dispatch(this.addPhaseValues(phaseValues))
    }
  )

  getStudyHealthSystemCountAndSetActive = this.asyncNotifier.subscribe(
    (studyUuid: string) => async (dispatch) => {
      this.clearActiveStudyHealthSystemCount()
      const res = await this.studyService.getStudyRunCounts(studyUuid)

      if (!res) {
        dispatch(this.clearActiveStudyHealthSystemCount())
      } else {
        dispatch(this.addActiveStudyHealthSystemCount(res))
      }
    }
  )

  private shouldUpsertStudyRecruitment(
    recruitmentStatus: string | undefined
  ): recruitmentStatus is string {
    return (
      !!recruitmentStatus &&
      recruitmentStatus !== StudyRecruitmentStatus.NOT_AVAILABLE
    )
  }

  private shouldUpsertStudyPhase(
    studyPhase: string | undefined
  ): studyPhase is string {
    return !!studyPhase && studyPhase !== StudyPhase.NOT_AVAILABLE
  }

  static Build() {
    return container.resolve(StudyActions)
  }
}

/**
 * @deprecated
 */
export const studyActions = StudyActions.Build()
