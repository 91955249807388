import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter, useHistory } from 'react-router-dom'

import { redirectActions } from '../../../store/redirect/redirect.actions'

interface RedirectManagerProps {
  url: string | null
}

interface RedirectManagerActionProps {
  clear: () => void
}

const _RedirectManager = ({
  url,
  clear
}: RedirectManagerProps & RedirectManagerActionProps) => {
  const history = useHistory()
  useEffect(() => {
    if (url) {
      history.push(url)
      clear()
    }
  })

  return <>{/*{'nothing to see here'}*/}</>
}

const mapStateToProps = ({ redirect }): RedirectManagerProps => ({
  url: redirect.location
})

const mapDispatchToProps: RedirectManagerActionProps = {
  clear: redirectActions.clear
}

export const RedirectManager = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(_RedirectManager)
)
