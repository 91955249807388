import React from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

export const LoadingOverlay = () => {
  return (
    <div
      data-testid="LoadingOverlay"
      className="h-full w-full flex flex-col justify-center"
    >
      <div className="self-center m-4">
        <AiOutlineLoading3Quarters className="m-auto animate-spin" size={48} />
        <p className="mt-3 text-2xl">Loading</p>
      </div>
    </div>
  )
}
