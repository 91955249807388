import { container, injectable } from 'tsyringe'

import { PhiAccessLevel, SessionService } from '../session/SessionService'

@injectable()
export class PermissionsService {
  constructor(private session: SessionService) {}

  canAccessPHI = (irb?: string): boolean => {
    const phiAccessLevel = this.session.getPhiAccessLevel()
    if (phiAccessLevel === PhiAccessLevel.PATIENT_LEVEL_CONDITIONAL) {
      const irbMemberships = this.session.getIrbMemberships()
      if (irb && irbMemberships.includes(irb)) return true
    }
    return phiAccessLevel === PhiAccessLevel.PATIENT_LEVEL_FULL
  }

  canAccessTR = (): boolean => {
    const trAccess = this.session.getTrAccess()
    const phiAccess =
      this.session.getPhiAccessLevel() >=
      PhiAccessLevel.PATIENT_LEVEL_CONDITIONAL
    return trAccess && phiAccess
  }

  canShare = (): boolean => {
    return this.session.getCanShare()
  }

  canBuildQuery = (): boolean => {
    return this.session.getBuildQuery()
  }

  canValidateData = (): boolean => {
    return this.session.getValidateData()
  }

  canViewVersions = (): boolean => {
    return this.session.getVersionView()
  }

  canAccessAuditingLogs = (): boolean => {
    return this.session.getAuditingLogs()
  }

  canDownloadData = (): boolean => {
    return this.session.getDownloadData()
  }

  static Build() {
    return container.resolve(PermissionsService)
  }
}

export const permissionsService = PermissionsService.Build()
