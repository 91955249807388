import { container, inject, injectable } from 'tsyringe'
import { JsonHttpClient, UrlBuilder } from '@deep6ai/common'

import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import {
  emptyPaginatedContent,
  PaginatedResponse
} from '../../lib/http/paginated-response'
import { Pageable } from '../../lib/http/pageable'

export enum AccountState {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED'
}

export interface Account {
  uuid: string
  displayName: string
}

export interface AccountDisplayName {
  accountUuid: string
  displayName: string
}

@injectable()
export class AccountService {
  private basePath = '/account'

  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private centralApi: JsonHttpClient,
    @inject(UrlBuilderIoc.injectionToken)
    private urlBuilder: UrlBuilder
  ) {}

  findAllAccountsByState = (
    searchTerm = '',
    states?: AccountState[]
  ): Promise<PaginatedResponse<AccountDisplayName[]>> => {
    if (searchTerm === '') return Promise.resolve(emptyPaginatedContent())
    const url = this.urlBuilder.build(this.formatPath(), {
      searchTerm,
      states
    })
    return this.centralApi.getJson(url)
  }

  findAllAccountsByUuid = (
    accountUuids: string[],
    pageable?: Pageable
  ): Promise<PaginatedResponse<AccountDisplayName[]>> => {
    if (!accountUuids.length) return Promise.resolve(emptyPaginatedContent([]))

    const url = this.urlBuilder.build(this.formatPath('/uuids'), {
      accountUuids,
      page: pageable?.page,
      size: pageable?.size
    })

    return this.centralApi.getJson(url)
  }

  findAllShareRecipientsByHealthSystemUuidAndState = (
    healthSystemUuid: string,
    searchTerm?: string,
    states?: AccountState[]
  ): Promise<PaginatedResponse<AccountDisplayName[]>> => {
    const url = this.urlBuilder.build(this.formatPath('/share-recipients'), {
      healthSystemUuid,
      searchTerm,
      states
    })
    return this.centralApi.getJson(url)
  }

  private formatPath(p = ''): string {
    return this.urlBuilder.joinPath(this.basePath, p)
  }

  static Build() {
    return container.resolve(AccountService)
  }
}

export const accountService = AccountService.Build()
