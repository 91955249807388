import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  Logger,
  LoggerFactory,
  FeatureFlagClient,
  FeatureFlagClientFactory
} from '@deep6ai/common'
import { Switch, Route, useParams } from 'react-router-dom'
import { If } from '@deep6ai/component-library'

import {
  User,
  UserClaims
} from '../../../services/authentication/authentication-service'
import { injectHeapScript } from './injectHeapScript'
import { FeatureFlagKey } from '../../../config/feature-flags'
import { Study, TeamMember } from '../../../services/study/study.service'
import { sessionService } from '../../../services/session/SessionService'
import {
  capabilitiesService,
  UserCapabilitiesSummary
} from '../../../services/capabilities/CapabilitiesService'

declare global {
  interface Window {
    heap: any
  }
}

interface HeapProps {
  activeUser: User
  apiKey: string
  logger?: Logger
  study?: Study
  getUserCapabilities?: typeof capabilitiesService.getCapabilities
  getUserClaims?: typeof sessionService.getClaims
}

function transformUserPermissions(
  permissions: UserClaims | UserCapabilitiesSummary
) {
  const transformedPermissions = {}

  Object.entries(permissions).forEach(([key, value]) => {
    transformedPermissions[`Permission ${key}`] = value
  })

  return transformedPermissions
}

export const _Heap = ({
  activeUser,
  apiKey,
  getUserCapabilities = capabilitiesService.getCapabilities,
  getUserClaims = sessionService.getClaims,
  logger = LoggerFactory.build(),
  study
}: HeapProps) => {
  const [isInitialized, setIsInitialized] = useState(false)
  const userClaims = getUserClaims()
  const userCapabilities = getUserCapabilities()

  const scriptIsInjected = () => !!window.heap
  const featureFlagClient: FeatureFlagClient<FeatureFlagKey> =
    FeatureFlagClientFactory.getClient<FeatureFlagKey>()

  useEffect(() => {
    if (featureFlagClient.isFlagEnabled(FeatureFlagKey.HEAP)) {
      injectHeapScript(apiKey)
    }
  }, [apiKey, featureFlagClient])

  useEffect(() => {
    const hasRequiredData = () =>
      !!activeUser?.accountUuid &&
      !!activeUser?.displayName &&
      !!activeUser?.organization &&
      userClaims &&
      userCapabilities

    if (!hasRequiredData()) {
      logger.error('Heap is missing user details', activeUser)
    }

    if (
      !scriptIsInjected() ||
      isInitialized ||
      !hasRequiredData() ||
      !userClaims
    )
      return

    const { accountUuid, displayName, organization } = activeUser

    window.heap.identify(accountUuid)

    const heapUserProperties = {
      account_name: organization.displayName,
      account_type: organization.type,
      account_UUID: organization.uuid,
      user_fullname: displayName,
      ...transformUserPermissions(userCapabilities),
      ...transformUserPermissions(userClaims)
    }

    window.heap.addUserProperties(heapUserProperties)

    setIsInitialized(true)
  }, [activeUser, isInitialized, logger, userCapabilities, userClaims])

  return (
    <Switch>
      <Route path={['*/studies/:studyUuid', '*']}>
        <If condition={scriptIsInjected()}>
          <HeapEventPropertiesSetter study={study} />
        </If>
      </Route>
    </Switch>
  )
}

const mapStateToProps = ({ authentication, study }) => ({
  activeUser: authentication.activeUser,
  study: study.activeStudy
})

export const Heap = connect(mapStateToProps)(_Heap)

const generateTeamList = (team: TeamMember[]): string =>
  team.map((member) => member.displayName).toString()

const HeapEventPropertiesSetter = ({ study }: { study?: Study }) => {
  const { studyUuid } = useParams<{ studyUuid: string }>()

  useEffect(() => {
    if (study && studyUuid) {
      const studyTeam = study.studyOrganizationTeam?.[0]?.studyTeam
      window.heap.addEventProperties({
        'Study Title': study.title,
        'Study UUID': study.studyUuid,
        'Study NCT': study.remoteTrials?.[0]?.id,
        'Study Creator': study.createdBy.displayName,
        'Study State': study.state,
        'Study Type': study.type,
        'Study Phase': study.phase,
        'Study Purpose': study.purpose,
        'Sponsor Type': study.sponsorType,
        'Recruitment Status': study.recruitment,
        'Recruitment Goal': study.recruitmentGoal,
        'Study IRB': study.studyHealthSystems[0]?.irbNumber,
        'Study Team': studyTeam ? generateTeamList(studyTeam) : undefined,
        'Study Create Date': study.createdAt
      })
    } else {
      window.heap.clearEventProperties()
    }
  }, [study, studyUuid])

  return <></>
}
