import React from 'react'
import { FieldError, NestDataObject } from 'react-hook-form'
import { If } from '@deep6ai/component-library'

interface ErrorProps {
  error?: FieldError
}

/**
 * @deprecated - use FormControlErrorMessage instead
 * */
export const Error = ({
  error
}: ErrorProps | NestDataObject<any, FieldError>) => (
  <div className="text-red">{error && <span>{error.message}</span>}</div>
)

interface FormErrorMessageProps {
  id?: string
  error: string | false | undefined
}

export const FormControlErrorMessage = ({
  error,
  id = 'FormControlErrorMessage'
}: FormErrorMessageProps) => {
  return (
    <If condition={!!error}>
      <div data-testid={id} className="text-red">
        {error}
      </div>
    </If>
  )
}
