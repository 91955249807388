import React from 'react'
import cx from 'classnames'
import { connect } from 'react-redux'
import { If } from '@deep6ai/component-library'
import { FeatureFlagClient, FeatureFlagClientFactory } from '@deep6ai/common'

import { NavLogo } from './NavLogo'
import { NavItem } from './NavItem'
import { NavUser } from './NavUser'
import {
  User,
  authenticationService
} from '../../services/authentication/authentication-service'
import { permissionsService } from '../../services/permissions/PermissionsService'
import {
  OrganizationService,
  OrganizationType
} from '../../services/organization/organization.service'
import { NavBreadcrumb } from './NavBreadcrumb'
import { ToolTip } from '../ToolTip/ToolTip'
import { UserpilotHelp } from './UserpilotHelp'
import { FeatureFlagKey } from '../../config/feature-flags'
import { NavDashboard } from './NavDashboard'

export const NAV_HEIGHT = 42

const trialRecommenderTooltipId = 'tip-tr-navlink'

export interface NavbarProps extends React.HTMLAttributes<HTMLElement> {
  activeUser: User
  canAccessTR?: typeof permissionsService.canAccessTR
  canAccessAuditingLogs?: typeof permissionsService.canAccessAuditingLogs
  isHealthSystemUser?: typeof OrganizationService.isHealthSystemUser
  logOut?: typeof authenticationService.logOut
  featureFlagClient?: FeatureFlagClient<FeatureFlagKey>
}

export function _Navbar({
  activeUser,
  className,
  canAccessTR = permissionsService.canAccessTR,
  canAccessAuditingLogs = permissionsService.canAccessAuditingLogs,
  isHealthSystemUser = OrganizationService.isHealthSystemUser,
  logOut = authenticationService.logOut,
  featureFlagClient = FeatureFlagClientFactory.getClient<FeatureFlagKey>()
}: NavbarProps) {
  const isReportingEnabled =
    featureFlagClient.isFlagEnabled(FeatureFlagKey.REPORTING_DASHBOARDS) &&
    canAccessAuditingLogs() &&
    OrganizationService.isHealthSystemUser(activeUser)

  return (
    <nav
      className={cx('bg-grey-05 border-t border-b border-grey-15', className)}
    >
      <div className="px-4 mx-auto max-w-7xl">
        <div
          className="flex items-center justify-between"
          style={{ height: NAV_HEIGHT }}
        >
          <div className="flex items-center">
            <NavLogo />

            <div className="flex items-center mx-4">
              <NavItem href="/studies">Studies</NavItem>

              <NavItem href="/lists">Lists</NavItem>

              <If condition={isHealthSystemUser(activeUser)}>
                <span data-tip data-for={trialRecommenderTooltipId}>
                  <NavItem href="/trial-recommender" disabled={!canAccessTR()}>
                    Trial Recommender
                  </NavItem>
                </span>
                <TrialRecommenderNavItemDisabledToolTip
                  disabled={canAccessTR()}
                />
              </If>

              <If condition={isReportingEnabled}>
                <NavDashboard isReportingEnabled={isReportingEnabled} />
              </If>
            </div>
          </div>

          <NavBreadcrumb className="flex-1" />

          <div className="flex items-center">
            <If
              condition={
                activeUser.organization.type === OrganizationType.HEALTH_SYSTEM
              }
            >
              <UserpilotHelp />
            </If>
            <NavUser user={activeUser} onLogOut={() => logOut('user')} />
          </div>
        </div>
      </div>
    </nav>
  )
}

function TrialRecommenderNavItemDisabledToolTip({
  disabled = false
}: {
  disabled?: boolean
}) {
  return (
    <ToolTip id={trialRecommenderTooltipId} disable={disabled}>
      No PHI permission to access this feature.
    </ToolTip>
  )
}

const mapStateToProps = ({ authentication }) => ({
  activeUser: authentication.activeUser
})

export const Navbar = connect(mapStateToProps, {})(_Navbar)
