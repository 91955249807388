import { AbstractAction } from '../abstract-action'
import { PatientActionTypes } from './patient.actions'
import {
  emptyPaginatedContent,
  PaginatedResponse
} from '../../lib/http/paginated-response'
import {
  Patient,
  StudiesForPatient
} from '../../services/patient/models/Patient'

export interface PatientReducerSlice {
  patientSummaries: PaginatedResponse
  activePatient: Patient | null
  studiesForPatients: StudiesForPatient[]
}

export const initialState: PatientReducerSlice = {
  patientSummaries: emptyPaginatedContent(),
  activePatient: null,
  studiesForPatients: []
}

export default function patientReducer(
  state = initialState,
  action: AbstractAction<PatientActionTypes>
) {
  switch (action.type) {
    case PatientActionTypes.addPatientSummaries:
      return {
        ...state,
        patientSummaries: action.payload
      }
    case PatientActionTypes.clearPatientSummaries:
      return {
        ...state,
        patientList: emptyPaginatedContent()
      }
    case PatientActionTypes.addActivePatient:
      return {
        ...state,
        activePatient: action.payload
      }
    case PatientActionTypes.clearActivePatient:
      return {
        ...state,
        activePatient: null
      }
    case PatientActionTypes.addStudiesForPatients:
      return {
        ...state,
        studiesForPatients: action.payload
      }
    case PatientActionTypes.clearStudiesForPatients:
      return {
        ...state,
        studiesForPatients: []
      }
    default:
      return state
  }
}
