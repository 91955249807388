import { RecruitmentBuckets } from '../../services/recruitment/Recruitment'
import { AbstractAction } from '../abstract-action'
import { RecruitmentActionTypes } from './recruitment.actions'

export interface RecruitmentReducerSlice {
  activeRecruitmentBuckets: RecruitmentBuckets | null
}

export const initialState: RecruitmentReducerSlice = {
  activeRecruitmentBuckets: null
}

export default function recruitmentReducer(
  state = initialState,
  action: AbstractAction<RecruitmentActionTypes>
) {
  switch (action.type) {
    case RecruitmentActionTypes.addActiveRecruitmentBuckets:
      return {
        ...state,
        activeRecruitmentBuckets: action.payload
      }
    case RecruitmentActionTypes.clearActiveRecruitmentBuckets:
      return {
        ...state,
        activeRecruitmentBuckets: null
      }
    default:
      return state
  }
}
