import React, { ReactNode } from 'react'

import { ErrorMessageLogo } from '../../components/Error/ErrorMessageLogo'

interface ErrorPageProps extends React.HTMLAttributes<HTMLDivElement> {
  message?: ReactNode
}

export const ErrorPage = ({ message, ...rest }: ErrorPageProps) => (
  <ErrorMessageLogo message={message} {...rest} />
)
