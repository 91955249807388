import { container, inject, injectable } from 'tsyringe'
import { HttpClient, JsonHttpClient, UrlBuilder } from '@deep6ai/common'

import { CentralApiHttpClient } from '../../lib/central-api-http-client/central-api-http-client'
import * as SignedS3UrlHttpClientIoc from '../../lib/signed-s3-url-http-client/SignedS3UrlHttpClient.ioc'
import * as UrlBuilderIoc from '../../lib/url/UrlBuilder.ioc'
import {
  AttachmentFormValue,
  CreateFileAttachmentFormValue,
  CreateLinkAttachmentFormValue,
  EditFileAttachmentFormValue,
  EditLinkAttachmentFormValue,
  StudyAttachmentFormControlState,
  DeleteLinkAttachmentFormValue,
  DeleteFileAttachmentFormValue
} from '../../components/Form/StudyForm/Controls/AttachmentsFormControl/AttachmentsFormControl'
import { isPromiseFulfilledResult } from '../../types/type-guards/isSettledPromiseType'

export enum AttachmentType {
  FILE = 'FILE',
  LINK = 'LINK'
}

export interface LinkAttachment {
  type: AttachmentType.LINK
  uuid: string
  displayName: string
  url: string
}

export interface FileAttachment {
  type: AttachmentType.FILE
  uuid: string
  displayName: string
  fileName: string
  uploadUrl: string
}

export interface FileAttachmentDownload extends FileAttachment {
  downloadUrl?: string
}

export type Attachment = LinkAttachment | FileAttachment

export type CreateLinkAttachment = Pick<
  LinkAttachment,
  'type' | 'displayName' | 'url'
>

export type CreateFileAttachment = Pick<
  FileAttachment,
  'type' | 'displayName' | 'fileName'
> & { file: File }

export type EditLinkAttachment = Pick<
  LinkAttachment,
  'type' | 'uuid' | 'displayName' | 'url'
>

export type EditFileAttachment = Pick<
  FileAttachment,
  'type' | 'uuid' | 'fileName' | 'displayName'
> & {
  file?: File
}

export type DeleteFileAttachment = Pick<
  FileAttachment,
  'type' | 'uuid' | 'fileName' | 'displayName'
>

export type DeleteLinkAttachment = Pick<
  LinkAttachment,
  'type' | 'uuid' | 'displayName' | 'url'
>

export type CreateAttachment = CreateLinkAttachment | CreateFileAttachment

export type EditAttachment = EditLinkAttachment | EditFileAttachment

export type DeleteAttachment = DeleteFileAttachment | DeleteLinkAttachment

export type DownloadableAttachment =
  | FileAttachment
  | EditFileAttachmentFormValue

@injectable()
export class StudyAttachmentService {
  private getBasePath = (studyUuid: string) => `/study/${studyUuid}/attachments`

  constructor(
    @inject(CentralApiHttpClient.injectionToken)
    private hubClient: HttpClient & JsonHttpClient,
    @inject(SignedS3UrlHttpClientIoc.injectionToken)
    private s3Client: HttpClient & JsonHttpClient,
    @inject(UrlBuilderIoc.injectionToken) private urlBuilder: UrlBuilder
  ) {}

  static getAttachmentDisplayName = (
    attachment: Attachment | AttachmentFormValue
  ): string => {
    if (attachment.type === AttachmentType.LINK) {
      return attachment.displayName || attachment.url
    }

    return attachment.displayName || attachment.fileName
  }

  createAttachments = async (
    studyUuid: string,
    createAttachments: CreateAttachment[]
  ): Promise<Attachment[]> => {
    const attachments = await this.hubClient.postJson<Attachment[]>(
      this.formatPath(studyUuid),
      {
        body: JSON.stringify(createAttachments)
      }
    )

    const fileAttachments = attachments.filter(isFileAttachment)
    const createFileAttachments = createAttachments.filter(
      isCreateFileAttachment
    )

    await Promise.allSettled(
      fileAttachments.map((fileAttachment, idx) => {
        return this.uploadAttachment(
          fileAttachment.uploadUrl,
          createFileAttachments[idx].file
        )
      })
    )

    return attachments
  }

  private uploadAttachment(uploadUrl: string, file: File) {
    return this.s3Client.put(uploadUrl, {
      body: file,
      headers: {
        'Content-Type': file.type
      }
    })
  }

  edit = (
    studyUuid: string,
    attachmentUuid: string,
    editAttachment: EditAttachment
  ): Promise<Attachment> => {
    const { displayName } = editAttachment
    return this.hubClient.patchJson<Attachment>(
      this.formatPath(studyUuid, attachmentUuid),
      {
        body: JSON.stringify({ displayName })
      }
    )
  }

  delete = (studyUuid: string, attachmentUuid: string): Promise<Response> => {
    return this.hubClient.delete(this.formatPath(studyUuid, attachmentUuid))
  }

  download = async (
    studyUuid: string,
    attachmentUuid: string
  ): Promise<void> => {
    const fileAttachment = await this.hubClient.getJson<FileAttachmentDownload>(
      this.formatPath(studyUuid, attachmentUuid)
    )

    if (
      fileAttachment.type === AttachmentType.FILE &&
      fileAttachment.downloadUrl
    ) {
      window.location.assign(fileAttachment.downloadUrl)
    } else {
      throw new Error('Attachment cannot be downloaded')
    }
  }

  preview = async (
    studyUuid: string,
    attachmentUuid: string
  ): Promise<void> => {
    const fileAttachment = await this.hubClient.getJson<FileAttachmentDownload>(
      this.formatPath(studyUuid, attachmentUuid)
    )

    if (
      fileAttachment.type === AttachmentType.FILE &&
      fileAttachment.downloadUrl
    ) {
      window.open(fileAttachment.downloadUrl)
    } else {
      throw new Error('Attachment cannot be previewed')
    }
  }

  /**
   * @description Accepts a list of attachments and performs any operations that have been attached to them.
   * @param studyUuid The study uuid
   * @param attachments The attachments to perform operations on
   * @returns The attachments with any operations performed on them
   */
  handleAttachmentByState = async (
    studyUuid: string,
    attachments: (Attachment | AttachmentFormValue)[] = []
  ): Promise<Attachment[]> => {
    const attachmentFormValues = attachments.filter(isAttachmentFormValue)

    if (attachmentFormValues.length === 0) {
      return []
    }

    const newAttachments = await this.createAttachments(
      studyUuid,
      attachmentFormValues.filter(isCreateAttachmentFormValue)
    )

    const editedAttachments = (
      await Promise.allSettled(
        attachmentFormValues
          .filter(isEditedAttachmentFormValue)
          .map((a) => this.edit(studyUuid, a.uuid, a))
      )
    )
      .filter(isPromiseFulfilledResult)
      .map((r) => r.value)

    await Promise.allSettled(
      attachmentFormValues
        .filter(isDeleteAttachmentFormValue)
        .map((a) => this.delete(studyUuid, a.uuid))
    )

    return [...editedAttachments, ...newAttachments]
  }

  private formatPath(studyUuid: string, p = ''): string {
    return this.urlBuilder.joinPath(this.getBasePath(studyUuid), p)
  }

  static build() {
    return container.resolve(StudyAttachmentService)
  }
}

export function isLinkAttachment(
  attachment: Attachment
): attachment is LinkAttachment {
  return attachment.type === AttachmentType.LINK
}

export function isFileAttachment(
  attachment: Attachment
): attachment is FileAttachment {
  return attachment.type === AttachmentType.FILE
}

export function isCreateLinkAttachment(
  attachment: CreateAttachment
): attachment is CreateLinkAttachment {
  return attachment.type === AttachmentType.LINK
}

export function isCreateFileAttachment(
  attachment: CreateAttachment
): attachment is CreateFileAttachment {
  return attachment.type === AttachmentType.FILE
}

export function isAttachmentFormValue(
  attachment: Attachment | AttachmentFormValue
): attachment is AttachmentFormValue {
  return 'state' in attachment
}

export function isCreateAttachmentFormValue(
  attachment: Attachment | AttachmentFormValue
): attachment is CreateLinkAttachmentFormValue | CreateFileAttachmentFormValue {
  return (
    isAttachmentFormValue(attachment) &&
    attachment.state === StudyAttachmentFormControlState.NEW
  )
}

export function isEditedAttachmentFormValue(
  attachment: Attachment | AttachmentFormValue
): attachment is EditLinkAttachmentFormValue | EditFileAttachmentFormValue {
  return (
    isAttachmentFormValue(attachment) &&
    attachment.state === StudyAttachmentFormControlState.EDIT
  )
}

export function isDeleteAttachmentFormValue(
  attachment: Attachment | AttachmentFormValue
): attachment is DeleteLinkAttachmentFormValue | DeleteFileAttachmentFormValue {
  return (
    isAttachmentFormValue(attachment) &&
    attachment.state === StudyAttachmentFormControlState.DELETE
  )
}

export function isDownloadableAttachment(
  attachment: Attachment | AttachmentFormValue
): attachment is DownloadableAttachment {
  return (
    attachment.type === AttachmentType.FILE &&
    (!('state' in attachment) ||
      attachment.state === StudyAttachmentFormControlState.EDIT)
  )
}

export const studyAttachmentService = StudyAttachmentService.build()
