import React, { useEffect, useState } from 'react'
import { Autocomplete, If, TextField } from '@deep6ai/component-library'

import { StudyFormContext } from '../StudyFormContext'
import {
  Study,
  StudyService
} from '../../../../../services/study/study.service'
import { FormGroup } from '../../../FormGroup'
import { Label } from '../../../Label'
import { FormControlErrorMessage } from '../../../Error'
import { useMountEffect } from '../../../../../hooks/useMountEffect'
import { Option } from '../../../Select/Select'
import { User } from '../../../../../services/authentication/authentication-service'
import { errorMessages } from '../../errorMessages'

export interface DepartmentFormControlProps {
  formCtx: StudyFormContext
  departmentTypeOptions: Option[]
  study: Study | undefined
  user: User
  formModalHeader: string
}

export const unspecifiedDepartment = {
  value: 'unspecified',
  label: 'My dept is not here'
}

export const DepartmentFormControl = ({
  formCtx,
  departmentTypeOptions,
  user,
  study,
  formModalHeader
}: DepartmentFormControlProps) => {
  const { register, setValue, errors } = formCtx
  const [selectedDepartment, setSelectedDepartment] = useState('')
  const [inputValue, setInputValue] = useState('')

  const displayedDepartmentTypeOptions = [...departmentTypeOptions]
  displayedDepartmentTypeOptions.push(unspecifiedDepartment)

  useEffect(() => {
    register(
      { name: 'departmentType' },
      { required: errorMessages.fieldRequired }
    )
  }, [register])

  function setDepartmentType(uuid: string) {
    setSelectedDepartment(uuid)
    setValue('departmentType', uuid, true)
  }

  useMountEffect(() => {
    const department =
      study && StudyService.getStudyHealthSystemForUser(user, study)?.department

    if (department) {
      setDepartmentType(department.departmentUuid)
    } else if (formModalHeader.includes('Edit')) {
      // note: we only want to prefill 'My dept is not here' when it has been previously selected during study creation
      // we do not wan't to prefill this field for new studies or duplicated studies
      setDepartmentType(unspecifiedDepartment.value)
    }
  })

  const handleDepartmentTypeChange = (event, newSelection) => {
    if (newSelection) {
      setDepartmentType(newSelection.value)
    } else {
      setDepartmentType('')
    }
  }

  const handleDepartmentTypeInput = (event, newInputValue) => {
    setInputValue(newInputValue)
  }

  return (
    <FormGroup className="inline-block w-1/2">
      <Label htmlFor="departmentType">Department*</Label>
      <Autocomplete
        id="departmentType"
        options={displayedDepartmentTypeOptions}
        disableClearable={true}
        placeholder="Pick one"
        onChange={handleDepartmentTypeChange}
        // we can't use undefined from array.find on initial render or else we get the following error
        // MUI: A component is changing the uncontrolled value state of Autocomplete to be controlled.
        // Elements should not switch from uncontrolled to controlled (or vice versa).
        // Decide between using a controlled or uncontrolled Autocomplete element for the lifetime of the component.
        // The nature of the state is determined during the first render. It's considered controlled if the value is not `undefined`.
        // More info: https://fb.me/react-controlled-components
        value={
          displayedDepartmentTypeOptions.find(
            (o) => o.value === selectedDepartment
          ) || null
        }
        onInputChange={handleDepartmentTypeInput}
        inputValue={inputValue}
        renderInput={(params) => (
          <TextField
            error={!!errors.departmentType?.message}
            variant="outlined"
            placeholder="Pick one"
            {...params}
          />
        )}
      />
      <If condition={'departmentType' in errors}>
        <FormControlErrorMessage error={`${errors.departmentType?.message}`} />
      </If>
    </FormGroup>
  )
}
