import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useBoolean } from 'react-use'
import { UrlBuilder, UrlBuilderFactory, HttpError } from '@deep6ai/common'
import { If } from '@deep6ai/component-library'

import {
  HttpResponsePayload,
  TrialRecommenderSubscribeEvents,
  ToggleLoadingPayload
} from './models/PubSub'
import {
  UnsubscribeCallback,
  Widget,
  WidgetApi,
  WidgetMessageEvent
} from '../Widget'
import { PPPConfig } from '../../../services/configuration/configuration.service'
import {
  trialRecommenderService,
  TrialRecommenderService
} from '../../../services/trial-recommender/TrialRecommenderService'

export interface TrialRecommenderProps {
  startingUri: string | undefined
  onWidgetReady: (api: WidgetApi) => void
  onError: (e: TrialRecommenderHttpError) => void
  config: PPPConfig
  trialRecommenderService?: TrialRecommenderService
  getSavedPatientUuids?: typeof trialRecommenderService.getSavedPatientUuids
  setSavedPatientUuids?: typeof trialRecommenderService.setSavedPatientUuids
}

const urlBuilder: UrlBuilder = UrlBuilderFactory.build()

export enum TrialRecommenderWidgetPublishEvent {
  ROUTE_CHANGE = 'ROUTE_CHANGE',
  PATIENT_LIST_REQUESTED = 'PATIENT_LIST_REQUESTED',
  PATIENT_LIST_PROVIDED = 'PATIENT_LIST_PROVIDED',
  TRIAL_RECOMMENDER_STORAGE_UPDATED = 'TRIAL_RECOMMENDER_STORAGE_UPDATED'
}

export const TrialRecommender = ({
  startingUri,
  onWidgetReady,
  onError,
  config,
  getSavedPatientUuids = trialRecommenderService.getSavedPatientUuids,
  setSavedPatientUuids = trialRecommenderService.setSavedPatientUuids
}: TrialRecommenderProps) => {
  const history = useHistory()
  const [widgetApi, setWidgetApi] = useState<WidgetApi>()

  const [showLoadingUI, setShowLoadingUI] = useBoolean(false)

  useEffect(() => {
    if (!widgetApi) return

    const acceptedPostMessageOrigin = (data: WidgetMessageEvent) =>
      data.origin === config.trialRecommenderUri

    const handleWidgetRouteChange = (pathname: string, search: string) => {
      const { cacheId, ...searchParams } = urlBuilder.parse(search)
      const sanitizedSearch = urlBuilder.build('', searchParams)
      // window.history stack is updated by the child iframe
      // iframe has a differnt reference in memory to the history object
      // therefore, the parent does not recognize the change trigerred by the child frame
      // hence, we need to use replace to simply trigger a URL change for the brower instead of pushing to the stack
      // the iframe will take care of its own route changes
      history.replace({ pathname, search: sanitizedSearch })
    }

    const handlePatientListRequested = () => {
      const savedPatientUuids = getSavedPatientUuids()
      widgetApi?.publish(
        TrialRecommenderWidgetPublishEvent.PATIENT_LIST_PROVIDED,
        savedPatientUuids
      )
    }

    const handleTrialRecommenderFrameStorageUpdated = (
      savedPatientUuids: string[]
    ) => {
      setSavedPatientUuids(savedPatientUuids)
    }

    const handleMessage = (data: WidgetMessageEvent) => {
      if (acceptedPostMessageOrigin(data)) {
        const { eventName, payload } = data.data

        switch (eventName) {
          case TrialRecommenderWidgetPublishEvent.ROUTE_CHANGE: {
            const { pathname, search } = payload
            handleWidgetRouteChange(pathname, search)
            break
          }
          case TrialRecommenderWidgetPublishEvent.PATIENT_LIST_REQUESTED: {
            handlePatientListRequested()
            break
          }
          case TrialRecommenderWidgetPublishEvent.TRIAL_RECOMMENDER_STORAGE_UPDATED: {
            handleTrialRecommenderFrameStorageUpdated(payload.value)
            break
          }
        }
      }
    }

    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [
    config.trialRecommenderUri,
    getSavedPatientUuids,
    history,
    setSavedPatientUuids,
    widgetApi
  ])

  useEffect(() => {
    if (!widgetApi) return

    const subscriptions: UnsubscribeCallback[] = [
      subscribeToToggleLoadingEvent(),
      subscribeToHttpErrors()
    ]

    function subscribeToToggleLoadingEvent() {
      return widgetApi!.subscribe(
        TrialRecommenderSubscribeEvents.ToggleLoading,
        ({ showLoading }: ToggleLoadingPayload) => {
          setShowLoadingUI(showLoading)
        }
      )
    }

    function subscribeToHttpErrors() {
      return widgetApi!.subscribe(
        TrialRecommenderSubscribeEvents.HttpError,
        ({ response }: HttpResponsePayload) => {
          if (!response.ok) {
            const error = new TrialRecommenderHttpError({
              status: response.status,
              message: response.statusText
            })
            onError(error)
          }
        }
      )
    }

    return () => subscriptions.forEach((unsubscribe) => unsubscribe())
  }, [setShowLoadingUI, onError, widgetApi])

  const handleWidgetReady = (api: WidgetApi) => {
    setWidgetApi(api)
    onWidgetReady(api)
  }

  return (
    <If condition={!!startingUri}>
      <Widget
        url={startingUri!}
        id="trial-recommender-widget"
        onWidgetReady={handleWidgetReady}
        isLoading={showLoadingUI}
      />
    </If>
  )
}

export class TrialRecommenderHttpError extends Error implements HttpError {
  status: number
  message: string
  trace?: string

  constructor(response: HttpError) {
    super(response.message)
    this.message = response.message
    this.status = response.status
    if (response.trace) {
      this.trace = response.trace
    }
  }
}
