import React, { useRef, useState } from 'react'
import cx from 'classnames'
import {
  FeatureFlagClient,
  FeatureFlagClientFactory,
  UrlBuilder,
  UrlBuilderFactory
} from '@deep6ai/common'
import { If } from '@deep6ai/component-library'

import { LoadingOverlay } from '../Loading/LoadingOverlay'
import { FeatureFlagKey } from '../../config/feature-flags'

export enum WidgetPublishEvent {
  ROUTE_CHANGE = 'ROUTE_CHANGE'
}

export interface WidgetApi {
  publish: Publish
  subscribe: Subscribe
}

export interface WidgetProps {
  title?: string
  id?: string
  isLoading?: boolean
  url: string
  onWidgetReady?: (api: WidgetApi) => void
  urlBuilder?: UrlBuilder
  featureFlagClient?: FeatureFlagClient<FeatureFlagKey>
}

export type UnsubscribeCallback = Function

export type Subscribe = <T = string>(
  eventName: T,
  handler: (payload: any) => void
) => UnsubscribeCallback

type Publish = <T = string, U = object>(eventName: T, payload: U) => void

export type WidgetMessageEvent<T = string> = {
  data: {
    eventName: T
    payload: Record<string, any>
  }
} & MessageEvent

const TARGET_ORIGIN = '*'

export const Widget = ({
  url,
  title = 'd6UiWidget',
  id = 'd6UiWidget',
  isLoading = false,
  onWidgetReady,
  urlBuilder = UrlBuilderFactory.build(),
  featureFlagClient = FeatureFlagClientFactory.getClient<FeatureFlagKey>()
}: WidgetProps) => {
  const isNewPatientTimeline = featureFlagClient.isFlagEnabled(
    FeatureFlagKey.USE_NEW_TIMELINE_REPO
  )

  const [cacheBustedUrl] = useState(urlBuilder.appendCacheBuster(url))
  const frame = useRef<HTMLIFrameElement>(null)

  const handleOnLoad = () => {
    const publish: Publish = (eventName, payload): void => {
      const iframe = frame.current
      iframe?.contentWindow?.postMessage(
        {
          eventName,
          payload
        },
        TARGET_ORIGIN
      )
    }

    const subscribe: Subscribe = (
      eventName,
      handler: <T>(payload: T) => void
    ): UnsubscribeCallback => {
      const cb = (e: WidgetMessageEvent) => {
        if (e.data.eventName !== eventName) return
        handler(e.data.payload)
      }
      window.addEventListener('message', cb)
      return () => window.removeEventListener('message', cb)
    }

    onWidgetReady?.({
      publish,
      subscribe
    })
  }

  return (
    <div className="relative w-full h-full">
      <If condition={isLoading}>
        <LoadingOverlay />
      </If>
      <iframe
        title={title}
        ref={frame}
        src={isNewPatientTimeline ? url : cacheBustedUrl}
        onLoad={handleOnLoad}
        frameBorder="0"
        className={cx('absolute pin h-full w-screen', {
          hidden: isLoading
        })}
        scrolling="no"
        id={id}
        allow="clipboard-read; clipboard-write"
        data-testid={id}
        data-cy={id}
      />
    </div>
  )
}
