import React, { useEffect } from 'react'
import { Route, Switch, useParams } from 'react-router-dom'

import { studyContextService } from '../../../services/study/StudyContextService'

export interface StudyContextWatcherProps {
  setContext: typeof studyContextService.setContext
}

/**
 * @name StudyContextWatcher
 * @description Watches all route changes and re-renders the setter on every change which
 * in turn triggers its useEffect callback which then dispatches the updated study context.
 * @link './doc/adr/0004-automatic-study-context-handling-for-irb.md'
 * */
export const StudyContextWatcher = ({
  setContext = studyContextService.setContext
}: Partial<StudyContextWatcherProps>) => (
  <Switch>
    {/**
     * We need to specifically name studyUuid here so that we can grab it
     * from the list of available route params. Otherwise react router just
     * gives it a random key name. Furthermore, this requires us to define
     * routes with the studyUuid route param with a /studies prefix every
     * time. We are already doing this but now its an important requirement
     * for this watcher to work.
     */}
    <Route path={['*/studies/:studyUuid', '*']}>
      <StudyContextSetter setContext={setContext} />
    </Route>
  </Switch>
)

export const StudyContextSetter = ({
  setContext
}: StudyContextWatcherProps) => {
  const { studyUuid } = useParams<{ studyUuid: string }>()
  useEffect(() => {
    setContext(studyUuid)
  }, [setContext, studyUuid])
  return <></>
}
