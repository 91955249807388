import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Logger, LoggerFactory } from '@deep6ai/common'
import { useLocation, useHistory } from 'react-router-dom'

import { ResolvedPage } from '../ResolvedPage'
import { PPPConfig } from '../../services/configuration/configuration.service'
import { authenticationService } from '../../services/authentication/authentication-service'
import { useTrialRecommender } from '../../hooks/useTrialRecommender/useTrialRecommender'
import { useQueryParams } from '../../hooks/useQueryParams/useQueryParams'
import { useMountEffect } from '../../hooks/useMountEffect'
import { WidgetApi } from '../../components/Widgets/Widget'
import {
  TrialRecommender,
  TrialRecommenderHttpError
} from '../../components/Widgets/TrialRecommender/TrialRecommender'
import { Patient } from '../../services/patient/models/Patient'

export interface TrialRecommenderApplicationResolvedProps {
  patients?: Patient[]
}

export type TrialRecommenderApplicationProps = ResolvedPage<
  TrialRecommenderApplicationResolvedProps,
  InjectedDependencies,
  PropsFromRedux
>

interface InjectedDependencies {
  logout: typeof authenticationService.logOut
  logger: Logger
}

interface PropsFromRedux {
  config: PPPConfig
}

/**
 * In order to increase perceived loading performance, this page is built so
 * that patients display first with a loading spinner in place of the counts.
 */
export const _TrialRecommenderApplication = ({
  logout = authenticationService.logOut,
  logger = LoggerFactory.build(),
  config
}: TrialRecommenderApplicationProps) => {
  const [queryParams] = useQueryParams()
  const location = useLocation()
  const history = useHistory()
  const { startingUri, setStartingUri, setWidgetApi, triggerWidgetRedirect } =
    useTrialRecommender(config)

  useMountEffect(() => {
    setStartingUri({ pathname: location.pathname, searchParams: queryParams })
  })

  useEffect(() => {
    if (history.action === 'POP' || history.action === 'PUSH') {
      triggerWidgetRedirect({
        pathname: history.location.pathname,
        searchParams: queryParams
      })
    }
  }, [triggerWidgetRedirect, queryParams, setStartingUri, history])

  function handleTrialRecommenderError(e: TrialRecommenderHttpError) {
    if (e.status === 401) {
      logout()
    } else {
      // The TrialRecommender component deals with errors internally. Normally
      // we would throw but in this particular case we won't (for now)
      logger.error(e.message)
    }
  }

  function handleTrialRecommenderReady(api: WidgetApi) {
    setWidgetApi(api)
  }

  return (
    <TrialRecommender
      startingUri={startingUri}
      onError={handleTrialRecommenderError}
      onWidgetReady={handleTrialRecommenderReady}
      config={config}
    />
  )
}

const mapStateToProps = ({ config }) => ({
  config: config.pppConfig
})

const TrialRecommenderApplication = connect(mapStateToProps)(
  _TrialRecommenderApplication
)

export default TrialRecommenderApplication
