import React from 'react'
import { HttpError } from '@deep6ai/common'

import { LoadingOverlay } from '../Loading/LoadingOverlay'
import { ErrorMessageAlert } from './ErrorMessageAlert'
import { ErrorMessageLogo } from './ErrorMessageLogo'
import { ErrorMessageBanner } from './ErrorMessageBanner'

interface PolymorphicErrorPageProps {
  errors: (HttpError | undefined | null)[]
}

interface InternalPolymorphicErrorProps extends PolymorphicErrorPageProps {
  onForbiddenExceptionShow?: JSX.Element
  onUnauthorizedExceptionShow?: JSX.Element
  onUnhandledExceptionShow?: JSX.Element
}

const InternalErrorStatusView = ({
  errors,
  onForbiddenExceptionShow = <DefaultForbiddenComponent />,
  onUnhandledExceptionShow = <DefaultUnhandledComponent />,
  onUnauthorizedExceptionShow = <DefaultUnauthorizedComponent />
}: InternalPolymorphicErrorProps) => {
  const unauthorized = errors?.find((e) => e?.status === 401)
  if (unauthorized) return onUnauthorizedExceptionShow

  const forbidden = errors?.find((e) => e?.status === 403)
  if (forbidden) return onForbiddenExceptionShow

  return onUnhandledExceptionShow
}

export const ErrorStatusView = ({ errors }: PolymorphicErrorPageProps) => {
  return <InternalErrorStatusView errors={errors} />
}

export const ErrorStatusViewOverlay = ({
  errors
}: PolymorphicErrorPageProps) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <ErrorStatusView errors={errors} />
    </div>
  )
}

const DefaultUnhandledComponent = () => (
  <ErrorMessageLogo id="unhandledExceptionErrorPage" />
)

const DefaultForbiddenComponent = () => (
  <ErrorMessageAlert
    id="accessDeniedPage"
    primaryText="You don't have access to this page."
  />
)

// by default, we handle unauthorized exceptions by continuing to display
// a loading UI while the redirect to login action is being executed.
const DefaultUnauthorizedComponent = () => <LoadingOverlay />

export const StudyErrorStatusView = ({ errors }: PolymorphicErrorPageProps) => {
  return (
    <InternalErrorStatusView
      onForbiddenExceptionShow={
        <ErrorMessageAlert
          className="py-12 px-6"
          id="studyAccessForbiddenError"
          primaryText="You don't have access to this study."
          secondaryText="Ask the study team to share with you if you believe you should have access."
        />
      }
      errors={errors}
    />
  )
}

export const DataTimeoutErrorStatusView = ({
  errors
}: PolymorphicErrorPageProps) => {
  return (
    <InternalErrorStatusView
      onForbiddenExceptionShow={
        <ErrorMessageAlert
          className="pt-12"
          id="dataTimeoutErrorStatus"
          primaryText="Data Request Timeout."
          secondaryText={
            <>
              <p>A request for data has taken too long.</p>
              <p>
                Please refresh the page & try again. If the issue persists
                please contact support.
              </p>
            </>
          }
        />
      }
      errors={errors}
    />
  )
}

export const StudyIrbApprovalErrorStatusView = ({
  errors
}: PolymorphicErrorPageProps) => {
  return (
    <InternalErrorStatusView
      onForbiddenExceptionShow={
        <ErrorMessageAlert
          className="pt-12"
          id="studyIrbApprovalForbiddenError"
          primaryText="You don't have IRB approval to view PHI for this study."
          secondaryText="Check the IRB # on this study or contact your compliance administrator about granting approval."
        />
      }
      errors={errors}
    />
  )
}

export const PhiAccessErrorStatusView = ({
  errors
}: PolymorphicErrorPageProps) => {
  return (
    <InternalErrorStatusView
      onForbiddenExceptionShow={
        <ErrorMessageAlert
          className="pt-12"
          id="phiAccessForbiddenError"
          primaryText="You don't have PHI permission to access this feature."
        />
      }
      errors={errors}
    />
  )
}

/*        Watchlist API Banner Errors      */

interface InternalErrorStatusBannerViewProps
  extends InternalPolymorphicErrorProps {
  onUnprocessableEntityExceptionShow?: JSX.Element
}

const InternalErrorStatusBannerView = ({
  errors,
  onForbiddenExceptionShow = <DefaultForbiddenAction />,
  onUnhandledExceptionShow = <DefaultUnhandledComponent />,
  onUnauthorizedExceptionShow = <DefaultUnauthorizedAction />,
  onUnprocessableEntityExceptionShow = <DefaultUnprocessableEntityAction />
}: InternalErrorStatusBannerViewProps) => {
  // Single endpoint should only return one error at a time
  const errorCode = errors[0]?.status
  switch (errorCode) {
    case 401:
      return onUnauthorizedExceptionShow // Authentication failed
    case 403:
      return onForbiddenExceptionShow // Authorization failed (no tenant access)
    case 422:
      return onUnprocessableEntityExceptionShow // Validation errors
    default:
      return onUnhandledExceptionShow // Unexpected error
  }
}

const DefaultForbiddenAction = () => (
  <ErrorMessageBanner
    id="accessDeniedAction"
    message="You are not authorized to perform this action. You don't have access to this study."
  />
)

const DefaultUnauthorizedAction = () => (
  <ErrorMessageBanner
    id="unauthorizedAction"
    message="You are not authorized to perform this action. Authentication failed."
  />
)

const DefaultUnprocessableEntityAction = () => (
  <ErrorMessageBanner
    id="unprocessableEntityAction"
    message="Unprocessable entity action. Validation error."
  />
)

export const ErrorStatusBannerView = ({
  errors
}: PolymorphicErrorPageProps) => {
  return <InternalErrorStatusBannerView errors={errors} />
}
