import { useState } from 'react'

export function useModalState(
  initialState = false
): [boolean, () => void, () => void] {
  const [isModalOpen, setIsDeleteModalOpen] = useState(initialState)

  const openModal = () => setIsDeleteModalOpen(true)
  const closeModal = () => setIsDeleteModalOpen(false)

  return [isModalOpen, openModal, closeModal]
}
