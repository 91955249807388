import { container, inject, injectable } from 'tsyringe'

import {
  GetStudyMembershipParams,
  PatientService
} from '../../services/patient/patient-service'
import { TrialRecommenderService } from '../../services/trial-recommender/TrialRecommenderService'
import { ReduxAsyncNotifier } from '../../lib/async-notifier/redux-async-notifier'
import type { AsyncNotifier } from '../../lib/async-notifier/async-notifier'
import { PaginatedResponse } from '../../lib/http/paginated-response'
import { AbstractAction } from '../abstract-action'
import { Patient } from '../../services/patient/models/Patient'
import { StudyPhase } from '../../services/study/study.service'
import { PatientSummary } from '../../services/trial-recommender/models/TrialRecommender'

export enum PatientActionTypes {
  addPatientSummaries = 'PATIENT::ADD_PATIENT_SUMMARIES',
  clearPatientSummaries = 'PATIENT::CLEAR_PATIENT_SUMMARIES',
  addActivePatient = 'PATIENT::ADD_PATIENT',
  clearActivePatient = 'PATIENT::CLEAR_PATIENT',
  addStudiesForPatients = 'PATIENT::ADD_STUDIES_FOR_PATIENTS',
  clearStudiesForPatients = 'PATIENT::CLEAR_STUDIES_FOR_PATIENTS'
}

@injectable()
export class PatientActions {
  constructor(
    @inject(ReduxAsyncNotifier.injectionToken)
    private asyncNotifier: AsyncNotifier,
    private patientService: PatientService,
    private trialRecommenderService: TrialRecommenderService
  ) {}

  addPatientSummaries(
    items: PaginatedResponse
  ): AbstractAction<PatientActionTypes, PaginatedResponse> {
    return {
      type: PatientActionTypes.addPatientSummaries,
      payload: items
    }
  }

  clearPatientSummaries(): AbstractAction<PatientActionTypes> {
    return {
      type: PatientActionTypes.clearPatientSummaries
    }
  }

  addActivePatient(
    patient: Patient
  ): AbstractAction<PatientActionTypes, Patient> {
    return {
      type: PatientActionTypes.addActivePatient,
      payload: patient
    }
  }

  clearActivePatient(): AbstractAction<PatientActionTypes> {
    return {
      type: PatientActionTypes.clearActivePatient
    }
  }

  addStudiesForPatients(
    studiesForPatient: PatientSummary
  ): AbstractAction<PatientActionTypes, PatientSummary> {
    return {
      type: PatientActionTypes.addStudiesForPatients,
      payload: studiesForPatient
    }
  }

  clearStudiesForPatients(): AbstractAction<PatientActionTypes> {
    return {
      type: PatientActionTypes.clearStudiesForPatients
    }
  }

  getPatientByUuid = this.asyncNotifier.subscribe(
    (patientUuid: string) => async (dispatch) => {
      const patient = await this.patientService.getPatientByUuid(patientUuid)
      dispatch(this.addActivePatient(patient))
    }
  )

  getStudiesForPatients = this.asyncNotifier.subscribe(
    (
        healthSystemUuid: string,
        patientUuids: string[],
        studyPhases: StudyPhase[]
      ) =>
      async (dispatch) => {
        const studies = await this.trialRecommenderService.getPatientSummary(
          patientUuids,
          studyPhases
        )
        dispatch(this.addStudiesForPatients(studies))
      }
  )

  getStudyMemberships = this.asyncNotifier.subscribe(
    (params: GetStudyMembershipParams) => async (dispatch) => {
      const res = await this.patientService.getStudyMemberships(params)
      dispatch(this.addPatientSummaries(res))
    }
  )

  static build() {
    return container.resolve(PatientActions)
  }
}

export const patientActions = PatientActions.build()
