import { PPPConfig } from '../../services/configuration/configuration.service'
import { AbstractAction } from '../abstract-action'
import { ConfigurationActionTypes } from './configuration.actions'

export interface ConfigurationReducerSlice {
  pppConfig: PPPConfig | null
}

export const initialState: ConfigurationReducerSlice = {
  pppConfig: null
}

export default function configurationReducer(
  state = initialState,
  action: AbstractAction<ConfigurationActionTypes, PPPConfig>
) {
  switch (action.type) {
    case ConfigurationActionTypes.setConfig:
      return {
        ...state,
        pppConfig: action.payload
      }
    default:
      return state
  }
}
