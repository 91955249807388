import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  AsyncNotifierActions,
  AsyncNotifierSubscriptions
} from '../../store/async-notifier/async-notifier.reducer'
import {
  ActionSubscriber,
  reduxAsyncNotifier
} from '../../lib/async-notifier/redux-async-notifier'
import { AsyncNotifier } from '../../lib/async-notifier/async-notifier'
import { useMountEffect } from '../useMountEffect'

export enum AsyncStatus {
  Pristine,
  Pending,
  Error,
  Resolved
}

interface UseAsyncActionProps {
  subscribeUntil?: (status?: AsyncStatus) => boolean
}

/**
 * @name useAsyncAction
 * @link reduxAsyncNotifier
 * @description notify a given component of any async-notifier operations occurring within any actions it happens to be
 * subscribed to. Under the hood it leverages the asyncNotifier to subscribe to and handle those actions.
 * @param {ActionSubscriber[]} subscribedActions - an array of subscribed actions you want to listen to
 * @param {UseAsyncActionProps} options - a configuration object that offers customization options to the default behavior.
 * @param asyncNotifier {AsyncNotifier} - implementation of AsyncNotifier we want to use
 * @return {[AsyncStatus]} - a status object of type `AsyncNotifierStatus` which is an enum with three
 * types: `Done`, `Pending`, and `Error`. It delegates the handling of these statuses to the caller.
 * @example
 * // check for status
 * const [status] = useAsyncAction(subscribedActions, {
 *   subscribeUntil: () => !subscribed
 * });
 *
 * // then do something with that status
 * useEffect(() => {
 *   switch (status) {
 *     case AsyncNotifierStatus.Error:
 *       setErrors(true);
 *       onError();
 *       break;
 *     case AsyncNotifierStatus.Pending:
 *       setPending(true);
 *       onPending();
 *       break;
 *     case AsyncNotifierStatus.Resolved:
 *       if (subscribed) setPending(false);
 *       onResolved();
 *       break;
 *   }
 * }, [onError, onResolved, onPending, status, subscribed]);
 */

export const useAsyncAction = (
  subscribedActions: ActionSubscriber[] = [],
  options?: UseAsyncActionProps,
  asyncNotifier: AsyncNotifier = reduxAsyncNotifier
): [AsyncStatus] => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(AsyncStatus.Pristine)
  const [subscribed, setSubscribed] = useState(false)
  const [isPristine, setIsPristine] = useState(true)
  useEffect(() => {
    const subscribeUntil = options?.subscribeUntil ?? (() => false)
    setSubscribed(!subscribeUntil(status))
  }, [options, status])

  const subscriptions = useSelector(
    ({
      asyncNotifierSubscriptions
    }: {
      asyncNotifierSubscriptions: AsyncNotifierSubscriptions
    }) => asyncNotifierSubscriptions
  )

  // Garbage collect our errors on unmount
  useMountEffect(() => {
    return () => {
      Object.keys(subscriptions.errors).forEach((subscriptionId) => {
        dispatch({
          type: AsyncNotifierActions.ErrorPop,
          payload: subscriptionId
        })
      })
    }
  })

  useEffect(() => {
    if (!subscribed) return

    const subscriptionIds = subscribedActions.map(asyncNotifier.getSubscription)
    const errors = Object.keys(subscriptions.errors).find((id) =>
      subscriptionIds.includes(id)
    )
    const pending = subscriptions.pending.find((id) =>
      subscriptionIds.includes(id)
    )

    if (errors) {
      subscriptions.pending.forEach((subscription) =>
        dispatch({
          type: AsyncNotifierActions.PendingPop,
          payload: subscription
        })
      )
      setStatus(AsyncStatus.Error)
    } else {
      if (pending) {
        setIsPristine(false)
      }

      const nonPendingStatus = isPristine
        ? AsyncStatus.Pristine
        : AsyncStatus.Resolved

      setStatus(pending ? AsyncStatus.Pending : nonPendingStatus)
    }
  }, [
    dispatch,
    subscribedActions,
    subscriptions,
    subscribed,
    isPristine,
    asyncNotifier.getSubscription
  ])

  return [status]
}
