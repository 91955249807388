import React from 'react'

import { Modal } from '../Modal'
import { ConfirmButtons } from '../ConfirmButtons'

interface AttachmentDeleteModalProps {
  isOpen: boolean
  onDelete: () => void
  onCancel: () => void
}

export const AttachmentDeleteModal = ({
  isOpen,
  onDelete,
  onCancel
}: AttachmentDeleteModalProps) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onCancel}>
      <div className="m-5">
        <h2 className="font-normal">
          Are you sure you want to remove this attachment?
        </h2>
        <p className="mb-6">
          This attachment will no longer be available to any study recipients,
          team members, or on Trial Recommender.
        </p>
        <ConfirmButtons onConfirm={onDelete} onCancel={onCancel} />
      </div>
    </Modal>
  )
}
